<template>
  <div class="notification-toasters">
    <ol>
      <transition-group name="slideinToaster">
        <li v-for="notificacao in notificacoes" :key="notificacao">
          <toaster :scope="notificacao" :config="config"></toaster>
        </li>
      </transition-group>
    </ol>
  </div>
</template>

<script>
import Toaster from '@/components/utilities/notifications/Toaster.vue'

export default {
  props: {
    config: {
      type: Array
    }
  },
  components: {
    Toaster
  },
  data () {
    return {
      notificacoes: []
    }
  },
  mounted () {

  },
  methods: {
    addNotificacoes ( data ) {
      const index = this.notificacoes.length
      data.index = index
      this.notificacoes.push(data)
      
      setTimeout(() => {
        this.removeNotificacoes(index)
      },10000)
    },
    removeNotificacoes ( id ) {
      const index = this.notificacoes.findIndex(k => k.index == id)
      this.notificacoes.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex; justify-content: flex-end;

    a {
      text-align: right;
    }
  }
  .notification-toasters {
    width: 100%; max-width: 380px;
    position: fixed;
    bottom: 0; left: 60px;
    z-index: 600;

    ol {
      padding: $hmg_mid;
      margin: 0;

      li {
        list-style: none;
        padding: 0;
        margin: $mg_mini 0 0 0;
        @extend %transition_4e;
      }
    }
  }

  .slideinToaster-enter-active  {
    transform: translateY(0) rotate(0);
  }

  .slideinToaster-leave-active {
    transform: translateY(0) rotate(0);
  }
  .slideinToaster-enter-from {
    transform: translateY(200%) rotate(-45deg);
  }

  .slideinToaster-leave-to {
    transform: translateY(300%) rotate(0);
  }
</style>