<template>
  <section class="appController">
    <spinner />
    <toaster />
    <header-controller :env="env"></header-controller>
    <sidebar-controller @onSuperMenu="toggleSuperMenu" :active="activeMenu" @setActive="setActiveMenu" :env="env"></sidebar-controller>
    <main>
      <transition name="fadein">
        <super-menu :scope="supermenu" v-if="supermenu" @close="closeSuperMenu" ></super-menu>
      </transition>
      <div class="main-content">
        <slot></slot>
      </div>
    </main>
    <div v-if="env != 'PROD'" class="notProdAlert">
      <b>Atenção!</b> Você está no ambiente de <b>{{ env == 'HOM' ? 'homologação' : 'desenvolvimento' }}</b>. As ações executadas neste ambiente não refletem no ambiente de produção.
    </div>
    <transition name="slideup">
      <a 
        v-if="goToTopButton && goToTopButtonActive" 
        href="#" 
        @click="goToTop()"
        class="btn btn-primary btn-icon btn-round goToTopButton"
        >
        <fa :icon="['far', 'arrow-up']" class="icon"></fa>
      </a>
    </transition>
  </section>
</template>

<script>
import HeaderController from '@/components/template/Header.vue'
import SidebarController from '@/components/navigation/Sidebar.vue'
import SuperMenu from '@/components/navigation/SuperMenu.vue'

export default {
  name: 'Template',
  components: {
    HeaderController, SidebarController, SuperMenu
  },
  props: {
    lockScroll: {
      type: Boolean,
      default: false
    },
    goToTopButton: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    lockScroll ( value ) {
      // console.log("watch lockScroll --------------->", value)
      this.lockBody(value)
    }
  },
  data () {
    return {
      env: null,
      octaScript: null,
      options: {
        suppressScrollY: false,
        minScrollbarLength: 80,
      },
      goToTopButtonActive: false,
      ease: null,
      scrollTop: 0,
      speed: 100,
      rate: 1,
      supermenu: null,
      activeMenu: null
    }
  },
  mounted () {
    this.checkDevMode()
    // this.addOcta()

    window.pagescroll.on('lock', () => {
      console.log("lock scroll")
      window.scroll({
        top: 0,
        behavior: 'instant'
      })
      this.lockBody(true)
    })

    window.pagescroll.on('unlock', () => {
      // console.log("unlock store scroll")
      this.lockBody(false)
    })

    this.lockBody(this.lockScroll)

    window.addEventListener('scroll', this.scroll)
  },
  methods: {
    checkDevMode () {
      this.env = process.env.VUE_APP_ENV
    },
    addOcta () {
      // // console.log("add octa now...")
      // this.octaScript = document.createElement('script')
      // this.octaScript.setAttribute('src', '/octa.js')
      // // console.log("externalScript:", externalScript)
      
      // document.head.appendChild(this.octaScript)

      // const octa = document.getElementById('octadesk-octachat-appchat')
      // if ( octa ) octa.removeAttribute('class', 'hidden')
    },
    removeOcta () {
      // console.log("remove octa...")
      const octa = document.getElementById('octadesk-octachat-appchat')
      octa.setAttribute('class', 'hidden')
      // document.head.removeChild(this.octaScript)
      // document.getElementById("octaScript").parentNode().removeChild(this.octaScript)
      // document.head.removeChild(this.octaScript)
    },
    toggleSuperMenu ( supermenu ) {
      this.supermenu = null
      console.log("supermenu",supermenu)
      
      this.$nextTick(() => {
        this.supermenu = supermenu
        if ( supermenu ) this.lockBody(true)
      })
    },
    closeSuperMenu () {
      this.supermenu = null
      this.activeMenu = null
      this.lockBody(false)
    },
    setActiveMenu ( active ) {
      // console.log("setActiveMenu:", active)
      if ( active ) {
        this.activeMenu = active
      } else {
        this.closeSuperMenu()
      }
      
    },
    lockBody ( state ) {
      if ( state ) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'auto'
      }
    },
    scroll () {
      this.goToTopButtonActive = window.scrollY > 100
    },
    goToTop () {
      document.body.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}

</script>

<style lang="scss" scoped>
  main {
    padding: 60px 0 0 60px;
    position: relative;

    .main-content {
      width: 100%; height: auto;
      position: relative;
    }
  }

  .notProdAlert {
    position: fixed;
    width: 100%;
    bottom: 0; left: 0;
    background-color: $color-warning;
    color: $color-dark;
    padding: $hmg_mini $mg_mini;
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
    z-index: 1000;
    text-align: center;
    box-shadow: -2px 1px 12px rgba(145, 107, 21, 0.4) !important;
    @extend %transition_4e;

    &:hover {
      padding: $mg_mini;
    }
  }

  .goToTopButton {
    position: fixed; bottom: $mg; right: $hmg_mid;
    z-index: 5000;
    @extend %transition_4e;
  }
</style>