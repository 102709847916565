<template>
  <section class="matriculas">
    <header>
      <h4>Matrículas</h4>
      <form>
        <div>
          <select-control 
            :options="options.periodoLetivo" 
            placeholder="Selecione" 
            v-model="filter.periodoLetivo" 
            @change="runFilter('periodoLetivo')"
            label="Período Letivo"
          />
        </div>
        <div>
          <select-control 
            :options="options.curso" 
            placeholder="Selecione"
            v-model="filter.curso"
            @change="runFilter('curso')"
            label="Curso"
          />
        </div>
        <div>
          <select-control 
            :options="options.status"
            placeholder="Selecione"
            v-model="filter.status"
            @change="runFilter('status')"
            label="Status"
          />
        </div>
      </form>
    </header>
    <ul v-if="matriculas">
      <li v-for="matricula in matriculas" :key="matricula">
        <card-mat :matricula="matricula" :ca="true" @onRemoveItinerarios="confirmRemoveItinerario" @onCallAction="callAction"/>
      </li>
    </ul>  
  </section>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'
import CardMat from '@/components/matriculas/utilities/CardMatricula.vue'



export default {
  components: {
    CardMat
  },
  props: {
    aluno: {
      type: Object
    }
  },
  data () {
    return {
      scope: null,
      matriculas: null,
      filter: {
        status: 'all',
        curso: 'all',
        periodoLetivo: 'all'
      },
      options: {
        status: [],
        curso: [],
        periodoLetivo: []
      },
      filterRef: {
        status: 'idSituacao',
        curso: 'idCurso',
        periodoLetivo: 'idPeriodoLetivo'
      },
      modalContent: null,
      idMatricula: null
    }
  },
  mounted () {
    this.getMatriculas()
  },
  methods: {
    getMatriculas() {
      const options = {
        idAluno: this.aluno.Aluno.idAluno
      }
      console.log("idAluno:", options)
      MatriculaService.getMatriculas(options).then(
        response => {
          console.log("getMatriculas:", response.data)
          this.matriculas = response.data
          this.scope = Object.assign([],response.data)

          this.createFilters()
        },
        () => {

        }
      )
    },
    createFilters () {
      this.filter = {
        status: 'all',
        curso: 'all',
        periodoLetivo: 'all'
      }
      
      const perRef = []
      const curRef = []
      const statRef = []

      const periodos = []
      const cursos = []
      const status = []

      const todos = {
        label: 'Todos',
        value: 'all'
      }

      this.matriculas.map( k => {
        const periodoLetivo = {
          label: k.nomePeridoletivo,
          value: k.nomePeridoletivo
        }
        if ( !perRef.includes(k.nomePeridoletivo) ) {
          perRef.push(k.nomePeridoletivo)
          periodos.push(periodoLetivo)
        }

        const curso = {
          label: k.nomeCurso,
          value: k.idCurso
        }
        if ( !curRef.includes(k.idCurso) ) {
          curRef.push(k.idCurso)
          cursos.push(curso)
        }

        const stat = {
          label: k.nomeSituacao,
          value: k.idSituacao
        }
        if ( !statRef.includes(k.idSituacao) ) {
          statRef.push(k.idSituacao)
          status.push(stat)
        }


      })

      periodos.unshift(todos)
      cursos.unshift(todos)
      status.unshift(todos)

      this.options.periodoLetivo = periodos
      this.options.curso = cursos
      this.options.status = status

      this.filter.periodoLetivo = this.options.periodoLetivo[1].value
      this.runFilter('periodoLetivo')

      const periodosDrop = Object.assign([],this.options.periodoLetivo)
            periodosDrop.splice(0,1)
      this.$emit("onCreateFilter", { periodos: periodosDrop, selected: this.options.periodoLetivo[1].value })
    },
    runFilter () {
      const result = Object.assign([],this.scope)

      const drop = result.filter( a => {
        const isPeriodoLetivo = this.filter.periodoLetivo != 'all' ? ( a.nomePeridoletivo == this.filter.periodoLetivo ) : true
        const isStatus = this.filter.status != 'all' ? ( a.idSituacao == this.filter.status ) : true
        const isCurso = this.filter.curso != 'all' ? ( a.idCurso == this.filter.curso ) : true

        return isPeriodoLetivo && isStatus && isCurso
      })
      
      this.matriculas = drop      
    },
    clearFilter () {
      this.matriculas = Object.assign([],this.scope)
    },
    confirmRemoveItinerario ( options ) {
      this.$emit("onRemoveItinerarios", options)
    },
    callAction ( options ) {
      console.log("callAction", options)

      if ( options.target === "Detalhes" ) {
        this.$router.push({name: 'Nova Matrícula', params: { action: 'ver', step: 'unidade-curso', idMatricula: options.matricula.idMatricula }})
      }
      if ( options.target === "Editar Matrícula" ) {
        this.$router.push({name: 'Nova Matrícula', params: { action: 'editar', step: 'unidade-curso', idMatricula: options.matricula.idMatricula }})
      }
      if ( options.target === "Trocar de Turma" ) {
        options['target'] = 'remanejamento'
        this.$emit('onModal', options)
      }
      if ( options.target === "Validar Documentos" ) {
        options['target'] = 'documentos'
        this.$emit('onModal', options)
      }
      if ( options.target === "Financeiro" ) {
        options['target'] = 'boletos'
        this.$emit('onModal', options)
      }
      if ( options.target === "Itinerários Formativos" ) {
        options['target'] = 'itinerarios'
        this.$emit('onModal', options)
      }
      if ( options.target === "Descontos" ) {
        this.idMatricula = options.matricula.idMatricula
        this.$router.push({name: 'Nova Matrícula', params: { action: 'ver', step: 'planos-pagamento', idMatricula: options.matricula.idMatricula }})
      }
      if ( options.target === "Rematrícula" ) {
        this.$router.push({name: 'Nova Matrícula', params: { action: 'pre', step: 'inicio', idMatricula: options.matricula.idMatricula }})
      }
      
    },
    closeModalControl ( update ) {
      this.$refs.modal.close()

      if ( update ) {
        this.$nextTick(() => {
          this.getMatriculas()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .matriculas {
    header {
      margin: 0; padding: $hmg_mid $hmg_mid $hmg_mid $mg;
      position: sticky; top: 60px;
      border-bottom: $border-component;
      z-index: 80; background-color: $color-bg;

      @media screen and ( max-width: 1024px ) {
        padding: $hmg_mid
      }

      form {
        margin: $hmg_mid 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $mg_mini;

        > div {
          flex: 1;
        }

        @media screen and ( max-width: 1024px ) {
          flex-wrap: wrap;
        }
      }
    }
    ul {
      position: relative;
      margin: 0;
      padding: $hmg_mid $hmg_mid $hmg_mid $mg;
      z-index: 79;

      @media screen and ( max-width: 1024px ) {
        padding: $hmg_mid
      }
      
      li {
        list-style: none;
        margin: 0 0 $hmg_mid 0; padding: 0;
      }
    }
  }
</style>