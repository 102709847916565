<template>
  <div>
    <div class="frame mid" >
      <success v-if="success || pix"></success>
      <div class="page-inner frame nano">
        <h1 v-if="success || pix">Tudo certo!</h1>
        <h1 v-else-if="!waiting">Ops. Algo deu errado.</h1>
        <h5 v-html="message" class="returnMessage color-text"></h5>
        <!-- <div v-if="pix">
          <img :src="qr" width="256" height="256" alt="QR-Code PIX" class="qr" />
        </div> -->
        <div class="pixMsg" v-if="status == 'pix'">
          <h5>
            Para concluir a compra, siga os passos abaixo:
          </h5>
          <ol>
            <li>Acesse seu aplicativo</li>
            <li>Selecione a opção PIX ou área PIX</li>
            <li>Selecione a opção Pix Copia e Cola</li>
            <li>Clique para copiar o código abaixo</li>
          </ol>
        </div>
        <div v-if="status == 'pix'"  class="pixContent " @click="copy()">
          <h5 class="btn btn-primary">Clique para copiar o Código Pix</h5>
          <span>
            <div ref="pixContent" class="textBox">{{ pix.pixcode }}</div>
            <transition name="slideup-small">
              <p v-if="copiado">Copiado!</p>
            </transition>
          </span>
          <h6 class="color-text">
            E lembre-se, seu código pix é válido por <b>24h</b>.<br/>
            Pague com o código até <b class="color-primary">{{ pixExpirationDate() }}</b><br>
          </h6>
          <p class="sub-text">Após a expiração do código, sua compra será cancelada automaticamente.</p>
        </div>
        <!-- <h3 v-if="success">Seu pedido: {{ transacao }}</h3> -->
        <div class="actions" v-if="success || pix">
          <a href="#" @click.prevent="returnToStoreLogged()" class="btn btn-secondary">
            <span>Voltar para a loja</span>
          </a>
          <!-- <router-link to="/loja" class="btn btn-secondary">
            Voltar para a loja
          </router-link> -->
          <!-- <router-link to="Menus Pedidos" class="btn btn-primary">
            Ver meus pedidos
          </router-link> -->
        </div>
        <div class="action" v-else>
          <a href="#" @click.prevent="goBack()" class="btn btn-secondary">
            <span>Tentar outra vez</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Success from '@/components/utilities/Success'
import CheckoutLoja from '@/services/checkoutLoja.service'

import moment from 'moment'

export default {
  name: "Confirmação",
  components: {
    Success
  },
  props: {
    status: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    transacao: {
      type: Number,
      default: null
    },
    waiting: {
      type: Boolean,
      default: false
    },
    pix: {
      type: Object,
      default: null
    }
  },
  watch: {
    status ( value ) {
      if ( value ) {
        console.log("status:", value)
        this.toggleSuccess()
      }
    },
    pix ( value ) {
      console.log("value:", value)
      console.log('pix code:',value.pixcode.base64())
      
    }
  },
  data () {
    return {
      success: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      tokenLaravel: process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN,
      copiado: false,
      qr: null
    }
  },
  mounted () {
    console.log("status:", this.status)
    
    this.toggleSuccess()
    // this.getQRCode()
  },
  methods: {
    toggleSuccess () {
      this.success = this.status === 'success'
    },
    goBack () {
      this.$emit('goback')
      this.success = false
    },
    returnToStoreLogged () {
      const token = this.tokenLaravel
      const email = this.currentUser.email
      // console.log("token:", token)
      // this.$router.push({name: 'Loja', query: { token: token, email: email }})
      
      window.location = '/loja/?token=' + token + '&email=' + email
    },
    fallbackCopy () {
      // console.log("window.clipboardData:", window.clipboardData)
      const el = document.createElement('textarea')
      el.value = this.pix.code
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      
    },
    copy () {
      // const elm = this.$refs.pixContent
      if ( !navigator.clipboard ) {
        this.fallbackCopy()
      } else {
        // const selection = window.getSelection()
        // selection.removeAllRanges()

        // const range = document.createRange()
        // range.selectNodeContents(elm)
        // selection.addRange(range)

        navigator.clipboard.writeText(this.pix.pixcode)
        this.copiado = true

        setTimeout(() => {
          this.copiado = false
        },3000)
      }
      
    },
    getQRCode () {
      if ( this.pix ) {
        CheckoutLoja.generatePixQR(this.pix.pixcode).then(
          response => {
            let blob = new Blob(
              [response.data], 
              { type: 'image/png' }
            )

            let image = URL.createObjectURL(blob)
            this.qr = image
            
          },
          error => {
            console.error(error.response.message)
          }
        )
      }
    },
    pixExpirationDate () {
      return moment().add(1,'days').format('DD/MM - HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    text-align: center;
  }
  
  h1 {
    color: $color-text
  }
  // p{margin-bottom: $mg;}

  .icon {
    font-size: 120px;
  }

  .actions {
    display: flex; justify-content: center;

    .btn {
      margin: auto $hmg_mini;
    }
  }
  .pixContent {
    padding: $hmg; margin: 0 auto $mg;
    position: relative;
    word-break: break-all;
    
    h5 {
      text-align: center;
      color: $color-text;
      margin-bottom: $hmg_mid;
    }
    p:not(.sub-text) {
      width: 100px;
      position: absolute; top: 0;
      left: 0; transform: translateY(10px);
      background-color: $color-success-2; color: $color-secondary;
      font-weight: bold; @extend %transition_4o; @extend %shadow-low-success-2;
      padding: $hmg_mini; border-radius: $border-radius-small; margin: 0 auto;
      word-break: break-all;
    }
    h6 {
      margin-top: $hmg;
    }
    span {
      position: relative;
    }
    div {
      opacity: 0; position: absolute;
      pointer-events: none; z-index: -1;

      // border-radius: $border-radius;
      // background-color: $color-secondary;
      // @extend %shadow-mid;
      // padding: $hmg;

      // &::-moz-selection { /* Code for Firefox */
      //   color:$color-secondary;
      //   background: $color-primary;
      // }

      // &::selection {
      //   color:$color-secondary;
      //   background: $color-primary;
      // }
    }

    .btn {
      width: 100%;
    }
  }

  .qr {
    border-radius: $border-radius;
  }

  .pixMsg {
    padding: $hmg_small;
    border-radius: $border-radius;
    background-color: $color-secondary;

    h5 {
      margin-bottom: $mg_mini
    }
    ol {
      text-align: left;
    }
  }

  .returnMessage {
    margin: $mg_mini 0 $hmg;
  }
</style>