<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Parametrização Gatilhos</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleNew()" class="btn btn-primary" v-if="!novo">
          <span>Novo Gatilho</span>&nbsp;
          <fa :icon="['fas', 'bolt']"></fa>
        </a>
      </transition>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <gatilho v-if="novo || editar" class="gatilho" @save="save" ref="gatilho"></gatilho>
      </transition>
      <gatilhos-controller ref="gatilhos" @edit="triggerEdit" @activate="save" @remove="remove"></gatilhos-controller>
    </div>
  </controller>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service'
import GatilhosController from '@/components/ferramentas/gatilhos/template/GatilhosController'
import Gatilho from '@/components/ferramentas/gatilhos/form/Gatilho'

export default {
  components: {
    GatilhosController, Gatilho
  },
  data () {
    return {
      novo: false,
      editar: false
    }
  },
  mounted () {

  },
  methods: {
    toggleNew () {
      this.novo = !this.novo
    },
    refreshList () {
      this.editar = false
      this.novo = false
      setTimeout(() => {
        this.$refs.gatilhos.getGatilhos()
      },1000)
      
    },
    triggerEdit ( gatilho ) {
      this.editar = true
      this.novo = false
      this.$nextTick(() => {
        this.$refs.gatilho.editGatilho(gatilho)
      })
      
    },
    save ( gatilho ) {

      if ( gatilho.loading ) delete gatilho.loading
      
      FerramentasService.saveGatilho(gatilho).then(
        response => {
          // console.log("saveGatilho:", response)
          if ( response.status == 200 || response.status == 201 ) {
            gatilho.loading = false
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Gatilho salvo com sucesso.", floater: true})
            this.refreshList()
          }
          window.spinner.emit("close")
          
        },
        error => {
          // console.log("error:", error)
          gatilho.loading = false
          const message = error.response.data.error ? error.response.data.error : 'Desconhecido'
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível gravar o gatilho. Err:" + message, floater: true})
        }
      )
    },
    remove ( gatilho ) {
      FerramentasService.deleteGatilho( gatilho ).then(
        response => {
          if ( response.status == 200 ) {
            window.spinner.emit("close")
            this.refreshList()
          }
        },
        error => {
          // console.log("error:", error.response.data)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível remover o item selecionado. Erro:" + error.response.data, floater: true})
        }
      )
    }
  }

}
</script>

<style lang="scss" scoped>
  .page-inner {
    margin-bottom: $mg_extra;

    @media screen and ( max-width: 640px) {
      padding: $hmg 0 !important;
    }

    .page-inner {
      header {
        // padding: $hmg_small $hmg 0;
      }
    }
  }

  .gatilho {
    @extend %transition_4e;
  }
</style>