<template>
  <section class="supermenu">
    <main>
      <header>
        <p>Navegação</p>
        <h2>{{ scope.name }}</h2>
        <a href="#" @click.prevent="$emit('close')" class="btClose">
          <span></span>
          <span></span>
        </a>
        <div class="search-form">
          <input-control v-model="query" placeholder="O que você procura?" @keyup="search()" search preventSearchButton></input-control>
          <a href="@" @click.prevent="clearSearch()">
            <fa :icon="['fal','trash-xmark']" class="icon"></fa>
          </a>
        </div>
      </header>
      <div class="menuWrap" v-for="(menu, i) in supermenu" :key="menu">
      <h3>{{ menu.name }}</h3>
        <ol>
          <li v-for="(item, ii) in menu.menu" :key="item" :ref="'item_' + i + '_' + ii">
            <card :marked="item.marked" hover>
              <a href="#" class="menuCard" @click.prevent="goToRoute(item)">
                <fa v-if="item.numeroDashboard" :icon="['far','square-poll-vertical']" class="icon painel"></fa>
                <fa v-else-if="item.tipo_url == 'blank' || ( item.externo == 1 && !item.numeroDashboard && !item.angular )" :icon="['far','arrow-up-right-from-square']" class="icon externo"></fa>
                <fa v-else :icon="['far','link-simple']" class="icon apollo"></fa>
                <span>
                  <p v-if="item.numeroDashboard">{{ parseDashNum(item.numeroDashboard) }}</p>
                  <h5>{{ item.name }}</h5>
                </span>
              </a>
            </card>
          </li>
        </ol>
      </div>
    </main>
  </section>
</template>

<script>
import UserService from  '@/services/user.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      supermenu: [],
      sub: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      query: null,
      tokenLaravel: process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN
    }
  },
  mounted () {
    this.createMenu()
  },
  methods: {
    createMenu () {
      const generic = []
      const supermenu = []

      this.scope.menu.map( a => {
        const hasSub = a.menu.length > 0

        if ( hasSub ) {
          supermenu.push(a)
        } else {
          generic.push(a)
        }

      })

      if ( generic.length > 0 ) {
        supermenu.push({ name: "", menu: generic })
      }
      this.menubkp = supermenu
      this.supermenu = supermenu
    },
    parseDashNum ( dash ) {
      const num = dash.split("/")[1]
      // dash.num = num
      return num
    },
    goToRoute ( item ) {
      console.log("goTo:", item)
      //externo
      if ( item.router_link && item.tipo_url == 'blank' && item.angular == 0 || item.externo == 1 && item.angular == 0 && !item.numeroDashboard ) {
        window.open(item.router_link)
      } else if ( item.externo == 1 && item.numeroDashboard && item.angular == 0 ) {
        // const query = this.getQueryStringParams(item.router_link)
        this.$router.push({name: 'Painel', params: { id: item.numeroDashboard }, query: {path: item.router_link} })
        this.closePanel()
      } else if ( item.externo == 1 && item.angular == 1 && item.router_link ) {
        this.$router.push({
          name: 'LinkExterno',
          params: {
            id: item.id, isMetabase: 0, isUrl: 0, token: this.tokenLaravel, email: this.currentUser.email 
          },
          query: { 
            numero_dashboard: 0, path: item.router_link
          }
        })
        this.closePanel()
      } else {
        this.$router.push(item.router_link)
        this.closePanel()
      }

      this.saveRotaFavorita(item)
      
    },
    saveRotaFavorita ( route ) {
      console.log("route:", route)
      const idPessoa = this.currentUser.idPessoa
      const data = {
        idPessoa: idPessoa,
        idRole: null,
        idPermission: route.id
      }
      // console.log("saveRotaFavorita:", data)

      UserService.saveRotaFavorita(data).then(
        response => {
          console.log("saveRotaFavorita:", response.data)
        }
      )
    },
    closePanel () {
      this.$emit("close")
    },
    search () {
      if ( this.query && this.query.length >= 1 ) {
        this.searchWait = setTimeout(() => {
          this.supermenu.map( (k, i) => {
            k.menu.map(( km, ii ) => {
              km.marked = false
              const num = km.numeroDashboard
              const nome = km.name.toLowerCase()
              const query = this.query.toLowerCase()
              const existNome = nome.includes(query)
              const existNum = num ? num.includes(query) : false

              km.marked = existNome || existNum

              if ( existNome || existNum ) {
                const item = this.$refs['item_' + i + '_' + ii][0]
                if ( item ) item.scrollIntoView({ behavior: "smooth" })
              }
            })
          })
        }, 200);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .supermenu {
    background-color: $color-bg;
    width: 100%; height: 100vh;
    padding: 60px 0 0 60px; top:0; left: 0;
    position: fixed;
    z-index: 500;

    main {
      max-height: 100%;
      padding: $mg;
      overflow-y: auto;
      @extend %scrollbar;
      
      >header {
        margin-bottom: $mg;
        position: relative;

        > h2 {
          color: $color-primary;  
        }
        > p{
          text-transform: uppercase;
          margin-bottom: 0;
          font-size: $font-size-mini;
          font-weight: bold;
        }

        .search-form {
          max-width: 440px;
          display: flex; align-items: center; align-content: center;
          margin-top: $hmg;

          a {
            margin-left: $hmg_small;
            .icon {
              font-size: 16px;
            }

            &:hover {
              .icon {
                color: $color-primary
              }
            }
          }
        }
        .btClose {
          display: block; height: 40px; width: 40px;
          position: absolute; top: 0; right: 0;

          span {
            display: block; width: 2px; height: 24px;
            position: absolute; top: 50%; left: 0; right: 0;
            border-radius: 2px;
            margin: auto; transform-origin: top center;
            background-color: $color-text;
            @extend %transition_6e;

            &:first-child {
              transform: rotate(45deg) translateY(-50%);
            }
            &:last-child {
              transform: rotate(-45deg) translateY(-50%);
            }
          }

          &:hover {
            span {
              background-color: $color-primary;

              &:first-child {
                transform: rotate(315deg) translateY(-50%);
              }
              &:last-child {
                transform: rotate(-315deg) translateY(-50%);
              }
            }
          }
        }
      }
      
      .menuWrap {
        margin-bottom: $mg;

        ol {
          padding: 0; margin: 0;
          display: flex; align-content: flex-start; align-items: flex-start;
          flex-wrap: wrap;

          li {
            padding: 0; margin: $hmg_mid $hmg_mid 0 0;
            list-style: none; width: 220px; height: 140px;
            
            .menuCard {
              display: flex; flex-direction: column;
              justify-content: space-between; 
              align-items: flex-start; align-content: flex-end;
              padding: $hmg_mid;
              width: 100%; height: 140px;
              
              p {
                margin-bottom: $hmg_mini
              }
              h5 {
                font-size: 1.1rem
              }
              .icon {
                font-size: 14px;
                &.painel {
                  color: $color-painel
                }
                &.externo {
                  color: $color-externo
                }
                &.apollo {
                  color: $color-apollo
                }
              }
            }

            &.marked {

            }

            &.small {
              height: 100px;
              .menuCard {
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
</style>