<template>
  <header>
    <div>
      <a href="/atividades/pesquisa" class="btn btn-bg btn-small">
        <fa :icon="['fas', 'filter']" /> &nbsp;
        Filtro Avançado
      </a>
      <div class="filterControl">
        <label class="check-control">
          <input type="checkbox" class="check view" v-model="filter.view" :checked="filter.view" @change="toggleFilter()"/>
          Ver
        </label>
        <!-- <label class="check-control">
          <input type="checkbox" class="check edit"  v-model="filter.edit" :checked="filter.edit" @change="toggleFilter()"/>
          Editar
        </label> -->
        <label class="check-control">
          <input type="checkbox" class="check execute" v-model="filter.execute" :checked="filter.execute" @change="toggleFilter()"/>
          Executar
        </label>
      </div>
      <!-- <date-filter></date-filter> -->
    </div>
    <div class="listActions" v-if="!isMobile">
      <a href="#" :class="{active: active == 'kanban' }" @click.prevent="toggleListState('kanban')">
        <fa :icon="['fal','square-kanban']"/>
      </a>
      <a href="#" :class="{active: active == 'list' }" @click.prevent="toggleListState('list')">
        <fa :icon="['fal','list-ul']"/>
      </a>
    </div>
  </header>
</template>

<script>
// import DateFilter from '@/components/atividades/utilities/DateFilter.vue'

export default {
  components:{
    // DateFilter
  },
  props: {
    isMobile: {
      type: Boolean
    }
  },
  data () {
    return {
      active: 'kanban',
      filter: {
        view: true,
        // edit: true,
        execute: true
      }
    }
  },
  mounted () {

  },
  methods: {
    toggleListState (state) {
      this.active = state
      this.$emit("listState",state)
    },
    toggleFilter () {
      setTimeout(() => {
        this.$emit('onFilter', this.filter)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex; align-content: center; align-items: center;
    justify-content: space-between;
    width: 100%; height: 50px;
    border-bottom: $border-component;
    padding: 0 $hmg_mid;
    position: sticky; top: 60px;
    background-color: $color-bg;
    z-index: 300;

    .listActions {
      display: flex; align-content: center; align-items: flex-end;
      gap: $hmg_mini;

      a {
        font-size: 18px;
        width: 36px; height: 36px;
        display: inline-flex; flex-direction: column;
        align-items: center; justify-content: center;
        border-radius: $border-radius;

        &.active {
          color: $color-primary;
          background-color: $color-secondary
        }
      }
    }

    >div:first-child {
      display: flex; align-items: center;
      gap: $hmg_mid;

      label {
        margin: 0; padding: 0;
      }
    }
  }

  .filterControl {
    display: flex; align-items: center;
    gap: $hmg_mid;
    
    label { margin: 0; padding: 0; }
  }

  
</style>