<template>
  <section class="infoBar" v-if="aluno">
    <header>
      <h5>Central do Aluno</h5>
      <h2 class="nome">{{ aluno.Basicos.nome.toLowerCase() }}</h2>
      <p>{{ aluno.Aluno.emailAcademico }}</p>
      <div class="rWrap">
        <span>
          <p>RM</p>
          <h5>{{ aluno.Aluno.rm }}</h5>
        </span>
        <span>
          <p>RA</p>
          <h5>{{ aluno.Aluno.ra }}</h5>
        </span>
      </div>
      <div class="pic" :style="{ backgroundImage: `url('${ alunoPic() }')`}"></div>
    </header>
    <!-- <div>
      <div v-if="perfilPedagogico">
        <div>
          <fa :icon="['far', 'id-card']" class="i"/>
          <h5>Perfis</h5>
        </div>
        <nav>
          <a href="#" class="btn btn-bg btn-small" v-for="profile in matricula.perfislPessoa" :key="profile" @click.prevent="toggleActiveProfile(profile)">{{ profile.nome }}</a>
        </nav>
      </div>
      <div v-if="activeProfile" class="activeProfile">
        <h5>Perfil {{ activeProfileName  }}</h5>
        <textarea-control v-model="activeProfile" textonly></textarea-control>
      </div>
    </div> -->
    <div class="contactInfo">
      <ul>
        <li class="labeledDetail">
          <label>Nascimento</label>
          <p>{{ aluno.Basicos.dataNascimento }}</p>
        </li>
        <li class="labeledDetail" v-if="aluno.Documento">
          <label>RG</label>
          <p>{{ aluno.Documento.rg ?? '...' }}</p>
        </li>
        <li class="labeledDetail" v-if="aluno.Documento">
          <label>CPF</label>
          <p>{{ aluno.Documento.cpf ?? '...' }}</p>
        </li>
      </ul>
      <ul v-if="aluno.Endereco">
        <li class="labeledDetail">
          <label>Endereço</label>
          <p>{{ resolveAddress(aluno.Endereco) }}</p>
        </li>
      </ul>
    </div>
    <div>
      <filiacao :aluno="aluno" v-if="aluno" />
    </div>
    <div>
      <ficha-saida :aluno="aluno" v-if="aluno" />
    </div>
    <div>
      <ficha-saude :aluno="aluno" v-if="aluno" />
    </div>
  </section>
</template>

<script>
import { formatDate } from '@/services/utils.service.js'
import Filiacao from '@/components/centralaluno/utilities/Filiacao.vue'
import FichaSaida from '@/components/centralaluno/utilities/FichaSaida.vue'
import FichaSaude from '@/components/centralaluno/utilities/FichaSaude.vue'

export default {
  components: {
    Filiacao, FichaSaida, FichaSaude
  },
  props: {
    aluno: {
      type: Object
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log("aluno:", this.aluno)
  },
  methods: {
    formatDate ( date ) {
      return formatDate(date)
    },
    resolveAddress ( data ) {
      const logradouro = data.logradouro ?? '[sem logradouro]'
      const numero = data.numero ?? '[sem número]'
      const complemento = ' - ' + data.complemento ?? ''
      const bairro = data.bairro ?? '[sem bairro]'
      const cep = data.cep ?? '[sem cep]'
      const cidade = data.nomeCidade ?? '[sem cidade]'
      const uf = data.nomeUf ?? '[sem UF]'
      
      return `${logradouro}, ${numero}${complemento} - ${bairro} - ${cep} - ${cidade}/${uf}`
    },
    alunoPic () {
      if ( this.aluno.Basicos.urlFoto ) {
        return this.aluno.Basicos.urlFoto
      } else {
        const src = '/img/'
        return src + ( this.aluno.Basicos.sexo == 'F' ? 'avatar_manu.jpg' : 'avatar_rafinha.jpg') 
      }

    }
  }
}
</script>

<style lang="scss" scoped>
  .infoBar {
    // min-height: 100%;


    > header {
      padding: $hmg_mid;
      border-bottom: $border-component;
      position: relative;
      position: sticky; top: 60px;
      z-index: 100;
      background-color: $color-secondary;
      
      >h5 {
        color: $color-primary;
      }
      .nome {
        text-transform: capitalize;

        @media screen and (max-width: 1024px) {
          padding-right: 80px
        }
      }

      .rWrap {
        display: flex; align-items: center;
        gap: $mg_mini; margin-top: $mg_mini;

        span {
          display: flex; align-items: center; align-content: center;
          justify-content: space-between;
          padding: $mg_mini;
          background-color: $color-bg;
          border-radius: $border-radius;
          gap: $hmg;
          line-height: 1;

          p {
            font-weight: bold;
            margin: 0; padding: 0;
          }
          h5 {
            margin: 0; padding: 0;
          }
        }
      }

      .pic {
        width: 80px; height: 80px;
        position: absolute; top: $hmg_mid;
        right: 0; transform: translateX(24px);
        border-radius: $border-radius-large;
        border: $border-component;
        background-color: $color-secondary;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media screen and (max-width: 1024px) {
          transform: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0
        }
      }
    }

    > div {
      padding: $hmg_mid;
      border-bottom: $border-component;
      position: relative; z-index: 99;

      &:last-child {
        border-bottom: 0
      }
    }

    .contactInfo {
      ul {
        padding: 0; margin: 0;
        display: flex; align-items: flex-start; align-content: flex-start;
        gap: $mg;

        li {
          list-style: none;
          padding: 0; margin: 0 0 $hmg_small;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .labeledDetail {
      label {
        font-size: $font-size-small;
        font-weight: bold;
        padding: 0; margin: 0 0 $hmg_mini 0
      }
      p {
        margin: 0; padding: 0;
      }
    }
  }
</style>