<template>
  <section>
    <div ref="atendForm" class="form-wrap" v-if="ready && !waiting">
      <div class="row no-padd">
        <div class="form-group col-md-4">
          <input-control 
            v-model="atendimento.dataOcorrencia"
            type="date"
            name="dataOcorrencia"
            :valid="valid.dataOcorrencia"
            @keyup="valid.dataOcorrencia = true"
            label="Data Ocorrência"
            required
          ></input-control>
        </div>
      </div>
      <div class="row no-padd">
        <div class="form-group col-md-8">
          <select-control 
            v-model="atendimento.idAtividadeCategoria" 
            :options="options.categorias"
            name="idAtividadeCategoria"
            label="Categoria"
            placeholder="Selecione"
            @change="selectCategory()"
            required
          ></select-control>
        </div>
        <div class="form-group col-md-4">
          <select-control 
            v-model="atendimento.idAtendimentoCanal" 
            :options="options.canais"
            name="idAtendimentoCanal"
            label="Canal"
            placeholder="Selecione"
            required
          ></select-control>
        </div>
      </div>
      <div class="row no-padd">
        <div class="form-group col-md-12">
          <div class="searchWrap">
            <input-spinner v-if="pessoa.loading" class="searchSpinner"></input-spinner>
            <input-control 
              :label="pessoa.type ? pessoa.type : 'Pessoa'"
              v-model="pessoa.query" 
              placeholder="Digite para buscar.." 
              :disabled="!pessoa.type && !pessoa.idPessoa" 
              :valid="valid.idPessoa"
              name="idPessoa"
              @keyup="searchPessoa()"
              @blur="trackClearSearch()"
              required
            ></input-control>
            <input type="hidden" v-model="atendimento.idPessoa">
            <input type="hidden" v-model="atendimento.idColaborador">
            <input type="hidden" v-model="atendimento.idresponsavel">
            <input type="hidden" v-model="atendimento.idMatricula">
            <input type="hidden" v-model="atendimento.idUnidade">
            <!-- <a href="#" class="remove" @click.prevent="remove(i)">
              <fa :icon="['fas','trash-can']"></fa>
            </a> -->
            <div class="listWrap" v-if="pessoa.list && pessoa.list.length > 0">
              <div class="list">
                <div>
                  <table>
                    <tr v-for="li in pessoa.list" :key="li" @click="selectPessoa(li)">
                      <template v-if="pessoa.type == 'Colaborador'">
                        <td>
                          {{ li.nome }}
                        </td>
                        <td>
                          {{ li.email }}
                        </td>
                      </template>
                      <template v-if="pessoa.type == 'Aluno' || pessoa.type == 'Responsável'">
                        <td>
                          {{ li.nomePessoa }} {{ pessoa.type == 'Responsável' ? ' - ' + li.nomeAluno : '' }}
                        </td>
                        <td>
                          {{ li.nomeCurso }}
                        </td>
                        <td>
                          {{ li.nomeTurma }}
                        </td>
                        <td v-if="pessoa.type == 'Aluno'">
                          {{ li.email }}
                        </td>
                        <td>
                          {{ li.nomePeridoletivo }}
                        </td>
                      </template>
                      <td>
                        {{ li.nomeUnidade }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-padd">
        <div class="form-group col-md-12">
          <div class="searchWrap">
            <input-spinner v-if="operador.loading" class="searchSpinner"></input-spinner>
            <input-control 
              label="Operador"
              v-model="operador.query" 
              placeholder="Digite para buscar.."
              name="idUserAtribuido"
              :valid="valid.idUserAtribuido"
              @keyup="searchPessoa(true)"
              @blur="trackClearSearch(true)"
            ></input-control>
            <input type="hidden" v-model="atendimento.idUserAtribuido">
            <!-- <a href="#" class="remove" @click.prevent="remove(i)">
              <fa :icon="['fas','trash-can']"></fa>
            </a> -->
            <div class="listWrap" v-if="operador.list && operador.list.length > 0">
              <div class="list">
                <div>
                  <table>
                    <tr v-for="li in operador.list" :key="li" @click="selectOperador(li)">
                      <td>
                        {{ li.nome }}
                      </td>
                      <td>
                        {{ li.email }}
                      </td>
                      <td>
                        {{ li.nomeUnidade }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-padd">
        <div class="form-group col-md-12">
          <input-control
            label="Link Externo"
            placeholder="Ex. http://linkexterno.com.br"
            v-model="atendimento.linkExterno"
          ></input-control>
        </div>
      </div>
      <div class="row no-padd">
        <div class="form-group col-md-12">
          <textarea-control
            label="Observações"
            placeholder="Insira aqui observações para quem vai executar este atendimento"
            v-model="atendimento.observacoes"
            resize
          ></textarea-control>
        </div>
      </div>
    </div>
    <div v-else class="spinnerWrap">
      <span class="loader"></span>
    </div>
    <footer>
      <div>
        <a href="#" class="btn btn-secondary" @click.prevent="cancel()">Cancelar</a>
      </div>
      <div>
        <a href="#" :class="['btn btn-primary', { disabled: running }]" @click.prevent="submit()">{{ this.id ? 'Editar' : 'Criar' }}</a>
        <transition name="shut">
          <a href="#" v-if="allowResponse()" :class="['btn btn-primary', { disabled: running }]" @click.prevent="submit(true)">{{ this.id ? 'Editar' : 'Criar' }} e Responder</a>
        </transition>
      </div>
    </footer>
  </section>
</template>

<script>
import AtendimentoService from '@/services/atendimento.service.js'
import inputSpinner from '@/components/utilities/InputSpinner'
import FormService from '@/services/form.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  components: {
    inputSpinner
  },
  props: {
    id: {
      type: [Number, String]
    }
  },
  watch: {
    id ( value ) {
      if ( value ) {
        this.getAtendimento(value)
      }
    }
  },
  data () {
    return {
      ready: false,
      waiting: true,
      running: false,
      options: {
        categorias: null,
        canais: null
      },
      atendimento: {
        idAtividadeCategoria: null,
        idAtendimentoCanal: null,
        dataOcorrencia: null,
        idPessoa: null,
        idUserAtribuido: null,
        idColaborador: null,
        idResponsavel: null,
        idMatricula: null,
        idUnidade: null,
        linkExterno: null,
        observacoes: null
      },
      valid: {
        dataOcorrencia: true,
        idAtividadeCategoria: true,
        idAtendimentoCanal: true,
        idPessoa: true
      },
      wait: {
        categorias: false,
        canais: false
      },
      pessoa: {
        query: null,
        loading: false,
        list: [],
        type: null
      },
      operador: {
        query: null,
        loading: false,
        list: []
      },
      category: null,
      waitSearch: null
    }
  },
  mounted () {
    this.getCategoriasAtendimento()
    this.getCanaisAtendimento()

    console.log("atendimento - id:", this.id)

    if ( this.id ) {
      this.getAtendimento(this.id)
    }
  },
  methods: {
    getCategoriasAtendimento () {
      AtendimentoService.getCategoriasAtendimento().then(
        response => {
          console.log("categorias:", response.data)
          this.options.categorias = createSelectOptions(response.data,'idAtividadeCategoria','nome')

          this.wait.categorias = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getCanaisAtendimento () {
      AtendimentoService.getCanaisAtendimento().then(
        response => {
          console.log("canais:", response.data)
          this.options.canais = createSelectOptions(response.data,'idAtendimentoCanal','nome')
          
          this.wait.canais = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getAtendimento ( id ) {
      if ( this.ready ) {
        this.waiting = true
        AtendimentoService.getAtendimento(id).then(
          response => {
            console.log("get-atendimento:", response.data)
            this.atendimento = response.data
            
            const tipoPessoa = this.filterPessoa(response.data.idPerfilPessoa, true)

            this.atendimento.dataOcorrencia = response.data.dataOcorrencia.split(" ")[0]
            this.pessoa.idPessoa = response.data.idPessoa

            if ( tipoPessoa == 'alunos' ) {
              this.pessoa.query = response.data.alunoNome
            }
            if ( tipoPessoa == 'colaboradores' ) { 
              this.pessoa.query = response.data.pessoaNome
            }
            if ( tipoPessoa == 'responsaveis' ) {
              this.pessoa.query = response.data.responsavelNome ? response.data.responsavelNome : response.data.pessoaNome
            }
            
            this.atendimento.idUserAtribuido = response.data.idUserAtribuido
            this.operador.query = response.data.userNome
            
            this.selectCategory(true)
            this.waiting = false
          },
          () => {
            this.waiting = false
          }
        )
      } else {
        setTimeout(() => this.getAtendimento(id), 200)
      }
      
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.ready = true
        if ( !this.id ) this.waiting = false
      }
      // console.log("finished:", finished)

    },
    selectCategory ( skipClear ) {
      if ( !skipClear ) {
        this.clearSearch()
      }
      
      const category = this.options.categorias.filter( a => a.idAtividadeCategoria == parseFloat(this.atendimento.idAtividadeCategoria) )[0]

      this.category = category
      this.pessoa.type = this.filterPessoa(this.category.idPerfilPessoa)

      this.allowResponse()
    },
    filterPessoa ( idTipo, slug ) {
      var pessoa = null

      if ( idTipo == 1 ) {
        pessoa = slug ? 'alunos' : 'Aluno'
      } else if ( idTipo == 2 ) {
        pessoa = slug ? 'colaboradores' : 'Colaborador'
      } else if ( idTipo == 3 ) {
        pessoa = slug ? 'responsaveis' : 'Responsável'
      }

      return pessoa
    },
    searchPessoa ( operador ) {
      if ( this.waitSearch ) clearInterval(this.waitSearch)
        console.log("search:", this.pessoa.query)

        this.waitSearch = setTimeout ( () => {
          const nome = operador ? this.operador.query : this.pessoa.query
          const pessoa = operador ? 'colaboradores' : this.filterPessoa(this.category.idPerfilPessoa, true)

          if ( nome.length == 0 ) {
            this.clearSearch( operador )
            
            if ( operador ) {
              this.operador.loading = false
            } else {
              this.pessoa.loading = false
            }
            
          } else {
            if ( operador ) {
              this.operador.loading = true
            } else {
              this.pessoa.loading = true
            }

            AtendimentoService.getPessoaByNome(pessoa, nome).then(
              response => {
                console.log("getPessoaByNome:", response.data)
                if ( response.data.length > 0 ) {
                  if ( operador ) {
                    this.operador.list = response.data
                  } else {
                    this.pessoa.list = response.data
                  }
                  
                } else {
                  if ( operador ) {
                    this.operador.valid = false
                  } else {
                    this.pessoa.valid = false
                  }

                  this.clearSearch(operador)
                }

                this.pessoa.loading = false
              },
              () => {
                this.pessoa.loading = false
              }
            )
          }
          
        }, 500)
    },
    clearSearch ( operador ) {
      if ( operador ) {
        this.operador.query = ''
        this.operador.list = []
        this.operador.loading = false
        this.atendimento.idUserAtribuido = null
      } else {
        this.pessoa.query = ''
        this.pessoa.list = []
        this.pessoa.loading = false
        this.atendimento.idColaborador = null
        this.atendimento.idMatricula = null
        this.atendimento.idResponsavel = null
        this.atendimento.idPessoa = null
        this.atendimento.idUnidade = null
      }
      
    },
    trackClearSearch ( operador ) {
      setTimeout(() => {
        const hasValue = operador ? this.atendimento.idUserAtribuido : this.atendimento.idPessoa

        if ( !hasValue ) this.clearSearch(operador)
      },500)
    },
    selectPessoa ( pessoa ) {
      this.atendimento.idPessoa = pessoa.idPessoa
      this.atendimento.idUnidade = pessoa.idUnidade

      this.pessoa.query = this.pessoa.type == 'Colaborador' ? pessoa.nome : pessoa.nomePessoa

      if ( this.pessoa.type == 'Colaborador' ) this.atendimento.idColaborador = pessoa.idColaborador
      if ( this.pessoa.type == 'Aluno' ) this.atendimento.idMatricula = pessoa.idMatricula
      if ( this.pessoa.type == 'Responsável' ) { 
        this.atendimento.idresponsavel = pessoa.idresponsavel
        this.atendimento.idMatricula = pessoa.idMatricula
      }
      
      this.pessoa.list = []
    },
    selectOperador ( pessoa ) {
      // console.log("operador: ", pessoa)
      this.atendimento.idUserAtribuido = pessoa.idPessoa
      this.operador.query = pessoa.nome
      this.operador.list = []
      this.operador.loading = false
    },
    cancel () {
      this.$emit('onCancel')
    },
    submit ( execute ) {
      console.log("submit atendimento:", this.atendimento)

      const valid = FormService.validate(this.$refs.atendForm, this.valid)

      if ( valid.response ) {
        this.running = true
        AtendimentoService.saveAtendimento( this.atendimento ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Atendimento criado com sucesso.", floater: true})
            }
            
            setTimeout(() => {
              if ( execute ) {
                // console.log("execute", { section: 'atendimentos', action: 'ver', id: response.data.criaAtividade.idAtividade })
                
                // this.$router.push({ name: 'Painel Atividades', params: { section: 'atendimentos', action: 'ver', id: response.data.criaAtividade.idAtividade }})
                this.$emit("onSave", response.data.criaAtividade.idAtividade)
              } else {
                console.log("emit save...")
                this.$emit("onSave")
              }

              this.running = false
            },1000)
          },
          () => {
            this.running = false
            window.toaster.emit('open', {style: "error", message: "Não foi possível salvar este atendimento. Valide os dados e tente novamente. Se o problema persistir, procure nosso time de suporte.", floater: true})
          }
        )
      } else {
        //
      }
    },
    allowResponse () {
      if ( this.atendimento.idAtividadeCategoria ) {
        var categoria = this.options.categorias.filter( a => a.idAtividadeCategoria == this.atendimento.idAtividadeCategoria )
        // console.log("categoria:", categoria)
        const allow = categoria[0].kanban == 1
        // console.log("allow:", allow)

        return allow
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-wrap {
    padding: $hmg_mid $mg_mini;
  }

  footer {
    display: flex;
    align-items: center; justify-content: space-between;
    padding: $hmg_mid;
    border-top: $border-component;

    @media screen and ( max-width: 1024px ) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: $hmg_mid;
    }

    > div {
      display: flex;
      align-content: flex-end;
      gap: $hmg_mid;

      @media screen and ( max-width: 1024px ) {
        gap: $mg_mini;
        justify-content: space-between;
      }
    }
  }

  .searchWrap {
    position: relative;

    .remove {
      position: absolute;
      right: $hmg_small; top: 50%;
      transform: translateY(-50%);

      >* {
        @extend %transition_3o;
      }
      
      &:hover {
        color: $color-primary
      }
    }
  }

  .listWrap {
    position: relative;
    width: 100%;

    .list {
      position: absolute; 
      width: 100%; max-height: 200px;
      left: $hmg_mini; top: -$hmg_mini;
      overflow: hidden;
      z-index: 100;
      padding: $hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;
      border: $border-component;

      > div {
        padding: 0 $mg_mini 0 0; margin: 0; 
        max-height: calc(200px - $hmg_mid);
        overflow-y: auto;
        @extend %scrollbar_card;

        table {
          border-collapse: collapse;
          width: 100%;
          
          tr {
            @extend %transition_3o;
            cursor: default;
            vertical-align: middle;

            &:hover {
              background-color: $color-bg;
            }
            td {
              padding: $mg_mini;
              cursor: default; line-height: 1;

              &:first-child {
                border-top-left-radius: $border-radius-small;
                border-bottom-left-radius: $border-radius-small;
              }
              &:last-child {
                border-top-right-radius: $border-radius-small;
                border-bottom-right-radius: $border-radius-small;
              }
            }
          }
        }
      }

      ul {
        padding: 0 $mg_mini 0 0; margin: 0; max-height: 100%;
        overflow-y: auto;
        @extend %scrollbar_card;
        
        li {
          display: flex;
          align-content: center; align-items: center;
          justify-content: space-between;
          list-style: none;
          padding: $hmg_mini;
          margin: 0;
          @extend %transition_3o;
          cursor: pointer;

          &:hover {
            background-color: $color-bg;
            border-radius: $border-radius-small;
          }
          span {
            flex: 1;
            padding: 0 $mg_mini;

            &:first-child,
            &:last-child {
              padding: 0
            }

            &:last-child {
              flex: 0;
              text-align: right;
            }

            &:nth-child(1) {
              width: 30%;
            }
          }
        }
      }
    }
  } 

  .searchSpinner { 
    position: absolute; top: 16px;
    right: $hmg_mid;
    z-index: 100;
  }

  .spinnerWrap {
    width: 100%; padding-top: $mg;

    .loader {
      width: 100%;
      height: 6px;
      display: block;
      position: relative;
      overflow: hidden;
      background-color: $color-bg;
    }
    .loader::after {
      content: '';  
      width: 96px; height: 6px;
      background-color: $color-primary;
      position: absolute; top: 0; left: 0;
      box-sizing: border-box;
      animation: hit 0.6s ease-in-out infinite alternate;
    }

    @keyframes hit {
      0% {
        left: 0;
        transform: translateX(-1%);
      }
      100% {
        left: 100%;
        transform: translateX(-99%);
      }
    }
  }
</style>