<template>
  <section class="appRema">
    <header>
      <logo :color="env == 'PROD' ? '#ED1C24' : '#444'" size="40px"/>
      <h1>Entre o sonho e a realização existe uma Decisão.</h1>
      <p>Chegou a hora de garantir sua rematrícula para 2025. Mas antes, não deixe de conferir o vídeo que preparamos para você.</p>
    </header>
    <div>
      <div class="thumb" @click="videoOn = true">
        <div><span></span></div>
      </div>
      <transition name="fadein">
        <div class="video" v-if="videoOn">
          <iframe 
            :width="videoConfigs.usable.w" 
            :height="videoConfigs.usable.h" 
            :src="video" 
            title="Vamos Construir Juntos em 2025!" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; modestbranding;" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <!-- <iframe
            :width="videoConfigs.usable.w" :height="videoConfigs.usable.h"
            :src="video"
            title="Vamos Construir Juntos em 2025!" 
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; modestbranding"
            allowfullscreen
          ></iframe> -->
          <a href="#" @click.prevent="videoOn = false">Fechar</a>
        </div>
      </transition>
    </div>
    <div class="sub">
      <p>Faça suas rematrículas, uma por vez e lembre-se de validar se seus dados estão todos corretos ou se é necessário atualizar alguma informação importante.</p>
      <h2>Você tem {{ remas.length }} {{ remas.length == 1 ? 'rematrícula disponível' : 'rematrículas disponíveis' }} para 2025</h2>
    </div>
    <div class="slider" v-if="ready">
      <div v-for="rema in remas" :key="rema">
        <span :class="segPic(rema)"></span>
        <div>
          <header>
            <p>{{ rema.nomeCurso }}</p>
            <p>{{ rema.nomePeridoletivo }}</p>
          </header>
          <div>
            <h3>{{ rema.nomeAluno.toLowerCase() }}</h3>
            <h4>{{ rema.nomeSerie }} - {{ rema.nomeTurma }}</h4>
          </div>
          <div>
            <template v-if="rema.bloqueios.length > 0">
              <div class="block-alert" v-if="hasBloqueioColab(rema)">
                <p>{{ hasBloqueioColab(rema, true) }}</p>
              </div>
              <template v-else-if="hasBloqueioFinanceiro(rema)">
                <div class="block-alert" >
                  <p>{{ hasBloqueioFinanceiro(rema, true) }}</p>
                </div>
                <a target="_blank" :href="'https://renegociacao.app.rededecisao.com/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjY4ODAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODAwMC9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTU2MzQ3NzY4NywiZXhwIjoxNTYzNDgxMjg3LCJuYmYiOjE1NjM0Nzc2ODcsImp0aSI6ImRtRjZsYUMwWUswVTdzSVIifQ.ynkU2CQ2Kr3yt-cxA3o21y2wkg2HRj-zckdBYDy2MNE&email=' + currentUser.email" class="btn btn-alert">
                  Renegociar
                </a>
              </template>
            </template>
            <router-link 
              v-else-if="rema.upsell"
              class="btn btn-primary" 
              :to="{ 
                name: 'Nova Matrícula Autônoma', 
                params: { 
                  action: 'pre',
                  step: 'inicio', 
                  idMatricula: rema.idMatricula 
                },
                query: {
                  cpm: rema.upsell
                }
              }">Matricular</router-link>
              <router-link 
              v-else
              class="btn btn-primary" 
              :to="{ 
                name: 'Nova Matrícula Autônoma', 
                params: { 
                  action: 'pre',
                  step: 'inicio', 
                  idMatricula: rema.idMatricula 
                }
              }">Matricular</router-link>
            <!-- <a href="#" @click.prevent="" class="btn btn-primary">Matricular</a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '@/components/template/logo.vue'
import MatriculaService from '@/services/matricula.service.js'
import { log } from '@/services/utils.service.js'

export default {
  components: {
    Logo
  },
  data () {
    return {
      env: null,
      videoConfigs: {
        raw: {
          w: 560,
          h: 315
        },
        usable: {
          w: 560,
          h: 315
        }
      },
      video: 'https://www.youtube-nocookie.com/embed/2NWAN4FhgoY?controls=0',
      videoOn: false,
      remas: [],
      ready: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    this.env = process.env.VUE_APP_ENV

    this.defineVideoSize()
    this.getRematriculas()
  },
  methods: {
    defineVideoSize () {
      const prop = 1.777777777777

      var w = this.videoConfigs.raw.w
      var h = this.videoConfigs.raw.h

      if ( window.innerWidth < this.videoConfigs.raw.w ) {
        w = window.innerWidth
        h = Math.floor(w / prop)
      }

      this.videoConfigs.usable.w = w
      this.videoConfigs.usable.h = h
    },
    getRematriculas () {
      // window.spinner.emit("open")
      MatriculaService.getHomeAppRema(0).then(
        response => {
          // console.log("response:", response)

          if ( response.data.propostaMatricula.length > 0 ) {
            const propostas = response.data.propostaMatricula.filter( k => k.appRema == 1 )
            // console.log("propostas:", propostas)
            this.remas = propostas
            this.ready = true

            this.trackCPM()
            this.log()
          }
        },
        error => {
          // window.spinner.emit("close")
          console.error(error.response.data)
        }
      )
    },
    trackCPM () {
      this.remas.map( k => {
        console.log('rema:', k)
        if ( k.siglaTipoCurso == 'R' ) {
          const match = this.remas.find(item => item.idAluno === k.idAluno && item.siglaTipoCurso == 'C')
          console.log('match:', match)
          if ( match ) {
            k['upsell'] = match.idMatricula
          }
        }
      })
      console.log('remas:', this.remas)
    },
    hasBloqueioFinanceiro ( proposta, dropMessage ) {
      const bloqueios = proposta.bloqueios

      if ( bloqueios.length > 0 ) {
        const has = bloqueios.filter( k => {
          return k.decricaoBloqueio == 'Financeiro'
        })

        const response = dropMessage ? has[0].mensagemBloqueio : has.length > 0 
        console.log("response:", response)
        return response
      }
    },
    hasBloqueioColab ( proposta, dropMessage ) {
      const bloqueios = proposta.bloqueios

      if ( bloqueios.length > 0 ) {
        const has = bloqueios.filter( k => {
          return k.decricaoBloqueio == 'Bolsistas'
        })

        const response = dropMessage ? has[0].mensagemBloqueio : has.length > 0
        console.log("response:", response)
        return response
      }
    },
    segPic ( rema ) {
      console.log("segPic:", rema)
      if ( rema.nomeCurso == 'Complementar' ) return 'cpm'
      if ( rema.nomeCurso == 'Educação Infantil' ) return 'edf'
      if ( rema.nomeCurso == 'Ensino Fundamental I' ) return 'efi'
      if ( rema.nomeCurso == 'Ensino Fundamental II' ) return 'efii'
      if ( rema.nomeCurso == 'Ensino Médio' ) return 'em'
    },
    log () {
      this.remas.map( k => {
        // console.log("k", k)
        if ( k.bloqueios.length > 0 ) {
          const idMatricula = k.idMatricula

          k.bloqueios.map( b => {
            const data = {
              "idBloqueioMatricula": b.idbloqueioMatricula,
              "idMatricula" : idMatricula,
              "local" : "Aplicativo", //Aplicativo ou APollo
              "mensagem" : "Responsável acessou o aplicativo"
            }

            log('bloqueios', data ).then(
              response => {
                console.log("log ok:", response.data)
              },
              () => {
                console.error("Ops. Algo deu errado. Não foi possível salvar o log enviado.")
              }
            )
          })
          
        }
      })

      
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .appRema {
    display: block;
    width: 100%; max-width: 1024px;
    margin: auto;
    overflow-y: auto; overflow-x: hidden;
    background-color: $color-secondary;
    
    >header {
      padding: $mg;

      @media screen and ( max-width: 414px ) {
        padding: $hmg;
      }

      h1 {
        margin: $hmg 0;
        font-size: 34px;
      }
      p {
        font-size: $font-size-larger;
        margin: 0; padding: 0;
      }
    }
    > div {
      padding: 0 $hmg;
      
      
    }
    .sub {
      background-color: $color-bg;
      margin-top: -$mg; padding: $mg $hmg $hmg;
      position: relative; z-index: 9;

      p {
        padding: $hmg; margin-top: $hmg;
        font-size: $font-size-larger;
      }
      h2 {
        padding: 0 $hmg;
      }
    }

    .thumb {
      width: 100%; height: 200px;
      background-image: url('@/assets/img/thumb_video_2025.jpg');
      background-position: center; background-size: cover;
      background-repeat: repeat;
      border-radius: $border-radius-large;
      position: relative; z-index: 10;

      @media screen and ( min-width: 1024px ) {
        height: 450px;
      }
      >div {
        display: flex; align-items:center; justify-content: center;
        width: 70px; height: 70px;
        border-radius: 100%;
        background-color: rgba(0,0,0,0.5);
        position: absolute; left: 0; right: 0;
        top: 50%; transform: translateY(-50%);
        margin: auto;
        @extend %transition_4e;

        
        >span {
          display: block;
          width: 0; height: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 30px solid $color-secondary;
          margin-left: 5px;
        }

        &:hover {
          transform: translateY(-50%) scale(1.1)
        }
      }
    }
    .video {
      position: fixed; display: flex;
      flex-direction: column; align-content: center;
      justify-content: center;
      @extend %transition_4o;
      width: 100%; height: 100%;
      background-color: rgba(0,0,0,0.9);
      backdrop-filter: blur(10px);
      z-index: 100; left: 0; top: 0;

      iframe {
        background-color: #000;
        margin: auto;
      }

      a {
        position: absolute;
        bottom: 20%;
        left: 50%; transform: translateX(-50%);
        color: $color-secondary;
        text-transform: uppercase;
        font-size: $font-size-large;
        font-weight: bold;

        &:hover {
          color: $color-primary
        }
      }
    }

    .slider { 
      display: flex;
      align-items: stretch; align-content: flex-start;
      padding: 0 0 $mg 0;
      overflow-x: auto; overflow-y: hidden;
      background-color: $color-bg;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      >div {
        width: 80vw; max-width: 300px; min-width: 260px;
        // height: 340px;
        display: flex; flex-direction: column;
        background-color: $color-secondary;
        border-radius: $border-radius;
        position: relative;
        margin-left: $hmg;
        overflow: hidden;
        
        &:last-child {
          margin-right: $hmg;
        }

        >span {
          height: 140px; width: 100%; display: block;
          background-color: $color-primary;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          &.edf { background-image: url('@/assets/img/edf.jpg') }
          &.efi { background-image: url('@/assets/img/efi.jpg') }
          &.efii { background-image: url('@/assets/img/efii.jpg') }
          &.em { background-image: url('@/assets/img/em.jpg') }
          &.cpm { background-image: url('@/assets/img/cpm.jpg') }
        }
        >div {
          padding: $hmg_mid;
          display: flex; flex-direction: column;
          justify-content: space-between;
          flex: 1;

          header {
            display: flex; align-items: center;
            justify-content: space-between;
            margin-bottom: $hmg_mid;

            p{
              margin: 0; padding: 0;
            }
          }
          h3 {
            padding: 0; margin: 0 0 $hmg_mini 0;
            text-transform: capitalize;
          }
          h4 {
            padding: 0; margin: 0;
          }
          a {
            margin-top: $hmg_mid
          }
        }
      }
    }

    .block-alert {
      margin-top: $hmg_mid;
      padding: $mg_mini;
      border-radius: $border-radius-small;
      background-color: $color-warning;
      color: $color-secondary;

      p {
        margin: 0; padding: 0;
      }
    }
  }
</style>