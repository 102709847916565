import authHeader from './auth-header'
import axios from 'axios'
import { googleLogout } from "vue3-google-login"

class AuthService {
  login(user) {
    console.log("login:", user)
    return axios
      .post(process.env.VUE_APP_API_URL + 'auth/login', {
        email: user.email,
        password: user.password,
        portal: user.type == 'resp' ? 'portalPais' : 'portalGestao'
      })
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.token) {

          localStorage.setItem('token', response.data.token)
          console.log("response.data.profile:", response.data.profile)
          localStorage.setItem('profile', btoa(JSON.stringify(response.data.profile)))
          console.log('Usuario Logado: ', response.data.profile)
        }

        return response.data
      })
  }

  loginApi(credentials) {
    return axios
      .post(process.env.VUE_APP_API_URL + 'auth/loginApi', credentials)
      .then(response => {
        console.log('response', response.data)
        
        if (response.data.token) {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('profile', btoa(JSON.stringify(response.data.profile)))
          
        }

        return response.data
      })
  }

  loginGoogle (email, credential) {
    return axios.post(process.env.VUE_APP_API_URL + 'auth/check_login_google', {
      google_user_email: email,
      google_user_token: credential,
      portal: 'portal'
    },{ headers: authHeader() })
    
  }

  logout() {
    googleLogout()
    localStorage.removeItem('token')
    localStorage.removeItem('profile')
  }

  reperarSenha ( user ) {
    user['portal'] = 'portalPais'
    return axios.post(process.env.VUE_APP_API_URL + 'auth/resetPassword', user, { headers: authHeader() })
  }

  resetGooglePassword ( email, senha ) {
    senha = senha ? senha : 0
    return axios.get(process.env.VUE_APP_API_URL + 'alterarsenha/' + email + '/' + senha, { headers: authHeader() })
  }

  getConfig () {
    return axios.get(process.env.VUE_APP_API_URL + 'configapollo', { headers: authHeader() })
  }
}

export default new AuthService()
