<template>
  <section>
    <div class="page-title" v-if="gestao">
      <h3 class="text-capitalize">Matrículas</h3>
    </div>
    <div v-if="!valid && loading" class="frame micro text-center validating" >
      <div class="iconWrap">
        <fa :icon="['fal', 'face-viewfinder']" class="icon"/>
      </div>
      <h3>Aguarde</h3>
      <p>Estamos validando seus dados cadastrais...</p>
    </div>
    <div class="page-inner frame fixed sm" v-else>
      <template v-if="respFinErr || respPedErr || fichaSaidaErr">
        <div class="frame fixed mini erros">
          <div class="details" v-if="aluno">
            <h5>Aluno</h5>
            <h2 class="color-text nome">{{ aluno.Basicos.nome.toLowerCase() }}</h2>
            <p>{{ matricula.descricaoPeriodoLetivo }} - {{ matricula.descricaoTurma }} | {{ matricula.descricaoUnidade }}</p>
          </div>
          <header class="frame fixed mini">
            <h4>Ops! Encontramos problemas no cadastro.</h4>
            <p>Como existem dados inconsistentes no cadastro, você precisará corrigí-los antes de prosseguir, ok?</p>
            
            <div class="errListWrap">
              <h5 class="color-text">Os campos com problema de preenchimento são:</h5>
              <div v-if="finErr.length > 0">
                <h6>Responsável Financeiro</h6>
                <ul class="errList">
                  <li v-for="err in finErr" :key="err">
                    {{ errLib[err] }}
                  </li>
                </ul>
              </div>
              <div v-if="pedErr.length > 0">
                <h6>Responsável Pedagógico</h6>
                <ul class="errList">
                  <li v-for="err in pedErr" :key="err">
                    {{ errLib[err] }}
                  </li>
                </ul>
              </div>
              <div v-if="fichaSaidaErr">
                <h6>Aluno</h6>
                <ul class="errList">
                  <li>Ficha de Saída</li>
                </ul>
              </div>
            </div>

            <a href="#" @click.prevent="goToMatricula()" class="btn btn-primary">
              Corrigir dados e fazer matrícula &nbsp;
              <fa :icon="['far','arrow-right-long']" />
            </a>
          </header>
        </div>
      </template>
      <template v-else>
        <header class="frame fixed mini">
          <h5>Agora ficou ainda mais rápido efetuar a matrícula na Rede Decisão.</h5>
          <p>O Primeiro passo é escolher se você precisa alterar alguma informação ou se já quer escolher a forma de pagamento e finalizar o processo.</p>
          <p><b>O Aluno será matriculado, conforme as informações abaixo:</b></p>
        </header>
        <div>
          <div class="details" v-if="aluno">
            <h5>Aluno</h5>
            <h2 class="color-text nome">{{ aluno.Basicos.nome.toLowerCase() }}</h2>
          </div>
          <div class="matBox" v-if="matricula">
            <div>
              <div>
                <fa :icon="['fal','school-flag']" class="icon"></fa>
                <span>
                  <h5>Unidade</h5>
                  <p>{{ matricula.descricaoUnidade }}</p>
                </span>
              </div>
              <div>
                <fa :icon="['fal','calendar-star']" class="icon"></fa>
                <span>
                  <h5>Período Letivo</h5>
                  <p>{{ matricula.descricaoPeriodoLetivo }}</p>
                </span>
              </div>
              <div>
                <fa :icon="['fal','chalkboard-user']" class="icon"></fa>
                <span>
                  <h5>Série</h5>
                  <p>{{ matricula.serieNomeExibicao }} - {{ matricula.descricaoCurso }}</p>
                </span>
              </div>
              <div>
                <fa :icon="['fal','screen-users']" class="icon"></fa>
                <span>
                  <h5>Turma</h5>
                  <p>{{ matricula.descricaoTurma }}</p>
                </span>
              </div>
            </div>
          </div>
          <div class="details multiple">
            <div v-if="responsavelFinanceiro">
              <h5>Responsável Financeiro</h5>
              <h3 class="color-text nome">{{ responsavelFinanceiro.Basicos.nome.toLowerCase() }}</h3>
              <ul class="respInfo">
                <li>
                  <b>CPF:</b> {{ responsavelFinanceiro.Documento.cpf }}
                </li>
                <li>
                  <b>RG:</b> {{ responsavelFinanceiro.Documento.rg }}
                </li>
                <li>
                  <b>Email:</b> {{ responsavelFinanceiro.Basicos.email }}
                </li>
                <li>
                  <b>Cel:</b> {{ responsavelFinanceiro.Basicos.telefonecelular }}
                </li>
                <li>
                  <b>End:</b> {{ responsavelFinanceiro.Endereco.logradouro }}, {{ responsavelFinanceiro.Endereco.numero }}
                              {{ responsavelFinanceiro.Endereco.complemento ? ' - ' + responsavelFinanceiro.Endereco.complemento : '' }}
                              - {{ responsavelFinanceiro.Endereco.bairro }} - {{ responsavelFinanceiro.Endereco.cep }} 
                              - {{ responsavelFinanceiro.Endereco.cidade }} / {{ responsavelFinanceiro.Endereco.uf }}
                </li>
              </ul>
            </div>
            <div v-if="responsavelPedagogico">
              <h5>Responsável Pedagógico</h5>
              <h3 class="color-text nome">{{ responsavelPedagogico.Basicos.nome.toLowerCase() }}</h3>
              <ul class="respInfo">
                <li>
                  <b>CPF:</b> {{ responsavelPedagogico.Documento.cpf }}
                </li>
                <li>
                  <b>RG:</b> {{ responsavelPedagogico.Documento.rg }}
                </li>
                <li>
                  <b>Email:</b> {{ responsavelPedagogico.Basicos.email }}
                </li>
                <li>
                  <b>Cel:</b> {{ responsavelPedagogico.Basicos.telefonecelular }}
                </li>
                <li>
                  <b>End:</b> {{ responsavelPedagogico.Endereco.logradouro }}, {{ responsavelPedagogico.Endereco.numero }}
                              {{ responsavelPedagogico.Endereco.complemento ? ' - ' + responsavelPedagogico.Endereco.complemento : '' }}
                              - {{ responsavelPedagogico.Endereco.bairro }} - {{ responsavelPedagogico.Endereco.cep }} 
                              - {{ responsavelPedagogico.Endereco.cidade }}/{{ responsavelPedagogico.Endereco.uf }}
                </li>
              </ul>
            </div>
          </div>
          <div class="details">

          </div>
          <footer class="frame fixed mini">
            <a href="#" v-if="debitos" @click.prevent="renegociar()" class="btn btn-warning btn-super btn-loose">
              <span>Aluno com pendências financeiras. Negociar.</span>
              <fa :icon="['far','arrow-right-long']" />
            </a>
            <a href="#" v-else-if="bloqueioPedagogico" @click.prevent="" class="btn btn-warning btn-super btn-loose">
              <span v-if="gestao">Aluno com bloqueio pedagógico.</span>
              <span v-else>Procure a sua unidade.</span>
              <fa :icon="['far','xmark']" />
            </a>
            <template v-else>
              <a href="#" @click.prevent="goToMatricula(true)" class="btn btn-secondary btn-super btn-loose color-primary">
                <span>Quero ir direto para o plano de pagamento</span>
                <fa :icon="['far','arrow-right-long']" />
              </a>
              <a href="#" class="btn btn-bg btn-large btn-fixed text-left" @click.prevent="goToMatricula()">
                Quero revisar as informações &nbsp;
                <fa :icon="['fas','user-pen']" />
              </a>
            </template>
          </footer>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import MatriculaService from '@/services/matricula.service'

export default {
  name: "Matricula Rápida",
  props: {
    idMatricula: {
      type: [String, Number]
    },
    gestao: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: false,
      loading: false,
      aluno: null,
      matricula: null,
      responsavelFinanceiro: null,
      responsavelPedagogico: null,
      fichaSaida: null,
      bloqueioPedagogico: false,
      wait: {
        responsavelFinanceiro: false,
        responsavelPedagogico: false,
        fichaSaida: false,
        aluno: false
      },
      debitos: false,
      respFinErr: false,
      finErr: [],
      respPedErr: false,
      pedErr: [],
      fichaSaidaErr: false,
      errLib: {
          email: "E-mail",
          cpf: "CPF",
          telefonecelular: "Celular",
          cep: "CEP",
          logradouro: "Endereço",
          numero: "Número"
      },
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    this.loading = true
    // console.log("chegou aqui...", this.gestao)
    this.getAluno()
    this.getFichaSaida()

  },  
  methods: {
    validate () {
      MatriculaService.validateMatInfo( this.idMatricula ).then(
        response => {
          console.log("response:", response)

          if ( response.data.debitos.length > 0 ) {
            const debitos = response.data.debitos.filter( k => k.descricao == 'Bolsistas' || k.descricao == 'Financeiro' )
            const bloqueioPedagogico = response.data.debitos.filter( k => k.descricao == 'Pedagógico' || k.descricao == 'Passos Mágicos' )

            if ( debitos.length > 0 ) this.debitos = true
            if ( bloqueioPedagogico.length > 0 ) this.bloqueioPedagogico = true
          }

          const fin = response.data.pessoaFinanceiro
          const ped = response.data.pessoaPedagogico

          this.respFinErr = Object.values(fin).some(a => a != "OK")
          this.respPedErr = Object.values(ped).some(a => a != "OK")

          if ( this.respFinErr ) {
            for (const k in fin) {
              if ( fin[k] != "OK" ) {
                this.finErr.push(k)
              }
            }
          }

          if ( this.respPedErr ) {
            for (const k in ped) {
              if ( ped[k] != "OK" ) {
                this.pedErr.push(k)
              }
            }
          }
          
          console.log("this.debitos", this.debitos)

          if ( !this.respFinErr && !this.respPedErr && !this.fichaSaidaErr && !this.debitos ) {
            this.getRespFin()
            this.getRespPed()
          } else {
            this.loading = false
          }

        }
      )
    },
    getAluno () {
      MatriculaService.getAlunoByIdMatricula( this.idMatricula ).then(
        response => {
          console.log("getAlunoByIdMatricula:", response.data)
          this.aluno = response.data.aluno
          this.matricula = response.data.matricula

          this.wait.aluno = true
          this.finishLoad()
        },
        error => {
          console.error(error.response.data.message)
        }
      )
    },
    getRespFin () {
      MatriculaService.getRespFin( this.idMatricula ).then(
        response => {
          console.log("getRespFin:", response.data)
          if ( response.data && response.data.pessoa ) {
            this.responsavelFinanceiro = response.data.pessoa
          }

          this.wait.responsavelFinanceiro = true
          this.finishLoad(true)
        },
        error => {
          console.error(error)
        }
      )
    },
    getRespPed () {
      MatriculaService.getRespPed( this.idMatricula ).then(
        response => {
          console.log("getRespPed:", response.data)
          if ( response.data && response.data.pessoa ) {
            this.responsavelPedagogico = response.data.pessoa
          }
          this.wait.responsavelPedagogico = true
          this.finishLoad(true)
        },
        error => {
          console.error(error)
        }
      )
    },
    getFichaSaida ( ) {
      MatriculaService.getFichaSaida( this.idMatricula ).then(
        response => {
          console.log("getFichaSaida:", response.data)
          if ( Object.keys(response.data).length > 0 ) {
            this.fichaSaida = response.data
          } else {
            this.fichaSaidaErr = true
          }
          this.wait.fichaSaida = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    finishLoad ( dontValidate ) {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( this.wait.aluno && this.wait.fichaSaida && !dontValidate) {
        console.log("validate...")
        this.validate()
      }

      if ( finished ) {
        window.spinner.emit("close")

        setTimeout(() => {
          this.loading = false
        },1000)
      }
      // console.log("finished:", finished)
    },
    goToMatricula ( fast ) {
      const route = this.$router.currentRoute._value.name
      const step = fast ? 'planos-pagamento' : 'unidade-curso'
      
      const currentRoute = this.$router.currentRoute._value
      const query = currentRoute.query

      if ( query ) {
        this.$router.push({ name: route, params: { action: 'nova', step: step, idMatricula: this.idMatricula }, query: query})
      } else {
        this.$router.push({ name: route, params: { action: 'nova', step: step, idMatricula: this.idMatricula }})
      }
      
      
    },
    renegociar () {
      window.open('https://renegociacao.app.rededecisao.com/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjY4ODAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODAwMC9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTU2MzQ3NzY4NywiZXhwIjoxNTYzNDgxMjg3LCJuYmYiOjE1NjM0Nzc2ODcsImp0aSI6ImRtRjZsYUMwWUswVTdzSVIifQ.ynkU2CQ2Kr3yt-cxA3o21y2wkg2HRj-zckdBYDy2MNE&email=' + this.currentUser.email)
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    padding-bottom: $mg_extra; 
  }
  header {
    padding: $hmg;

    >*{
      margin: 0;
    }
  }

  .details { 
    padding: $hmg;
    margin-bottom: $hmg;

    .nome {
      text-transform: capitalize;
    }

    &.multiple {
      padding: 0;
      display: flex;
      align-items: flex-start;

      > div {
        padding: $hmg;
        margin-right: $mg;

        h5 { margin: 0 }
      }

      @media screen and (max-width: 1024px ) {
        display: block;
      }
    }

    .respInfo {
      padding: 0; margin: 0;

      li { 
        list-style: none;
        padding: 0;
      }
    }
  }

  .matBox {
    padding: 0 $hmg_mid;
    margin-bottom: $hmg;

    >div {
      width: 100%; max-width: 625px;
      background-color: $color-secondary;
      border-radius: $border-radius;
      display: grid; 
      grid-template-columns: 1fr .6fr;
      grid-template-rows: 1fr 1fr;

      h5,p {margin: 0}
      
      >* {
        padding: $hmg_small;
        display: flex; align-items: center; align-content: center;

        &:nth-child(1), &:nth-child(3) {
          border-right: 1px solid $color-bg;
        }
        &:nth-child(1), &:nth-child(2) {
          border-bottom: 1px solid $color-bg
        }

        .icon {
          margin-right: $hmg_small;
          font-size: 30px;
          color: $color-primary
        }
      }

      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        >* {
          border-right: 0;
          border-bottom: 1px solid $color-bg;
          
          &:last-child{
            border-bottom: 0;
          }
        }
      }
    }
    
  }

  .validating {
    position: absolute; top: 50%;
    left: 0; right: 0; 
    transform: translateY(-50%);
    .icon {
      font-size: 84px;
      margin-bottom: $hmg;
    }

    .iconWrap {
      animation-name: bounce;
      animation-duration: 1.2s;
      animation-delay: .6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
  }

  @keyframes bounce {
    0%, 75%, 90%, 100%  { transform: scale(1) translateY(0) }
    10%  { transform: scale(1.1,0.9) translateY(0) }
    50%  { transform: scale(0.9,1.1) translateY(-25%) }
    // 75%  { transform: scale(1) translateY(0)  }
    85%  { transform: scale(1.05,0.95) translateY(0) }
    // 100% { transform: scale(1) translateY(0)  }
  }

  .erros {
    .btn {
      margin-top: $hmg
    }

    .details {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .errListWrap {
    margin-top: $hmg;

    >div {
      margin-top: $hmg_small
    }

    .errList {
      display: flex; flex-wrap: wrap;
      align-items: flex-start; align-content: flex-start;
      padding: 0; margin: $mg_mini 0 0 0;

      >* {
        list-style: none;
        padding: $hmg_mini $mg_mini;
        border-radius: $border-radius;
        background-color: $color-secondary;
        margin: $hmg_mini $mg_mini $hmg_mini 0;
      }
    }
  }
  
  footer {
    padding: 0 $hmg;
    .btn {
      &:last-child {
        margin-top: $hmg
      }
      &.btn-primary,
      &.btn-warning {
        &:hover {
          color: $color-secondary
        }
      }
    }
  }
</style>