<template>
  <section>
    <h3>
      <fa :icon="['fal','chart-user']" class="icon"></fa>
      <span>Favoritos</span>
    </h3>
    <div class="listWrap">
      <card :listed="false">
        <header>
          <h6>Favoritos Pessoais</h6>
          <a href="#" @click.prevent="toggleAddFavorito(true)">
            Novo
          </a>
        </header>
        <ul v-if="favorites">
          <template v-if="favorites.pessoaFavoritos.length > 0">
            <li v-for="pf in favorites.pessoaFavoritos" :key="pf" >
              <a :href="pf.urlFavoritos" target="_blank">
                {{ pf.descricaoFavoritos }}
              </a>
              <a href="#" @click.prevent="toggleRemoveFav(pf)" >
                <fa :icon="['far', 'trash-can']" />
              </a>
            </li>
          </template>
          <template v-else>
            <li class="addNew">
              <span>Você ainda não tem favoritos.</span>
              <a href="#" @click.prevent="toggleAddFavorito(true)"><b>Adicionar novo</b></a>
            </li>
          </template>
        </ul>
        <header>
          <h6>Favoritos da Função</h6>
        </header>
        <ul v-if="favorites">
          <li v-for="ff in favorites.funcaoFavoritos" :key="ff">
            <!-- <a :href="ff.router_link" :target="'_' + ff.tipo_url">
              {{ ff.name }}
            </a> -->
            <a :href="ff.router_link" @click="saveRotaFavorita(ff)" v-if="ff.router_link && !ff.numeroDashboard" :target="ff.tipo_url">
              {{ ff.name }}
            </a>
            <a :href="ff.path" @click="saveRotaFavorita(ff)" v-else-if="ff.path" :target="ff.tipo_url">
              {{ ff.roleName }}
            </a>
            <a href="#" @click.prevent="goToRoute(ff); saveRotaFavorita(ff)" v-else-if="ff.numeroDashboard">
              {{ ff.name }}
            </a>
          </li>
        </ul>
      </card>
      <modal ref="modal">
        <add-favorito :pessoa="currentUser" @onSave="closeAddFavoritos()"></add-favorito>
      </modal>
      <confirm 
        :backdrop="false"
        class="confirm"
        ref="confirm"
      ></confirm>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AddFavorito from '@/components/utilities/favorites/AddFavorito.vue'
import Modal from '@/components/utilities/Modal'

export default {
  components: {
    Modal, AddFavorito
  },
  data () {
    return {
      favorites: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    this.get()
  },
  methods: {
    get() {
      const idPessoa = this.currentUser.idPessoa
      
      UserService.getFavoritos( idPessoa ).then(
        response => {
          console.log("favorites", response.data)
          this.favorites = response.data
        }
      )
    },
    toggleAddFavorito ( state ) {
      if ( state ) {
        const options = {
          title: 'Adicionar novo favorito pessoal',
          titleAlign: "center",
          // message: "Se quiser uma mensagem...", //não obrigatório
          size: "small", //small, mid, large, full
          backdrop: true,
          backdropColor: "transparent",  //se o backdrop for false, não precisa
          backdropClose: true, //se o backdrop for false, não precisa
          fullHeight: false
        }
        
        this.$refs.modal.open(options)
      } else {
        //
      }
    },
    closeAddFavoritos () {
      this.$refs.modal.close()
      this.get()
    },
    toggleRemoveFav ( fav ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este favorito?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: fav,
        callback: this.removeFav
      })
    },
    removeFav ( response, fav ) {
      if ( response ) {
        console.log ("removeFav:", fav)
        const id = fav.idFavoritosApollo

        UserService.removeFavorito( id ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Favorito removido com sucesso.", floater: true})
              this.get()
            }
          },
          () => {
            window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu! Não foi possível remover o favorito no momento ", floater: true})
          }
        )
      }
    },
    saveRotaFavorita ( route ) {
      const idPessoa = this.currentUser.idPessoa
      const data = {
        idPessoa: idPessoa,
        idRole: null,
        idPermission: route.id
      }

      UserService.saveRotaFavorita(data).then(
        response => {
          console.log("saveRotaFavorita:", response.data)
        }
      )
    },
    goToRoute ( item ) {
      // console.log("goTo:", item)
      //externo
      if ( item.router_link && item.tipo_url == 'blank' || item.externo == 1 && !item.numeroDashboard ) {
        window.open(item.router_link)
      } else if ( item.externo == 1 && item.numeroDashboard ) {
        // const query = this.getQueryStringParams(item.router_link)
        this.$router.push({name: 'Painel', params: { id: item.numeroDashboard }, query: {path: item.router_link} })
      } else {
        this.$router.push(item.router_link)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    display: flex; align-content: center;
    font-size: $font-size;
    gap: $hmg_mini;
    color: $color-primary;
    padding-left: $mg_mini;
    .icon {
      font-size: 16px;
    }
  }

  .listWrap {
    margin-top: $mg_mini;

    header {
      display: flex;
      align-content: center; align-items: center; justify-content: space-between;
      padding: $mg_mini; margin: 0;
      border-bottom: $border-component;

      &:last-of-type {
        border-top: $border-component-double
      }
      
      h6 {
        padding: 0; margin: 0;
        color: $color-primary
      }
      a {
        background-color: $color-bg;
        border-radius: 2em;
        font-size: $font-size-mini; font-weight: bold;
        text-transform: uppercase;
        padding: 3px $mg_mini;
        text-align: center;

        &:hover {
          background-color: $color-primary;
          color: $color-secondary
        }
      }
    }

    
    ul {
      padding: 0; margin: 0;

      li {
        display: flex; align-items: center;
        justify-content: space-between;
        list-style: none;
        border-top: $border-component;
        padding: $mg_mini;

        &:first-child {
          border-top: 0 !important;
        }

        a {
          &:hover {
            color: $color-primary;
          }
        }
        &:hover {
          background-color: $color-bg;
        }

        &.addNew {
          padding: $mg_mini;
          background-color: $color-bg;
          border-bottom: $border-component;

          a {
            padding: 0;
            
            &:hover{
              background-color: transparent;
            }
          }
        }
      }
    }
  }
</style>