<template>
  <section class="over-page">
    <main class="frame">
      <card :listed="false" :boxed="false">
        <div class="form-wrap">
          <header>
            <h4>Editar Categoria de Atividade</h4>
            <p v-if="scope && scope.nome">{{ scope.nome }}</p>
          </header>
          <form ref="categoriaForm"  v-if="ready">
            <div class="row nop nom">
              <div class="form-group col-md-6">
                <select-control
                  v-model="categoria.idIndicador"
                  :options="indicadores"
                  name="idIndicador"
                  label="Indicador"
                  placeholder="Selecione"
                  required
                ></select-control>
              </div>
              <div class="form-group col-md-6">
                <select-control
                  v-model="categoria.idAtendimentoCategoria"
                  :options="categoriasAtendiemnto"
                  name="idAtendimentoCategoria"
                  label="Categoria"
                  placeholder="Selecione"
                  required
                ></select-control>
              </div>
              <div class="form-group col-md-6">
                <select-control
                  v-model="categoria.idClassificacaoAtividade"
                  :options="classificacaoAtividade"
                  name="idClassificacaoAtividade"
                  label="Classificação"
                  placeholder="Selecione"
                  required
                ></select-control>
              </div>
              <div class="form-group col-md-6">
                <select-control
                  v-model="categoria.idFormulario"
                  :options="formularios"
                  name="idFormulario"
                  label="Rubrica"
                  placeholder="Selecione"
                  :required="categoria.kanban == 1"
                ></select-control>
              </div>
            </div>
            <div class="row nop nom">
              <div class="form-group col-md-6">
                <input-control
                  label="Descrição Categoria Atividade"
                  v-model="categoria.descricaoCategoriaAtividade"
                  required
                ></input-control>
              </div>
              <div class="form-group col-md-6">
                <select-control
                  v-model="categoria.idPerfilPessoa"
                  :options="tiposPessoa"
                  name="idFormulario"
                  label="Tipo de Pessoa"
                  placeholder="Selecione"
                  required
                ></select-control>
              </div>
            </div>
            <div class="row nop nom">
              <div class="form-group col-md-6">
                <input-control
                  label="SLA (Em horas)"
                  v-model="categoria.horasSLA"
                  required
                ></input-control>
              </div>
              <div class="form-group col-md-6">
                <input-control
                  label="Expiração (Em horas)"
                  v-model="categoria.horasExpiracao"
                  required
                ></input-control>
              </div>
              <div class="col-md-3 form-group">
                <label>Tipo</label>
                <div class="checklists">
                   <label class="check-control">
                    <input type="radio" class="check" name="tipoItem" v-model="tipo.kanban" :checked="categoria.kanban == 1" @change="setTipo('kanban')"/>
                    Atividade
                   </label>
                   <label class="check-control">
                    <input type="radio" class="check" name="tipoItem" v-model="tipo.checklist" :checked="categoria.checklist == 1" @change="setTipo('checklist')"/>
                    Tarefa
                   </label>
                </div>
              </div>
              <div class="col-md-2 form-group">
                <label>Notificação Hangouts</label>
                <div class="switchWrapMini">
                  <switch-control v-model:checked="categoria.disparaHangouts" labelOn="Notifica" labelOff="Não Notifica" invert></switch-control>
                </div>
              </div>
              <div class="col-md-2 form-group">
                <label>Envia NPS</label>
                <div class="switchWrapMini">
                  <switch-control v-model:checked="categoria.enviaNps" labelOn="Envia" labelOff="Não Envia" invert></switch-control>
                </div>
              </div>
            </div>
            <div class="row no-padd">
              <div class="form-group col-md-12">
                <textarea-control
                  label="Instruções"
                  placeholder="Insira aqui Instruções para quem vai executar este atendimento"
                  v-model="categoria.instrucoes"
                  resize
                ></textarea-control>
              </div>
              <div class="col-md-12 form-group">
                <label>Funções</label>
                <div class="funcoesWrap">
                  <div class="searchWrap">
                    <div>
                      <input-control v-model="query" placeholder="Buscar..." @keyup="search" search preventSearchButton/>
                      <a href="#" @click.prevent="clearSearch()">
                        <fa :icon="['fal','trash-xmark']" class="icon"></fa>
                      </a>
                    </div>
                  </div>
                  <div class="funcoesList">
                    <div>
                      <table class="transparent-table funcoes">
                        <thead>
                          <tr>
                            <th class="fullCell">Função</th>
                            <th>Ver</th>
                            <!-- <th>Editar</th> -->
                            <th>Executar</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in funcoes" :key="item">
                            <td class="fullCell">
                              {{ item.nome }}
                            </td>
                            <td>
                              <label class="check-control">
                                <input type="checkbox" class="check invert" v-model="item.view" :checked="item.view"/>
                              </label>
                            </td>
                            <!-- <td>
                              <label class="check-control">
                                <input type="checkbox" class="check invert" v-model="item.edit" :checked="item.edit" />
                              </label>
                            </td> -->
                            <td>
                              <label class="check-control">
                                <input type="checkbox" class="check invert" v-model="item.execute" :checked="item.execute" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form-spinner v-else></form-spinner>
          <footer>
            <div>
              <a href="#" class="btn btn-secondary" @click.prevent="cancel()">Cancelar</a>
            </div>
            <div>
              <a
                href="#"
                :class="['btn btn-primary', { disabled: running }]"
                @click.prevent="submit()"
                >{{ this.scope ? "Editar" : "Criar" }}</a
              >
            </div>
          </footer>
        </div>
      </card>
    </main>
  </section>
</template>

<script>
import FormulariosService from "@/services/formularios.service.js"
import Ferramentas from "@/services/ferramentas.service.js"
import { createSelectOptions } from "@/services/utils.service.js"
import FormService from '@/services/form.service.js'
import AtendimentoService from '@/services/atendimento.service.js'
import AtividadesService from '@/services/atividades.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  props: {
    scope: {
      type: Object,
    },
    editar: {
      type: Boolean
    },
    novo: {
      type: Boolean
    }
  },
  watch: {
    scope(value) {
      if (value) {
        this.categoria = value
      }
    },
  },
  data() {
    return {
      ready: false,
      waiting: true,
      running: false,
      options: {
        categorias: null,
        canais: null,
      },
      categoria: {
        idAtividadeCategoria: null,
        descricaoCategoriaAtividade: null,
        idFormulario: null,
        horasSLA: null,
        horasExpiracao: null,
        kanban: true,
        checklist: false,
        disparaHangouts: 0,
        instrucoes: null,
        idIndicador: null,
        idAtendimentoCategoria: null,
        enviaNps: 0
      },
      tiposPessoa: [
        { value: 1, label: "Aluno" },
        { value: 2, label: "Colaborador" },
        { value: 3, label: "Responsável" },
      ],
      formularios: [],
      indicadores: [],
      funcoes: [],
      funcoesBkp: [],
      funcoesSelecionadas: [],
      categoriasAtendiemnto: [],
      classificacaoAtividade: [],
      valid: {
        dataOcorrencia: true,
        idAtividadeCategoria: true,
        idAtendimentoCanal: true,
        idPessoa: true,
      },
      wait: {
        formularios: false,
        categoriasAtendiemnto: false,
        indicadores: false,
        funcoes: false,
        funcoesCategorias: false,
        classificacoesAtividade: false
      },
      pessoa: {
        query: null,
        loading: false,
        list: [],
        type: null,
      },
      operador: {
        query: null,
        loading: false,
        list: [],
      },
      category: null,
      waitSearch: null,
      query: null,
      tipo: {
        kanban: true,
        checklist: false
      }
    }
  },
  mounted() {
    this.getFormularios()
    this.getIndicadores()
    this.getCategoriasAtendimentos()
    this.getFuncoes()
    this.getClassificacaoAtividade()

    if ( this.editar ) {
      this.getFuncoesCategoriaAtividade()
    } else if ( this.novo ) {
      this.wait.funcoesCategorias = true
    }
    if( this.scope) {
      this.categoria = this.scope

      const tipo = this.categoria.kanban ? 'kanban' : 'checklist'
      this.setTipo(tipo)
    }
  },
  methods: {
    getFormularios() {
      FormulariosService.getFormularios(1).then(
        response => {
          this.formularios = createSelectOptions(response.data, "idFormulario", "descricao")
          
          this.wait.formularios = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getCategoriasAtendimentos () {
      AtendimentoService.getCategoriasAtendimentos().then(
        response => {
          // console.log("getCategoriasAtendimentos:", response.data)
          if ( response.data.length > 0 ) {
            this.categoriasAtendiemnto = createSelectOptions( response.data, "idAtendimentoCategoria", "nome")
          }

          this.wait.categoriasAtendiemnto = true
          this.finishLoad()
        },
        () => {
          
        }
      )
    },
    getIndicadores() {
      Ferramentas.getIndicadores().then(
        response => {
          this.indicadores = createSelectOptions(response.data, "idIndicador", "descricaoIndicador")

          this.wait.indicadores = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getClassificacaoAtividade() {
      AtividadesService.getClassificacaoAtividade().then(
        response => {
          this.classificacaoAtividade = createSelectOptions(response.data, "idClassificacaoAtividade", "descricaoClassificacaoAtividade")

          this.wait.classificacoesAtividade = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getFuncoes() {
      AtividadesService.getFuncoes().then(
        response => {
          console.log("")
          this.funcoes = response.data

          this.wait.funcoes = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getFuncoesCategoriaAtividade () {
      AtividadesService.getFuncoesCategoriaAtividade( this.scope.idAtividadeCategoria ).then(
        response => {
          this.funcoesSelecionadas = response.data
          this.wait.funcoesCategorias = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    processFuncoes () {
      this.funcoes.map( k => {
        const funcao = k.idFuncao
        const match = this.funcoesSelecionadas.filter( a => a.idFuncao == funcao)

        if ( match.length > 0 ) {
          k['view'] = match[0].view == 1
          k['edit'] = match[0].edit == 1
          k['execute'] = match[0].execute == 1
        }
      })

      this.funcoesBkp = Object.assign([], JSON.parse(JSON.stringify(this.funcoes)))
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.ready = true
        this.processFuncoes()
      }
    },
    setTipo ( tipo ) {
      if ( tipo == 'kanban' ) {
        this.categoria.kanban = 1
        this.categoria.checklist = 0
      }
      if ( tipo == 'checklist' ) {
        this.categoria.kanban = 0
        this.categoria.checklist = 1
      }
    },
    cancel () {
      this.$emit('onCancel')
    },
    submit () {
      this.clearSearch()
      this.categoria.funcoes = this.funcoes.filter( a => a.view || a.edit || a.execute )
      // this.categorias.funcoes = 
      // console.log("this.categorias.funcoes:", this.categoria.funcoes)
      const valid = FormService.validate(this.$refs.categoriaForm, this.valid)

      if ( valid.response ) {
        this.running = true
        AtendimentoService.saveCategoriaAtividade( this.categoria ).then(
          response => {
            if ( response.status == 201 || response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Categoria de atividade criada com sucesso.", floater: true})

              this.$emit('onSave')
            }
          },
          () => {
            this.running = false
            window.toaster.emit('open', {style: "error", message: "Não foi possível salvar este atendimento. Valide os dados e tente novamente. Se o problema persistir, procure nosso time de suporte.", floater: true})
          }
        )
        
      } else {
        this.running = false
            window.toaster.emit('open', {style: "error", message: "Todos os campos requeridos do formulário devem estar preenchidos.", floater: true})
      }
    },
    search () {
      if ( this.waitSearch ) clearTimeout(this.waitSearch)

      if ( this.query && this.query.length >= 1 ) {
        this.waitSearch = setTimeout(() => {
          this.funcoes = this.funcoesBkp.filter( a => {
            const nome = a.nome.toLowerCase()
            const existNome = nome.includes(this.query)

            return existNome
          })
        },200)
      }
    },
    clearSearch () {
      this.query = null
      this.funcoes = Object.assign([], JSON.parse(JSON.stringify(this.funcoesBkp)))
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  padding: $mg
}
.form-wrap {
  header {
    padding: $hmg_mid;
    border-bottom: $border-component;
  }
  form {
    padding: $hmg_mid $mg_mini
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $hmg_mid;
    border-top: $border-component;

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: $hmg_mid;
    }

    > div {
      display: flex;
      align-content: flex-end;
      gap: $hmg_mid;

      @media screen and (max-width: 1024px) {
        gap: $mg_mini;
        justify-content: space-between;
      }
    }
  }
}

.funcoes {
  td:not(.fullCell ), th:not(.fullCell) {
    width: 5%;
    max-width: 100px; min-width: 80px;
    text-align: center
  }
}
.fullCell {
  width: 85%;
  text-align: left
}

.funcoesWrap {
  display: flex; flex-direction: column;
  border-radius: $border-radius;
  border: $border-component;
  overflow: hidden;
  
  .searchWrap{
    padding: $hmg_mid;
    border-bottom: $border-component;

    >div { 
      display: flex; align-items: center;
      gap: $mg_mini;
      max-width: 340px;

      a { 
        font-size: 16px;

        &:hover {
          color: $color-primary
        }
      }
    }
  }

  .funcoesList {
    height: 70vh; max-height: 640px;
    padding: $hmg_mid $hmg_mid $hmg_mid $mg_mini;

    thead {
      position: sticky; top: 0;
      background-color: $color-secondary;
      z-index: 100;
    }
    
    >div {
      height: 100%;
      padding-right: $hmg_mid;
      overflow-y: auto;
      @extend %scrollbar_card
    }
    
  }  
}

.checklists {
  display: flex; gap: $hmg_mid
}
</style>
