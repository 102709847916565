import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class FormService {
  validate ( form , valid ) {
    console.log("form:", form)
    console.log("valid:", valid)

    var required = form.querySelectorAll(':required')

    const notValids = []
    const radioChecked = []
    console.log("required:", required)
    
    
    required.forEach (k => {
      // console.log("k:", k)
      const value = k.value
      const nome = k.name
      const type = k.type

      // console.log("value:", value)
      // console.log("nome:", nome)
      // console.log("type:", type)
      if ( type == 'radio' ) {
        const checked = k.checked
        console.log("checked:", checked)

        if ( !checked ) {
          radioChecked.push(k)

          if ( radioChecked.length == 3 ) {
            valid[nome] = false
            notValids.push(k)
          }
        }
        // console.log("checked:", k.checked)
        // if ( !value ) {
        //   notValids.push(k)
        //   this.valid[nome] = false
        //   // window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
        //   // k.className += ' error'; 
        // }
      } else {
        if ( !value ) {
          notValids.push(k)
          valid[nome] = false
          // window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
          // k.className += ' error'; 
        }
      }
    })

    // console.log('notValids:', notValids)
    // console.log('notValids:', notValids)
    const response = {
      response: notValids.length == 0,
      valids: valid
    }
    return response
    // return false
  }
  
  getNPS ( campanha ) {
    return axios.get(process.env.VUE_APP_API_URL + 'get/campanha/nps/' + campanha, { headers: authHeader() })
  }
  saveNPS ( idCampanha, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'post/nps/resposta/' + idCampanha, data, { headers: authHeader() })
  }
}

export default new FormService()
