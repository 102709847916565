<template>
  <section>
    <header>
      
    </header>
    <div v-if="gatilhos.length > 0">
      <ol class="itemsList">
        <li>
          <div class="itemsListInner has-action">
            <span>Ativo</span>
            <span>Nome</span>
            <span>Financeiro</span>
            <span>Etapa</span>
            <span>Público</span>
            <span>Sinal</span>
            <span>Regra</span>
            <span>Valor</span>
            <span>Plataforma</span>
            <span>Teste</span>
            <span></span>
          </div>
        </li>
        <li v-for="(gatilho, index) in gatilhos" :key="gatilho">
          <card spacing="small">
            <div class="itemsListInner has-action">
              <span class="toggleWrap">
                <switch-control v-model:checked="gatilho.ativo" @change="toggleGatilhoAtivo($event, gatilho)" :disabled="gatilho.loading"></switch-control>
              </span>
              <span>
                {{ gatilho.nome }}
              </span>
              <span>
                {{ gatilho.analiseFinanceira ? 'Sim' : 'Não' }}
              </span>
              <span>
                {{ gatilho.etapa ? gatilho.etapa : '-' }}
              </span>
              <span>
                {{ gatilho.publico }}
              </span>
              <span>
                {{ gatilho.analise_integrada }}
              </span>
              <span>
                {{ gatilho.regra }}
              </span>
              <span>
                {{ gatilho.metrica }}
              </span>
              <span>
                {{ gatilho.plataformaDisparo }}
              </span>
              <span>
                {{ gatilho.teste ? 'Sim' : 'Não' }}
              </span>
              <span class="itemsListActions">
                <input-spinner v-if="gatilho.loading"></input-spinner>
                <template v-else>
                  <a href="#" @click.prevent="confirmCreateIntervencoes(gatilho)" class="btn btn-primary btn-small">
                    Criar Intervenções
                  </a>
                  <a href="#" @click.prevent="getIntervencoes(gatilho)" title="Sincronizar Intervenções">
                    <fa :icon="['far','arrows-rotate']"></fa>
                  </a>
                  <a href="#" @click.prevent="toggleEdit(gatilho)" title="Editar Intervenção">
                    <fa :icon="['far','pencil']"></fa>
                  </a>
                  <a href="#" @click.prevent="confirmRemove(gatilho)" title="Remover Intervenção">
                    <fa :icon="['far','trash-can']"></fa>
                  </a>
                </template>
                
              </span>
            </div>
            <div v-if="gatilho.intervencoes">
              <section class="intList">
                <header>
                  <div>
                    <h5>
                      {{ gatilho.intervencoes.length + ( gatilho.intervencoes.length == 1 ? ' intervenção programada' : ' intervenções programadas' ) }}
                    </h5>
                    <a href="#" @click.prevent="confirmRunIntervencoes( gatilho, index )" class="btn btn-primary btn-small" v-if="gatilho.intervencoes.length > 0">
                      Rodar todas as intervenções &nbsp;
                      <fa :icon="['fas','bullseye-pointer']"></fa>
                    </a>
                    <!-- <span v-if="custoAprox(gatilho) > 0">
                      <label>Custo Aproximado</label>
                      <h4 class="color-text">{{ formatMoney(custoAprox(gatilho)) }}</h4>
                    </span> -->
                  </div>
                  <a href="#" @click.prevent="closeList(index)" class="btn btn-secondary btn-small">Fechar</a>
                </header>
                <table class="content-table bordered-list" v-if="gatilho.intervencoes.length > 0">
                  <thead>
                    <tr>
                      <th>Matrícula</th>
                      <th>Aluno</th>
                      <th>Responsável</th>
                      <th>Período Letivo</th>
                      <th>Unidade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="intervencao in gatilho.intervencoes" :key="intervencao">
                      <td>{{ intervencao.idMatricula }}</td>
                      <td>{{ intervencao.nomeAluno }}</td>
                      <td>{{ intervencao.nomeResponsavel }}</td>
                      <td>{{ intervencao.periodoLetivo }}</td>
                      <td>{{ intervencao.unidade }}</td>
                      <td class="actions">
                        <input-spinner v-if="intervencao.loading"></input-spinner>
                        <b v-else-if="intervencao.message" class="message"><p>{{ intervencao.message }}</p></b>
                        <a v-else href="#" @click.prevent="confirmCallIntervencao(intervencao)" title="Rodar Intervenção" class="table-action">
                          <fa :icon="['fas','bullseye-pointer']" class="icon"></fa>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </card>
        </li>
      </ol>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service'
import InputSpinner from '@/components/utilities/InputSpinner'
import { formatMoney } from '@/services/utils.service'

export default {
  components: {
    InputSpinner
  },
  data () {
    return {
      gatilhos: [],
      loading: false,
      valores: {
        Blip: .35,
        SMS: .08,
        Email: .09
      }
    }
  },
  mounted () {
    this.getGatilhos()
  },
  methods: {
    getGatilhos () {
      if ( !this.loading ) {
        this.loading = true
        window.spinner.emit("open")
        FerramentasService.getGatilhos().then(
          response => {
            console.log("getGatilhos:", response)
            if ( response.data.length > 0 ) {
              response.data.map( k => {
                const ativo = k.ativo
                k.ativo = ativo == 1
                k.loading = false
                k['idAtividadeCategoria'] = k.categoriaAtendimento
              })
              
              this.gatilhos = response.data
            } else {
              window.toaster.emit('open', {style: "warning", message: "Não encontramos gatilhos.", floater: true})
            }
            
            window.spinner.emit("close")
            this.loading = false
          },
          error => {
            console.log("error:", error)
            this.loading = false
            window.spinner.emit("close")
            const message = error.response.data.error ? error.response.data.error : 'Desconhecido'

            window.toaster.emit('open', {
              style: "warning", 
              message: "Ops. Algo aconteceu. Não conseguimos encontrar gatilhos no momento. Tente novamente, se o erro persistir, procure o suporte. Err:" + message, 
              floater: true
            })
          }
        )
      }
      
    },
    toggleEdit ( gatilho ) {
      this.$emit('edit', gatilho)
      // console.log("index:", index)
    },
    confirmRemove ( gatilho ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este item?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: gatilho,
        callback: this.remove,
      })
    },
    remove ( response, gatilho ) {
      if ( response ) {
        window.spinner.emit("open")
        this.$emit('remove', gatilho)
      }
    },
    toggleGatilhoAtivo ( e, gatilho ) {
      // gatilho.loading = true
      gatilho.loading = true
      this.$emit('activate', gatilho)
    },
    confirmCreateIntervencoes ( gatilho ) {
      this.$refs.confirm.run({
        message: "Tem certeza que criar as intervenções deste gatilho?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: gatilho,
        callback: this.createIntervencoes,
      })
    },
    createIntervencoes ( response, gatilho ) {
      if ( response ) {
        gatilho.loading = true
        FerramentasService.createIntervencoes( gatilho ).then(
          response => {
            if ( response.status == 200 ) {
              this.getIntervencoes(gatilho)
            }
            console.log("response:", response)
            gatilho.loading = false
          },
          error => {
            gatilho.loading = false
            const message = error.response.data.error ? error.response.data.error : 'Desconhecido'

            window.toaster.emit('open', {
              style: "warning", 
              message: "Ops. Algo aconteceu. Não conseguimos criar as intervenções no momento.Tente novamente, se o erro persistir, procure o suporte. Err:" + message, 
              floater: true
            })
          }
        )
      }
    },
    getIntervencoes ( gatilho ) {
      gatilho.loading = true
      FerramentasService.getIntervencoes( gatilho ).then(
        response => {
          if ( response.data.length > 0 ) {
            gatilho.intervencoes = response.data
          } else {
            window.toaster.emit('open', {
              style: "warning", 
              message: "Não encontramos intervenções para o gatilho selecionado", 
              floater: true
            })
          }
          console.log("getIntervencoes:", response)
          gatilho.loading = false
        },
        error => {
          const message = error.response.data.error ? error.response.data.error : 'Desconhecido'
          gatilho.loading = false

          window.toaster.emit('open', {
            style: "warning", 
            message: "Ops. Algo aconteceu. Não conseguimos coletar as intervenções no momento.Tente novamente, se o erro persistir, procure o suporte. Err:" + message, 
            floater: true
          })
        }
      )
    },
    confirmCallIntervencao ( intervencao ) {
      this.$refs.confirm.run({
        message: "Tem certeza que rodar esta intervenção?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: intervencao,
        callback: this.callIntervencao,
      })
    },
    callIntervencao ( response, intervencao ) {
      if ( response ) {
        intervencao.loading = true
        FerramentasService.callIntervencao( intervencao ).then(
          response => {
            intervencao.message = response.data.mensagem
            intervencao.loading = false
            console.log("response:", response)
          },
          error => {
            intervencao.loading = false
            console.log("error:", error)
            const message = error.response.data.error ? error.response.data.error : 'Desconhecido'

            window.toaster.emit('open', {
              style: "warning", 
              message: "Ops. Algo aconteceu. Não conseguimos processar a intervenção no momento. Tente novamente, se o erro persistir, procure o suporte. Err:" + message, 
              floater: true
            })
          }
        )
      }
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    custoAprox ( gatilho ) {
      var cost = 0

      if ( this.valores[gatilho.plataformaDisparo] ) { 
        // console.log('intervencoes:', intervencoes)
        cost = this.valores[gatilho.plataformaDisparo] * gatilho.intervencoes.length
      }
      
      return cost
      
    },
    confirmRunIntervencoes ( gatilho, index ) {
      this.$refs.confirm.run({
        message: "Tem certeza que disparar as intervenções deste gatilho?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: { index, gatilho },
        callback: this.runIntervencoes,
      })
    },
    runIntervencoes ( response, data ) {
      if ( response ) {
        FerramentasService.runIntervencoes( data.gatilho ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {
                style: "success", 
                message: "Suas intervenções estão sendo disparadas em segundo plano. Você pode continuar trabalhando normalmente.",
                floater: true
              })
              setTimeout(() => {
                this.closeList(data.index)
              },1000)
            }
          },
          error => {
            this.loading = false
            window.spinner.emit("close")
            const message = error.response.data.error ? error.response.data.error : 'Desconhecido'

            window.toaster.emit('open', {
              style: "warning", 
              message: "Ops. Algo aconteceu. Não conseguimos processar as intervenções no momento. Tente novamente, se o erro persistir, procure o suporte. Err:" + message, 
              floater: true
            })
          }
        )
      }
      
    },
    closeList ( index ) {
      this.gatilhos[index].intervencoes = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .itemsListWrap {
      margin-top: $mg;

      h4 { 
        padding: 0 $hmg_mid;
      }
      > div {
        margin-top: $hmg_small
      }
    }

    .itemsList {
      display: block;
      width: 100%;
      padding: 0; margin: 0;

      li {
        list-style: none;
        width: 100%; 
        padding: 0; margin: 0;

        .itemsListInner {
          display: grid; width: 100%;
          padding: $mg_mini $hmg_small;
          align-items: center; align-content: center;
          grid-template-columns: 3fr 2fr 1fr;

          &.has-action {
            grid-template-columns: .3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
          }
          
          > span {
            display: flex; align-items: center;
            padding: 0 $hmg_mini;

            &.toggleWrap {
              padding: 0
            }

            &.actionWrap {
              justify-content: flex-end;
            }

            .syncIcon {
              display: flex; flex-direction: column;
              align-content: center; align-items: center; justify-content: center;
              width: 28px; height: 28px;
              background-color: $color-success-2;
              padding: 0; margin: 0;
              line-height: 1;
              color: $color-secondary;
              border-radius: 100%;
              text-align: center;
            }

            .fixed {
              white-space: nowrap;
            }

            a:not(.btn) {
              color: $color-primary;

              &:hover {
                color: $color-dev
              }
            }
          }

        }

        .itemsListActions {
          display: flex; align-items: center; align-content: center;
          justify-content: flex-end;

          a:not(.btn) {
            margin-left: $mg_mini;
            font-size: 16px;
          }
        }
        
        .itemToggle {}

        .plItemDetails {
          padding: $hmg;
        }

        .plTableFooter {
          padding-top: $hmg;
          display: flex;
          align-content: center; align-items: center;
          justify-content: flex-end;

          >.btn {
            margin-left: $mg_small;
          }
        }
      }
    }

    .intList {
      padding: $hmg;

      header {
        display: flex; align-content: center; align-items: center;
        justify-content: space-between;
        padding-left: $mg_mini;
        margin-bottom: $hmg;

        h5 {
          padding: 0; margin: 0;
        }

        > div {
          display: flex; align-content: center; align-items: center;

          a {
            margin-left: $hmg;
          }

          > span {
            padding: $mg_mini $mg_small;
            border-radius: $border-radius;
            background-color: $color-bg;
            position: relative;
            margin-left: $hmg_small;

            &:before {
              content: "";
              display: block; width: 15px; height: 15px;
              border-radius: 2px; 
              background-color: $color-bg;
              transform: rotate(45deg) translate(-50%);
              position: absolute; top: 50%; left: 0;
            }

            label {
              padding: 0; margin: 0;
              font-size: $font-size-small;
            }
            h4 {
              padding: 0; margin: 0;
            }
          }
        }

        
      }

      .table-action {
        font-size: 16px;
      
        .icon {
          transform-origin: center;
          @extend %transition_4e;
        }
    
        &:hover{
          color: $color-primary;

          .icon {
            transform: rotate(45deg);
          }
          
        }
      }

      .message {
        p {
          padding: 0; margin: 0;
        }
      }
    }
</style>