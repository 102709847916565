<template>
  <section >
    <transition name="slideNotifDown">
      <div class="notifications" v-if="state">
        <span></span>
        <span></span>
        <div>
          <header>
            <h3>Notificações</h3>
            <div>
              <a href="#" @click="show(!read)" class="showAll">
                  <span>Exibir lidas</span>
                  <div :class="['exibSwitch', {active: read}]">
                    <span></span>
                  </div>
                </a>
            </div>
          </header>
          <div>
            <ol v-if="notifications.length > 0">
              <li v-for="notification in notifications" :key="notification" :class="notification.type">
                <div>
                  <span>
                    <fa :icon="parseContent(notification.type, 'icon')" class="iconWrap" />
                    <h6>{{ notification.titile ? notification.titile : parseContent(notification.type,'title') }}</h6>
                  </span>
                  <p v-html="notification.msg"></p>
                  <span class="timer">
                    <fa :icon="['fas','clock']" class="icon"/>
                    <p>{{ fromNow(notification.timestamp) }}</p>
                  </span>
                </div>
                <div>
                  <label :class="['checkbox-large', { checked: notification.selected }]" v-if="notification.read == 0">
                    <fa :icon="['fass', 'check']" class="icon"></fa>
                    <input type="checkbox" v-model="notification.selected">
                  </label>
                </div>
              </li>
            </ol>
            <div class="empty" v-else>Tudo certo! Você não tem novas notificações.</div>
          </div>
          <footer>
            <a href="#" @click.prevent="markAsRead(true)" :class="{ disabled: !notifications.some( a => a.read == 0) }">
              Marcar todas como lidas
            </a>
            <a href="#" @click.prevent="markAsRead()" :class="['btn btn-primary',{ disabled: !notifications.some( a => a.selected) }]">
              Marcar como lida
            </a>
          </footer>
        </div>
      </div>
    </transition>
    <div class="backdrop" @click="toggleClose()" v-if="state"></div>
  </section>
</template>

<script>
import NotificacaoService from '@/services/notifications.service.js'
import { fromNow } from '@/services/utils.service.js'

export default {
  props: {
    scope: {
      type: Array
    },
    state: {
      type: Boolean,
      default: false
    },
    config: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      console.log("value:", value)
      this.notifications = value
    },
    state ( value ) {
      if ( value ) {
        const self = this
        document.onkeydown = function(e) {
          if (e.key == 'Escape') {
            self.toggleClose()
          }
        } 
      } else {
        document.onkeydown = null
      }
    }
  },
  data () {
    return {
      notifications: [],
      read: false
    }
  },
  mounted () {

  },
  methods: {
    parseContent (type, target) {
      const content = this.config.filter( a => a.type == type)[0][target]
      return content
    },
    markAsRead ( all ) {
      const data = []

      this.notifications.map(k => {
        if ( k.read == 0 ) {
          if ( all ) {
            data.push(k.idNotificacao)
          } else {
            if ( k.selected ) data.push(k.idNotificacao)
          }
        }
      })

      NotificacaoService.markAsRead(data).then(
        response => {
          console.log("response", response)
          this.$emit('refreshList')
        },
        () => {

        }
      )
    },
    toggleClose() {
      this.$emit('close')
    },
    fromNow ( timestamp ) {
      return fromNow(timestamp)
    },
    show ( all ) {
      this.read = all
      this.$emit('get', all)
    }
  }
}
</script>

<style lang="scss" scoped>
  .notifications {
    position: absolute;
    z-index: 1000;
    width: 100%; width: 440px;
    bottom: 10px; left: 50%;
    transform: translate(-50%, 100%);
    @extend %transition_4o;

    > span {
      display: block; width: 20px; height: 20px;
      border-radius: 4px;
      border: $border-component;
      border-width: 2px !important;
      background-color: $color-secondary;
      transform: rotate(45deg);
      position: absolute; top: -5px; left: 0; right: 0;
      margin: auto;
      z-index: 1;
      box-sizing: content-box;

      &:nth-child(2) {
        z-index: 3;
        border: 0 !important;
        box-sizing: border-box;
        border: 1px solid transparent;
        background-clip: border-box;
      }
    }

    > div:not(.backdrop) {
      display: flex; flex-direction: column;
      border: $border-component;
      position: relative; z-index: 2;
      width: 100%; height: auto; max-height: calc(100vh - 120px);
      overflow: hidden;
      background-color: $color-secondary;
      border-radius: $border-radius;
      box-shadow: 10px 4px 16px rgba(0,0,0,0.1);

      header {
        display: flex; align-content: center; align-items: center;
        justify-content: space-between;
        padding: $hmg_mid;

        h3 {
          line-height: 1;
          padding: 0; margin: 0;
        }

        > div {
          text-align: right
        }

        .showAll {
          display: flex; align-items: center;
          .exibSwitch{ 
            width: 26px; height: 16px;
            border-radius: 2em;
            margin-left: $hmg_mini;
            background-color: $color-text;
            position: relative;

            > span {
              width: 10px; height: 10px;
              border-radius: 100%;
              position: absolute;
              top: 50%; transform: translateY(-50%);
              left: 3px;
              @extend %transition_3o;
              background-color: $color-secondary
            }

            &.active {
              background-color: $color-primary;

              > span {
                left: 13px;
              }
            }
          }
        }
      }
      footer {
        padding: $hmg_mid;

        a.disabled {
          opacity: .5;
          pointer-events: none;
        }
      }
      > div {
        flex: 1;
        height: auto; 
        @extend %scrollbar_light;
        overflow-y: auto;
        border-top: $border-component;
        border-bottom: $border-component;
      }

      ol {
        padding: 0; margin: 0;
        
        li {
          display: flex;
          align-items: stretch;
          padding: $hmg_mid;
          border-bottom: $border-component;
          @extend %transition_3o;

          > div {
            &:first-child {
              padding-right: $hmg_mid;
              flex: 1;
            }
            &:last-child {
              // display: flex; flex-direction: column;
              // justify-content: space-between;
              // align-items: flex-end;
            }

            p {
              white-space: normal;
              word-break: break-all;
            }
            >span {
              display: flex; align-items: center;
              align-content: center;
              margin-bottom: $hmg_mini;

              h6 { margin: 0 }
            }
          }

          &:last-child{
            border-bottom: 0 !important;
          }
          
          .iconWrap {
            font-size: 14px;
            margin-right: $hmg_mini
          }

          &:hover {
            background-color: $color-bg;

            .checkbox-large {
              background-color: $color-secondary;
              .icon {
                color: $color-bg;
              }
            }
          }

          &.apollo {
            .iconWrap {
              color: $color-notificacao
            }
            h6 {
              color: $color-notificacao
            }
          }
          &.sincronismo {
            .iconWrap {
              color: $color-sincronismo
            }
            h6 {
              color: $color-sincronismo
            }
          }
          &.atividades {
            .iconWrap {
              color: $color-atividades
            }
            h6 {
              color: $color-atividades
            }
          }
          &.relatorios {
            .iconWrap {
              color: $color-relatorio
            }
            h6 {
              color: $color-relatorio
            }
          }
          &.jarvis {
            .iconWrap {
              color: $color-jarvis
            }
            h6 {
              color: $color-jarvis
            }
          }
          .checkbox-large {
            display: flex; flex-direction: column;
            justify-content: center; align-items: center;
            width: 24px; height: 24px;
            background-color: $color-bg;
            border-radius: $border-radius-small;
            border: $border-component;
            position: relative;
            @extend %transition_3o;

            .icon {
              font-size: 16px;
              color: $color-secondary;
              @extend %transition_3o
            }
            &.checked {
              .icon {
                font-size: 18px;
                color: $color-primary;
              }
            }
            input {
              opacity: 0;
              z-index: -1;
              position: absolute;
            }
          }
        }
      }
    }
  
    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .checkReadToggle {
      font-size: 16px;
      
      &:hover {
        color: $color-primary
      }
    }

    .timer {
      display: flex; align-items: center; align-content: center;
      font-size: $font-size-small;
      margin-top: $hmg_mini;
      .icon {
        margin-right: $hmg_mini
      }
      p {
        margin: 0;
      }
    }
  }

  .backdrop { 
    width: 100vw; height: 100vh;
    background: transparent;
    position: fixed; z-index: 900;
    left: 0; top: 0;
  }

  .empty {
    padding: $hmg_mid;
    text-align: center;
  }
  .slideNotifDown-enter-active, .slideNotifDown-leave-active {
    transform: translate(-50%, 100%); opacity: 1;
  }

  .slideNotifDown-enter-from, .slideNotifDown-leave-to {
    transform: translate(-50%, 90%); opacity: 0;
  }
</style>