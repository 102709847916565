<template>
  <section>
    <card :listed="false">
      <div class="listWrap">
        <header>
          <div>
            <span>
              <fa :icon="['fas','clipboard-check']" class="icon"/>
            </span>
            <div>
              <h5 class="color-primary">Minhas Atividades</h5>
              <p class="color-primary">{{ atividades.length }} {{ atividades.length == 1 ? 'pendente' : 'pendentes' }}</p>
            </div>
          </div>
          <div>
            <router-link  class="btn btn-secondary" :to="{ name: 'Painel Atividades'  }">
              Ver Todas
            </router-link>
            <router-link  class="btn btn-primary" :to="{ name: 'Painel Atividades', params: { section: 'atendimentos', action: 'novo' } }">
              Criar Nova
            </router-link>
          </div>
        </header>
        <div class="filter-wrap">
          <div>
            <label class="check-control">
              <input type="checkbox" class="check view" v-model="filter.view" @change="filterPermission()"/>
              Ver
            </label>
            <!-- <label class="check-control">
              <input type="checkbox" class="check edit" v-model="filter.edit" @change="filterPermission()"/>
              Editar
            </label> -->
            <label class="check-control">
              <input type="checkbox" class="check execute" v-model="filter.execute" @change="filterPermission()"/>
              Executar
            </label>
          </div>
          <div>
            <nav class="filterType">
              <a 
                v-for="item in types" 
                :key="item" 
                :class="[item.slug, { hidden: selectedType == item.slug }]"
                @click.prevent="filterType(item)"
                href="#"
              >
                <p>{{ item.label }}</p>
                <span></span>
              </a>
              <a 
                @click.prevent="filterType('todos')"
                class="todos"
                href="#"
                v-if="selectedType"
              >
                <p>Todos</p>
                <span></span>
              </a>
            </nav>
          </div>
          <div>
            <label>Exibir</label>
            <div class="select-wrap">
              <select-control :options="options.amount" v-model="amount" placeholder="Exibir" cssClass="small" @change="triggerAmount()" />
            </div>
          </div>
        </div>
        <form-spinner v-if="loading"></form-spinner>
        <div v-else>
          <ol v-if="atividades.length > 0">
            <li v-for="(atividade, i) in atividades" :key="atividade" :class="{ active: atividade.active, processing: atividade.processing }" :ref="'atividade_' + i ">
              <router-link v-if="atividade.tiposlug == 'atendimentos'" :to="{ name: 'Painel Atividades', params: { section: atividade.tiposlug, action: atividade.emAndamento ? 'executar' : 'ver', id: atividade.idAtividade }}" :disabled="preventClick">
                <header>
                  <h5 :class="atividade.tiposlug">{{ atividade.tipoLabel }}</h5>
                  <div>
                    <span class="timeframe">
                      {{ atividade.unidade }}
                    </span>
                    <span :class="['timeframe', status(atividade)]">
                      <fa :icon="['fas','calendar-star']" v-if="status(atividade) == ''" class="icon"/>
                      <fa :icon="['fas','calendar-exclamation']" v-else class="icon"/>
                      {{ formatDate(atividade.dataOcorrencia) }}
                    </span>
                    <div class="permissionTags">
                      <span class="permissionTag view" v-if="atividade.view" title="Ver">
                        <fa :icon="['fas','eye']" class="icon"/>
                      </span>
                      <!-- <span class="permissionTag edit" v-if="atividade.edit" title="Editar">
                        <fa :icon="['fas','pen-to-square']" class="icon"/>
                      </span> -->
                      <span class="permissionTag execute" v-if="atividade.execute" title="Executar">
                        <fa :icon="['fas','pencil']" class="icon"/>
                      </span>
                    </div>
                  </div>
                </header>
                <div class="atribuicao">
                  <span>
                    <fa :icon="['fas', 'user-tie-hair']" />
                    <p>{{ atividade.idUserAtribuido ? atividade.nome : 'Não atribuído' }}</p>
                  </span>
                  <fa :icon="['fal','arrow-right-long']" />
                  <span>
                    <fa :icon="['fas', 'user-hair']" />
                    <p>{{ getAtendido(atividade) }}</p>
                  </span>
                </div>
                <div v-if="atividade.alunoNome" class="atribuicao">
                  <span>
                    <fa :icon="['fas', 'user-graduate']" />
                    <p>{{ atividade.alunoNome }}</p>
                  </span>
                </div>
                <div class="infoBlock">
                  <h5>
                    {{ atividade.nomeCategoria }}
                  </h5>
                  <p v-if="atividade.observacoes">
                    {{ atividade.observacoes }}
                  </p>
                  <a v-if="atividade.linkExterno" :href="atividade.linkExterno" target="_blank" @mouseenter="preventClick = true" @mouseleave="preventClick = false">
                    {{ atividade.linkExterno }}
                  </a>
                </div>
              </router-link>
              <div v-else>
                <form-spinner v-if="atividade.processing"></form-spinner>
                <header :class="{ hasSpinner: atividade.processing }">
                  <h5 :class="atividade.tiposlug">{{ atividade.tipoLabel }}</h5>
                  <div>
                    <span class="timeframe">
                      {{ atividade.unidade }}
                    </span>
                    <span :class="['timeframe', status(atividade)]">
                      <fa :icon="['fas','calendar-star']" v-if="status(atividade) == ''" class="icon"/>
                      <fa :icon="['fas','calendar-exclamation']" v-else class="icon"/>
                      {{ formatDate(atividade.dataOcorrencia) }}
                    </span>
                    <div class="permissionTags">
                      <span class="permissionTag view" v-if="atividade.view" title="Ver">
                        <fa :icon="['fas','eye']" class="icon"/>
                      </span>
                      <!-- <span class="permissionTag edit" v-if="atividade.edit" title="Editar">
                        <fa :icon="['fas','pen-to-square']" class="icon"/>
                      </span> -->
                      <span class="permissionTag execute" v-if="atividade.execute" title="Executar">
                        <fa :icon="['fas','pencil']" class="icon"/>
                      </span>
                    </div>
                  </div>
                </header>
                <div class="atribuicao">
                  <span>
                    <fa :icon="['fas', 'user-tie-hair']" />
                    <p>{{ atividade.idUserAtribuido ? atividade.nome : 'Não atribuído' }}</p>
                  </span>
                  <fa :icon="['fal','arrow-right-long']" />
                  <span>
                    <fa :icon="['fas', 'user-hair']" />
                    <p>{{ getAtendido(atividade) }}</p>
                  </span>
                </div>
                <div v-if="atividade.alunoNome" class="atribuicao">
                  <span>
                    <fa :icon="['fas', 'user-graduate']" />
                    <p>{{ atividade.alunoNome }}</p>
                  </span>
                </div>
                <div class="infoBlock">
                  <h5>
                    {{ atividade.nomeCategoria }}
                  </h5>
                  <p v-if="atividade.observacoes">
                    {{ atividade.observacoes }}
                  </p>
                  <a v-if="atividade.linkExterno" :href="atividade.linkExterno" target="_blank" >
                    {{ atividade.linkExterno }}
                  </a>
                </div>
                <footer v-if="atividade.execute">
                  <div>
                    <input-control placeholder="Observação (Opcional)" v-model="atividade.registroAtendimento" cssClass="mid"/>  
                  </div>
                  <a href="#" :class="'btn btn-' + atividade.tiposlug" @click.prevent="execute(atividade, i)">
                    Feito
                  </a>
                </footer>
              </div>
            </li>
          </ol>
          <div v-else class="empty">
            <p>Você não tem atividades pendentes</p>
          </div>
        </div>
      </div>
    </card>
  </section>
</template>

<script>
import AtividadesService from '@/services/atividades.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'
import { formatDate } from '@/services/utils.service.js'
import { gsap } from "gsap/dist/gsap"

export default {
  components: {
    FormSpinner
  },
  data () {
    return {
      atividades: [],
      scope: [],
      loading: false,
      preventClick: false,
      options: {
        amount: [
          { value: 10, label: 10 },
          { value: 30, label: 30 },
          { value: 50, label: 50 }
        ]
      },
      amount: 10,
      filter: {
        view: true,
        // edit: true,
        execute: true
      },
      types: [
        {
          label: "Atendimentos",
          slug: "atendimentos",
          single: "Atendimento"
        },
        {
          label: "Tarefas",
          slug: "tarefas",
          single: "Tarefa"
        },
      ],
      selectedType: 0
    }
  },
  mounted () {
    const aa = localStorage.getItem('aa')
    this.amount = aa ? aa : 10

    this.getAtividades()
  },
  methods: {
    getAtividades () {
      this.loading = true
      AtividadesService.getAtividadesPendentesHome( this.amount, this.selectedType ).then(
        response => {
          console.log("getAtividades:", response)
          if ( response.data.length > 0 ) {
            this.atividades = response.data
            this.atividades.map( k => {
              k['active'] = false
              k['processing'] = false
              k['done'] = false
            })
            this.scope = Object.assign([], this.atividades)
            
          } else {
            this.atividades = []
          }
          
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
    formatDate ( timestamp ) {
      // console.log("timestamp:", timestamp)
      // console.log("fromNow:", fromNow(timestamp))

      return formatDate(timestamp)
    },
    goToAtividade (atividade) {
      // console.log("atividade:", atividade)

      const section = 'atendimentos'
      const action = atividade.emAndamento ? 'executar' : 'ver'

      this.$router.push({ name: 'Painel Atividades', params: { section: section, action: action, id: atividade.idAtividade }})
    },
    status ( atividade ) {
      if ( atividade.statusSLA == 'SLA Não Vencido' ) return ''
      else if ( atividade.statusSLA == 'SLA Vencido' ) return 'alert2'
    },
    goToAtividades () {
      this.$router.push({ name: "Painel Atividades", params: { section: "atendimentos" } })
    },
    getAtendido ( item ) {
      if ( item.idPerfilPessoa == 1  ) { //aluno
        return item.alunoNome
      }
      if ( item.idPerfilPessoa == 2 ) { //colaborador 
        return item.nomeColaborador
      }
      if ( item.idPerfilPessoa == 3  ) { //responsavel
        return item.responsavelNome
      }
      // console.log("getAtendido:", item)
    },
    createAtividade () {
      this.$router.push({ name: "Painel Atividades", params: { section: "atendimentos", action: 'novo' } })
    },
    triggerAmount () {
      localStorage.setItem('aa', this.amount)
      this.getAtividades()
    },
    filterPermission () {
      const filter = {
        view: this.filter.view ? 1 : 0,
        // edit: this.filter.edit ? 1 : 0,
        execute: this.filter.execute ? 1 : 0
      }

      this.atividades = this.scope.filter( obj => 
        Object.keys(obj).some(key => obj[key] === filter[key])
      )

    },
    execute ( data, i ) {
      const atividade = {
        idAtividade: data.idAtividade,
        registroAtendimento: data.registroAtendimento,
        // anexo: null,
        idFormulario: null,
        formulario: []
      }
      this.atividades[i]['processing'] = true
      this.atividades[i]['active'] = true
      // console.log("atvidade:", atividade)

      AtividadesService.saveAtividade(atividade.idAtividade, atividade).then(
        response => {
          if ( response.status == 200 ) {
            this.atividades[i]['processing'] = false
            setTimeout(() => {
              // this.atividades[i]['done'] = true
              const item = this.$refs['atividade_' + i]
              const tl = gsap.timeline()

              tl.to(item, {
                x: '100%',
                scale: 0.4,
                opacity: 0,
                duration: 0.3,
                easy: 'back.in(2)',
                onComplete: () => {
                  this.atividades.splice(i,1)
                }
              })
            },1000)
          }
        },
        () => {
          
        }
      )
      console.log("save:", atividade)
    },
    filterType ( item ) {
      if ( item == 'todos' ) {
        this.selectedType = null
        // this.$emit('filterType', null)
      } else {
        this.selectedType = item.slug
        // this.$emit('filterType', item)
      }

      this.$nextTick(() => {
        this.getAtividades()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .listWrap {
    > header {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      border-bottom: $border-component;
      position: sticky; top: 0;

      >div {
        display: flex;
        align-items: center;
        align-content: flex-start;
        gap: $mg_mini;
        padding: $hmg_mid;

        span {
          display: flex; flex-direction: column;
          align-items: center; justify-content: center;
          width: 40px; height: 40px;
          background-color: $color-primary; color: $color-secondary;
          border-radius: 100%;
          font-size: 22px;
        }

        >div {
          // margin-left: $mg_mini;

          h5,p {
            margin: 0; padding: 0;
          }
        }

        &:last-child {
          display: flex; align-items: center;
          justify-content: flex-end; gap: $mg_mini
        }
      }
      
    }
    >div {
      ol {
        padding: 0; margin: 0;

        li {
          list-style: none;
          margin: 0; padding: 0;
          border-bottom: $border-component;
          @extend %transition_4o;

          >a,>div {
            display: block;
            padding: $hmg_mid;  
            @extend %transition_3o;

            &[disabled=true] {
              pointer-events: none;

              a {
                pointer-events: auto
              }
            }

            &>a {
              cursor: pointer;
            }

            &:last-child {
              border-bottom: 0 !important;
            }

            > header {
              display: flex;
              align-items: center;
              align-content: flex-start;
              justify-content: space-between;
              margin-bottom: $mg_mini;

              &.hasSpinner {
                margin-top: $mg_mini;
              }
              h5 {
                &.atendimentos {
                  color: $color-atendimentos;
                }
                &.tarefas {
                  color: $color-tarefas;
                }
              }

              >div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: $mg_mini;
              }
              .timeframe {
                padding: $hmg_mini $mg_mini;
                border-radius: 2em;
                background-color: $color-bg;
                line-height: 1;

                &.alert1 {
                  background-color: $color-warning;
                  color: $color-secondary
                }
                &.alert2 {
                  background-color: $color-error;
                  color: $color-secondary
                }

                .icon {
                  margin-right: 3px;
                  font-size: $font-size;
                }
              }
            }
            
            &:hover {
              background-color: $color-bg
            }

            .infoBlock {
              margin: $mg_mini 0;

              p {
                padding: 0; margin: 0
              }
            }
          }
          
          
          &.active {
            > div {
              background-color: $color-active-light;
            }
          }
          &.processing {
            footer {
              opacity: .7;
              pointer-events: none
            }
          }

          footer {
            display: flex; align-items: center;
            align-content: stretch;
            gap: $mg_mini;

            div {
              flex: 1
            }
          }
        }
      }
    }
  }

  .empty {
    padding: $hmg_mid;
    margin: 0;
  }

  .atribuicao {
    display: flex; align-items: center; align-content: center;
    margin-bottom: $hmg_mini;
    gap: $mg_mini;
    
    >span {
      display: flex; align-items: center; align-content: center;
      gap: $hmg_mini;
    }
    p {
      font-size: $font-size-small;
      margin: 0; padding: 0;
    }
  }

  .filter-wrap {
    display: flex; align-items: center;
    border-bottom: $border-component;    
    padding: $mg_mini $hmg_mid;
    justify-content: space-between;

    >div {
      display: flex; align-items: center;
      gap: $mg_mini;

      &:first-child{
        gap: $hmg_mid;
      }
      

      label { margin: 0; padding: 0; }
    }
  }
  .select-wrap {
    max-width: 140px;
  }

  .permissionTags {
    display: flex; align-items:center;
    justify-content: flex-end; gap: $hmg_mini;

    .permissionTag {
      display: flex; flex-direction: column;
      justify-content: center; align-items: center;
      width: 24px; height: 24px;
      border-radius: 100%;
      background-color: $color-primary;
      font-size: 12px;
      color: $color-secondary;
      text-align: center;

      &.view { background-color: $color-view }
      &.edit { background-color: $color-edit }
      &.execute { background-color: $color-execute }
    }  
  }
  

  .filterType {
    display: flex;
    align-items: center; align-content: flex-start;
    gap: $mg_mini;

    a {
      display: flex; align-items: center; align-content: center; 
      justify-content: center; flex-direction: column;
      width: auto; padding: $hmg_mini $mg_mini;
      position: relative;
      @extend %transition_3o;

      &.hidden {
        display: none
      }

      > span {
        display: block;
        width: 100%; height: 100%;
        border-radius: 2em;
        position: absolute; z-index: 1;
        @extend %transition_4e;
        top: 0; left: 0; right: 0;
        margin: auto;
        // transform: translateY(-50%) scale(1);
        transform-origin: center center;
      }
      > p {
        position: relative; z-index: 2;
        margin: 0; padding: 0;
        color: $color-secondary;
      }

      &:hover {
        > span {
          transform: scale(1.1)
        }
      }

      &.atendimentos {
        > span {
          background-color: $color-atendimentos;
        }
      }
      &.tarefas {
        > span {
          background-color: $color-tarefas;
        }
      }
      &.tickets {
        > span {
          background-color: $color-tickets;
        }
      }
      &.solicitacoes {
        > span {
          background-color: $color-solicitacoes;
        }
      }
      &.prospects {
        > span {
          background-color: $color-prospects;
        }
      }
      &.todos {
        > p {
          color: $color-text
        }
        > span {
          background-color: $color-bg;
        }
      }
    }
  }
</style>