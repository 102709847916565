<template>
  <section>
    <div class="form-wrap" ref="form">
      <h6>{{ pergunta.descricao }}</h6>
      <template v-if="pergunta.tipoResposta == 1">
        <textarea-control 
          v-model="resposta" 
          @change="getChange" 
          :required="required" 
          :disabled="disabled"
        />
      </template>
      <template v-if="pergunta.tipoResposta == 2 || pergunta.tipoResposta == 3">
        <ul class="opt">
          <li v-for="opcao in pergunta.opcoes" :key="opcao">
            <label class="check-control" :required="required">
              <input 
                v-if="pergunta.tipoResposta == 2"
                class="check"
                type="radio"
                :name="opcao.idFormularioPergunta"
                :value="opcao.idFormularioAlternativa"
                v-model="resposta"
                @change="getChange"
                :disabled="disabled"
              />
              <input 
                v-else
                class="check"
                type="checkbox"  
                :value="opcao.idFormularioAlternativa"
                v-model="resposta"
                @change="getChange"
                :disabled="disabled"
              />
              <span>{{ opcao.alternativa }}</span>
            </label>
          </li>
        </ul>
      </template>
      <template v-if="pergunta.tipoResposta == 4">
        <file-control @change="getChange" :required="required" :disabled="disabled" :thumb="resposta"/>
      </template>
      <template v-if="pergunta.tipoResposta == 5 || pergunta.tipoResposta == 6">
        <input-control 
          :type="pergunta.tipoResposta == 5 ? 'number' : 'date'" 
          v-model="resposta" 
          @change="getChange" 
          :required="required" 
          :disabled="disabled"
        />
      </template>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pergunta: {
      type: Object
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      config: [
        {
          id: 1,
          label: "Dissertativa",
          itens: false,
          placeholder: true
        },
        {
          id: 2,
          label: "Múltipla Escolha",
          itens: true,
          placeholder: false
        },
        {
          id: 3,
          label: "Caixa de Seleção",
          itens: true,
          placeholder: false
        },
        {
          id: 4,
          label: "Anexo",
          itens: false,
          placeholder: true
        },
        {
          id: 5,
          label: "Números",
          itens: false,
          placeholder: true
        },
        {
          id: 6,
          label: "Data",
          itens: false,
          placeholder: false
        }
      ],
      resposta: null
    }
  },
  mounted () {
    // console.log('perguntas....:', this.pergunta)
    if ( this.pergunta.tipoResposta == 3 ) {
      this.resposta = [] 
    }

    // console.log("resposta da pergunta:", this.pergunta.resposta)
    if ( this.pergunta.resposta ) {  
      this.dropResp(this.pergunta.resposta)
    }
    
  },
  methods: {
    getChange ( target, asw ) {
      if ( asw ) {
        this.resposta = asw
      }
      if ( this.pergunta.tipoResposta == 4 ) {
        this.resposta = target
      }
      
      const value = { target, pergunta: this.pergunta, resposta: this.resposta }
      // console.log("value:", value)
      
      this.$emit("onChange", value)
    },
    dropResp ( resposta ) {
      console.log("resposta:", resposta)

      if ( resposta.length > 0 && resposta[0].urlDocumento ) {
        console.log("aqui...", resposta[0].urlDocumento)
        this.resposta = resposta[0].urlDocumento
      } else if ( this.pergunta.tipoResposta == 3 ) {
        this.resposta = []
        resposta.map( k => {
          // console.log("k:", k)
          this.resposta.push(k.idFormularioAlternatiava)
          // 
        })
      } else {
        if ( resposta[0] && resposta[0].idFormularioAlternatiava ) {
          this.resposta = resposta[0].idFormularioAlternatiava
        } else if ( resposta[0] ){
          this.resposta = resposta[0].resposta
          // console.log("resposta:", this.resposta)
        }
      }
      // console.log("final - resposta:", this.resposta)
      // console.log("type of resposta:", type)
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-wrap {
    margin-top: $hmg;

    > h6 {
      margin-bottom: $mg_mini;
    }
  }

  .opt {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      label {
        margin-left: -$hmg_mini !important
      }
    }
  }
</style>