<template>
  <app-controller title="Recuperação de Senha">
    <div class="userError" v-if="userError">
      <fa class="icon" :icon="['fal','face-meh']"></fa>
      <h4>Ops! Existe algo errado com a sua conta.</h4>
      <p>Não conseguimos utilizar seu email cadastrado para acessar sua conta. <br>Caso o problema persista você pode procura nosso atendimento utilizando o App Rede Decisão.</p>
    </div>
    <ul class="cardList" v-else>
      <li v-for="aluno in alunos" :key="aluno">
        <h3>{{ aluno.nome }}</h3>
        <p>{{ aluno.email }}</p>
        <a href="#" @click.prevent="confirmResetGooglePassword(aluno)" class="btn btn-primary">
          Restaurar Senha &nbsp;
          <fa :icon="['fas','lock']"></fa>
        </a>
      </li>
    </ul>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </app-controller>
</template>

<script>
import AuthService from '@/services/auth.service'

export default {
  data () {
    return {
      alunos:[],
      userError: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    console.log("currentUser", this.currentUser)
    if ( this.currentUser ) {
      this.alunos = this.currentUser.alunos
    } else {
      this.userError = true
    }
    
  },
  methods: {
    confirmResetGooglePassword ( aluno ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja redefinir a senha deste aluno?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: aluno,
        callback: this.resetGooglePassword,
      })
    },
    resetGooglePassword ( state, aluno ) {
      const email = aluno.emailAcademico

      if ( email ) {
        if ( state ) {
          window.spinner.emit('open')
          AuthService.resetGooglePassword( email ).then(
            response => {
              if ( response.data ) {
                // this.newPass = response.data.novaSenha
                this.$refs.confirm.run({
                  message: "<span class='color-text'>Senha do aluno </span>" + aluno.nome +" <span class='color-text'>redefinida com sucesso!</span>",
                  obs: "A nova senha foi encaminhada para o e-mail do responsável financeiro.<br><br><b>A nova senha é:</b> <br/><b style='font-size: 28px;' class='font-code'>" + response.data.novaSenha + "</b>",
                  confirmButton: "Copiar senha",
                  denyButton: "Fechar",
                  data: response.data.novaSenha,
                  size: 'mid',
                  callback: this.copy,
                  preventCloseOnTrue: true
                })
              } else {
                //
              }
              console.log("response:", response)
              window.spinner.emit('close')
            },
            error => {
              console.log("error:", error)
              window.spinner.emit('close')
            }
          )
        }
      } else {
        this.$refs.confirm.run({
          message: "Ops. Este aluno não tem email acadêmico cadastrado.",
          confirmButton: "Ok"
        })
      }
    },
    copy ( state, newPass ) {
      if ( state ) {
        navigator.clipboard.writeText(newPass)
        
        setTimeout(() => {
          window.toaster.emit('open', {style: "success", message: "Senha copiada para a área de transferência.", floater: true})
        },500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .cardList {
    padding: $mg_mini; margin: 0;
    display: flex; flex-wrap: wrap;
    align-content: stretch; align-items: stretch;

    > li {
      flex: 1;
      max-width: 414px; margin: $mg_mini;
      list-style: none; padding: $hmg;
      border-radius: $border-radius;
      background-color: $color-secondary;

      a {
        margin-top: $mg_mini;
      }
    }
  }

  .userError { 
    text-align: center;
    padding: $mg; 

    .icon {
      font-size: 64px;
      margin-bottom: $hmg;
    }

    p {
      max-width: 50%; margin: auto;
    }
  }
</style>