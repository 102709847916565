<template>
  <section>
    <template v-if="processing">
      <div class="processing">
        <h3>Aguarde. Estamos processando sua solicitação</h3>
        <div class="iconWrap">
          <fa :icon="['fal', 'arrows-turn-to-dots']" class="icon"/>
        </div>
      </div>
    </template>
    <template v-else>
      <header v-if="matricula">
        <h4>{{ matricula.aluno.Basicos.nome }}</h4>
        <p>{{ matricula.aluno.Basicos.email }}</p>
      </header>
      <template v-if="confirm">
        <div class="confirmBox">
          <h4>Confirme as informações abaixo antes de prosseguir</h4>
          <div class="">
            <div>
              <h5>Turma de Origem</h5>
              <ul>
                <li>
                  <fa :icon="['far','school-flag']" class="icon" />
                  <p>{{ matricula.matricula.descricaoUnidade }}</p>
                </li>
                <li>
                  <fa :icon="['far','books']" class="icon" />
                  <p>{{ matricula.matricula.descricaoCurso }}</p>
                </li>
                <li>
                  <fa :icon="['far','clock']" class="icon" />
                  <p>{{ matricula.matricula.serieNomeExibicao }}</p>
                </li>
                <li>
                  <fa :icon="['far','chalkboard-user']" class="icon" />
                  <p>{{ matricula.matricula.descricaoTurma }}</p>
                </li>
              </ul>
            </div>
            <span>
              <fa :icon="['far', 'arrow-right-long']" />
            </span>
            <div>
              <h5>Turma de Destino</h5>
              <ul>
                <li>
                  <fa :icon="['far','school-flag']" class="icon" />
                  <p>{{ destino.descricaoUnidade }}</p>
                </li>
                <li>
                  <fa :icon="['far','books']" class="icon" />
                  <p>{{ destino.descricaoCurso }}</p>
                </li>
                <li>
                  <fa :icon="['far','clock']" class="icon" />
                  <p>{{ destino.descricaoSerie }}/{{ destino.descricaoTurno }}</p>
                </li>
                <li>
                  <fa :icon="['far','chalkboard-user']" class="icon" />
                  <p>{{ destino.descricao }}</p>
                </li>
              </ul>
            </div>
          </div>
          <footer>
            <div>
              <a href="#" class="btn btn-secondary" @click.prevent="goBack()">
                Voltar
              </a>
              <a href="#" class="btn btn-secondary" @click.prevent="cancel()">
                Cancelar
              </a>
            </div>
            <a href="#" class="btn btn-primary" @click.prevent="remanejar()">
              Confirmar e Remanejar
            </a>
          </footer>
        </div>
      </template>
      <template v-else>
        <div v-if="matricula">
          <h4>Matrícula Atual</h4>
          <ul>
            <li>
              <fa :icon="['far','school-flag']" class="icon" />
              <p>{{ matricula.matricula.descricaoUnidade }}</p>
            </li>
            <li>
              <fa :icon="['far','books']" class="icon" />
              <p>{{ matricula.matricula.descricaoCurso }}</p>
            </li>
            <li>
              <fa :icon="['far','chalkboard-user']" class="icon" />
              <p>{{ matricula.matricula.descricaoTurma }}</p>
            </li>
          </ul>
        </div>
        <div v-if="options.series && options.series.length > 0">
          <div>
            <h4>Selecione a série de destino</h4>
            <select-control :options="options.series" v-model="serie" placeholder="Selecione" @change="filterTurmas()"/>
          </div>
          <div v-if="turmas && turmas.length > 0">
            <h4>Agora, escolha a turma</h4>
            <table class="content-table">
              <thead>
                <tr>
                  <th>Turma</th>
                  <th>Turno</th>
                  <th>Período Letivo</th>
                  <th>Capacidade</th>
                  <th>Vagas</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="turma in turmas" :key="turma">
                  <td>{{ turma.descricao }}</td>
                  <td>{{ turma.descricaoTurno }}</td>
                  <td>{{ turma.descricaoPeriodoLetivo }}</td>
                  <td>{{ turma.capacidade }}</td>
                  <td>{{ turma.vagas }}</td>
                  <td>
                    <a href="#" class="matricular" :disabled="turma.vagas == 0" @click.prevent="toggleConfirm(turma)">
                      <fa :icon="['fal','face-smile-plus']"/>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="emptyAlert" v-if="emptyTurmas()">
              <fa :icon="['fas', 'triangle-exclamation']" />
              <span>Não existem turmas disponíveis para remanejamento.</span>
            </div>
          </div>

        </div>
      </template>
    </template>
    
  </section>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    idMatricula: {
      type: [String, Number]
    }
  },
  data () {
    return {
      matricula: null,
      options: {
        series: null
      },
      serie: null,
      turmas: null,
      confirm: false,
      destino: null,
      processing: false
    }
  },
  mounted () {
    console.log("this.matricula:", this.idMatricula)
    this.getMatricula()
  },
  methods: {
    getMatricula () {
      MatriculaService.getAlunoByIdMatricula(this.idMatricula).then(
        response => {
          this.matricula = response.data
          console.log("getAlunoByIdMatricula:", response)
          this.getSeries(this.matricula.matricula.idPeriodoLetivo)
        },
        () => {

        }
      )
    },
    getSeries ( idPeriodoLetivo ) {
      AcademicoService.getSeries( idPeriodoLetivo, 0 ).then(
        response => {
          this.filterSeries(response.data)
        },
        () => {

        }
      )
    },
    filterSeries ( series ) {
      const curso = this.matricula.matricula.idCurso
      const turma = this.matricula.matricula.idTurma
      const nomeSerie = this.matricula.matricula.descricaoSerie

      console.log('curso:', curso)
      console.log('turma:', turma)
      console.log('nomeSerie:', nomeSerie)
      console.log('series:', series)

      const filtered = series.filter( a => a.descricao == nomeSerie && a.idCurso == curso && a.idTurma != turma)
      console.log('filtered:', filtered)
      this.options.series = createSelectOptions(filtered, 'idSerie', 'nomeExibicao')
      console.log('series:', this.options.series)
    },
    filterTurmas () {
      const current = this.matricula.matricula.idTurma

      AcademicoService.getTurmas(this.serie).then(
        response => {
          console.log("turmas:", response.data)
          const turmas = response.data.filter( a => a.idTurma != current)
          this.turmas = turmas
        },
        () => {

        }
      )
    },
    emptyTurmas () {
      const empty = this.turmas.every( a => a.vagas == 0 )
      return empty
    },
    toggleConfirm ( destino ) {
      console.log("this.matricula:", this.matricula)
      console.log("destino:", destino)

      this.destino = destino

      this.confirm = true
    },
    goBack () {
      this.confirm = false
      this.destino = null
    },
    cancel (refresh) {
      this.$emit("onClose", refresh)
    },
    remanejar () {
      const remanejamento = {
        matricula: {
          idSerie: this.destino.idSerie,
          idTurma: this.destino.idTurma
        }
      }
      this.processing = true
      // console.log("remanejamento:", remanejamento)

      MatriculaService.trocaTurma(this.matricula.matricula.idMatricula, remanejamento).then(
        response => {
          this.processing = false
          if ( response.status == 200 || response.status == 201 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo. Remanejamento concluído com sucesso.", floater: true})

            setTimeout(() => {
              this.cancel(true)
            },500)
          }
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! Não foi possível remanejar o aluno para a turma selecionada.", floater: true})
          this.processing = false
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    >header {
      padding-bottom: $mg_mini;
    }

    >div {
      padding: $hmg_mid 0;
      border-top: $border-component;

      > div {
        margin-bottom: $hmg_mid
      }

      ul {
        display: flex;
        align-items: center; align-content: flex-start;
        padding: 0; margin: $hmg_mid 0 $mg_mini 0;
        gap: $hmg_mid;

        li {
          display: flex;
          align-items: center; align-content: center;
          list-style: none;
          padding: $mg_mini $mg_mini; margin: 0;
          border-radius: $border-radius;
          background-color: $color-bg;
          gap: $mg_mini; line-height: 1;

          .icon {
            font-size: 16px
          }
          p {
            margin: 0; padding: 0;
          }
        }
      }
    }
  }
  
  .matricular{
    font-size: 16px;

    &:hover {
      color: $color-primary
    }
    &[disabled='true'] {
      opacity: .5;
      pointer-events: none;
    }
  }

  .emptyAlert {
    display: flex; align-items: center;
    gap: $hmg_mini;
    border-radius: $border-radius-small;
    background-color: $color-alert;
    color: $color-secondary;
    margin-top: $hmg_mid; padding: $mg_mini
  }

  .confirmBox {
    h4 { 
      text-align: center;
      margin-bottom: $mg_mid;
    }

    >div {
      display: flex;
      align-items: center;
      gap: $hmg_mid;

      span {
        font-size: 24px;
        padding: 0 $mg_mini
      }
      div {
        flex: 1;
        background-color: $color-bg;
        padding: $hmg_mid;
        border-radius: $border-radius;
        
        ul {
          display: flex; flex-direction: column;
          align-items: flex-start;
          padding: 0; margin: $hmg_mid 0 0 0;
          
          li {
            display: flex;
            align-items: center; align-content: center;
            list-style: none;
            padding: 0; margin: 0;
            border-radius: none;
            background-color: $color-bg;
            gap: $mg_mini; line-height: 1;

            .icon {
              font-size: 16px
            }
            p {
              margin: 0; padding: 0;
            }
          }
        }
      }
    }
    
    footer {
      display: flex; align-items: center;
      justify-content: space-between;

      > div {
        display: flex; align-items: center;
        justify-content: flex-start;
        gap: $mg_mini
      }
    }
  }

  .processing {
    border-top: 0 !important;
    padding: $mg;
    text-align: center;

    .icon {
      font-size: 84px;
      margin-top: $mg;
    }

    .iconWrap {
      animation-name: bounce;
      animation-duration: 1.2s;
      animation-delay: .6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
  }

  @keyframes bounce {
    0%, 75%, 90%, 100%  { transform: scale(1) translateY(0) }
    10%  { transform: scale(1.1,0.9) translateY(0) }
    50%  { transform: scale(0.9,1.1) translateY(-25%) }
    // 75%  { transform: scale(1) translateY(0)  }
    85%  { transform: scale(1.05,0.95) translateY(0) }
    // 100% { transform: scale(1) translateY(0)  }
  }
</style>