<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h4 class="text-capitalize">Vendas & Transações</h4>
      <template>
        <!-- <a 
          href="#" 
          :class="['btn', { 'btn-primary': !creatingDesconto , 'btn-secondary': creatingDesconto }]" @click.prevent="toggleCreateDesconto(true)">Novo Desconto</a>
        <a href="#" :class="['btn', { 'btn-primary': !creatingVoucher , 'btn-secondary': creatingVoucher }]" @click.prevent="toggleCreateVoucher(true)">Novo Voucher</a> -->
      </template>
    </div>
    <div class="page-inner" v-if="!loading">
      <div :class="['search-filter frame fixed', { mini: !fullFilter}]">
        <form @keyup.enter="runFilter">
          <div class="row no-padd">
            <div class="col-md-12 form-group">
              <label>Nome</label>
              <input-control v-model="filter.nome" placeholder="Aluno ou Responsável" :autocomplete="true"/>
            </div>
            <div class="col-md-4 form-group">
              <input-control v-model="filter.idVendaMaterial" placeholder="ID Venda Material" :autocomplete="true"/>
            </div>
            <div class="col-md-4 form-group">
              <input-control v-model="filter.idTransacao" placeholder="ID Transação Venda" :autocomplete="true"/>
            </div>
            <div class="col-md-4 form-group">
              <input-control v-model="filter.idTransacaoMeioPagamento" placeholder="ID PagarMe" :autocomplete="true"/>
            </div>
            <transition name="fadein">
              <div class="col-md-3 form-group" v-if="fullFilter">
                <label>Vencimento de</label>
                <input-control v-model="filter.vencimentoDe" placeholder="Vencimento" type="date" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-3 form-group" v-if="fullFilter">
                <label>Vencimento até</label>
                <input-control v-model="filter.vencimentoAte" placeholder="Vencimento" type="date" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-3 form-group" v-if="fullFilter">
                <label>Pago de</label>
                <input-control v-model="filter.pagoemDe" placeholder="Pago em" type="date" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-3 form-group" v-if="fullFilter">
                <label>Pago até</label>
                <input-control v-model="filter.pagoemAte" placeholder="Pago em" type="date" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-4 form-group" v-if="fullFilter">
                <label>Categoria loja</label>
                <select-control v-model="filter.categoria" :options="categoriasLoja" placeholder="Selecione" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-4 form-group" v-if="fullFilter">
                <label>Unidades</label>
                <select-control v-model="filter.idUnidade" :options="unidades" placeholder="Selecione" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-4 form-group" v-if="fullFilter">
                <label>Período Letivo</label>
                <select-control v-model="filter.periodoLetivo" :options="periodosLetivos" placeholder="Selecione" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-4 form-group" v-if="fullFilter">
                <label>Turmas</label>
                <select-control v-model="filter.turma" :options="turmas" placeholder="Selecione" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-4 form-group" v-if="fullFilter">
                <label>Produtos</label>
                <select-control v-model="filter.produto" :options="produtos" placeholder="Selecione" />
              </div>
            </transition>
            <transition name="fadein">
              <div class="col-md-4 form-group" v-if="fullFilter">
                <label>Status</label>
                <select-control v-model="filter.status" :options="statuses" placeholder="Selecione" />
              </div>
            </transition>
            <div class="col-md-12">
              <a href="#" @click.prevent="toggleFullFilter()" class="btn link-icon toggleFullFilter">
                <template v-if="fullFilter">
                  <fa :icon="['far','magnifying-glass-minus']" class="icon" />
                  <p>Busca Simples</p>
                </template>
                <template v-else>
                  <fa :icon="['far','magnifying-glass-plus']" class="icon" />
                  <p>Busca Avançada</p>
                </template>
              </a>
            </div>
            <div class="col-md-12 form-actions form-group">
              <a href="#" class="btn btn-primary" @click.prevent="runFilter()">
                Filtrar
              </a>
              <a href="#" class="btn btn-secondary" @click.prevent="clear()">
                Limpar
              </a>
            </div>
          </div>
        </form>
      </div>
      <section class="collapseList">
        <ul>
          <!-- <label>
            <fa :icon="['fas', 'user-tie']" class="icon"/>
            {{ item.checkouts[0].nome.toUpperCase() }}
          </label> -->
          <li v-for="(checkout,i) in list" :key="checkout" @click="!preventParentClick ? toggleCollapse(true, i, checkout) : null" :class="{ active: checkout.collapse }">
            <card :boxed="true">
              <div>
                <header :class="{ canceled: checkout.deleted_at }">
                  <div>
                    <span :class="{active: checkout.collapse}" @mouseenter="preventParentClick = true" @mouseleave="preventParentClick = false" @click="toggleCollapse(false, i, checkout)"></span>
                  </div>
                  <span>
                    {{ checkout.idcheckoutMarketplace }}
                  </span>
                  <span>
                    {{ formatDate(checkout.created_at) }}
                  </span>
                  <span class="large-cell">
                    {{ checkout.descricao }}
                  </span>
                  <span>
                    {{ checkout.descricaoPeriodoLetivo }}
                  </span>
                  <span class="large-cell">
                    {{ checkout.nome }}
                  </span>
                  <span class="fixed-cell">
                    {{ checkout.cpf }}
                  </span>
                  <span class="fixed-cell">
                    {{ checkout.email }}
                  </span>
                  <span class="text-center fixed-cell">
                    {{ checkout.sigla }}
                  </span>
                  <span class="text-center fixed-cell">
                    {{ formatMoney(checkout.valorvenda) }}
                  </span> 
                  <span class="text-right fixed-cell">
                    {{ checkout.totalVendas }} {{ checkout.totalVendas == 1 ? 'venda' : 'vendas' }}
                  </span> 
                  <span class="text-right fixed-cell">
                    {{ checkout.totalTransacoes }} {{ checkout.totalTransacoes == 1 ? 'transação' : 'transações' }}
                  </span>
                  <div class="alerts">
                    <!-- <fa :icon="['fas', 'circle-dollar']" :class="['icon', getPago(checkout.transacoes)]"/>
                    <fa :icon="['fas', 'triangle-exclamation']" :class="['icon icon-error', { active: checkout.cartao == 0 && checkout.parcelas > checkout.transacoes.length }]"/> -->
                    <fa :icon="['fas', 'credit-card']" :class="['icon', { active: !checkout.deleted_at && checkout.cartao }]" v-if="checkout.cartao"/>
                    <fa :icon="['fas', 'rectangle-barcode']" :class="['icon', { active: !checkout.deleted_at && !checkout.cartao && !checkout.pix }]" v-if="!checkout.cartao && !checkout.pix "/>
                    <fa :icon="['fas', 'qrcode']" :class="['icon', { active: !checkout.deleted_at && checkout.pix }]" v-if="checkout.pix"/>
                    <fa :icon="['fas', 'ban']" class="icon color-primary" v-if="checkout.deleted_at"/>
                  </div>
                </header>
                <transition name="slidedown-small">
                  <div class="checkoutDetails" v-if="checkout.collapse">
                    <input-spinner class="detailsSpinner" v-if="checkout.loadingDetails" />
                    <template v-else>
                      <div class="sectionActions">
                        <span>
                          <h4>Ações</h4>
                        </span>
                        <div>
                          <a href="#" class="btn btn-primary" @click.prevent="toggleGerarPedido(checkout)" v-if="canGerarPedido">
                            Gerar Pedido &nbsp;
                            <fa :icon="['fas', 'conveyor-belt']"/>
                          </a>
                          <!-- <a href="#" class="btn btn-primary" @click.prevent="toggleGerarPedidoRetroativo(checkout)" v-if="canGerarPedido">
                            Gerar Pedido &nbsp;
                            <fa :icon="['fas', 'conveyor-belt-boxes']"/>
                          </a> -->
                          <a 
                            href="#"
                            :class="['btn btn-primary refundActions', { off: !canExclude(checkout) }]"
                            v-if="canRefund && !checkout.deleted_at"
                            @click.prevent="refundCancel(checkout, 'total')"
                            :title="canExclude(checkout) ? 'Excluir e estornar compra por completo' : 'Esta compra não pode ser excluída.' "
                          >
                          <template v-if="!canExclude(checkout) && !checkout.deleted_at">
                            <fa :icon="['fas','lock-keyhole']"></fa>
                            &nbsp;
                          </template>
                          Excluir Compra
                          </a>
                          <!-- <a href="#" class="btn btn-primary" v-if="allowRefund(checkout) && canRefund" @click.prevent="refundCancel(checkout, 'parcial')">Estornar Itens</a> -->
                          <a href="#" :class="['btn btn-secondary', { disabled: waitingPostback }]" @click.prevent="postback(checkout)">
                            <fa :icon="['fas','arrows-rotate']"></fa> Sincronizar Pagamentos
                          </a>
                        </div>
                      </div>
                      <div class="checkoutDetailsLists">
                        <div>
                          <h5>Vendas</h5>
                          <ul class="fixed">
                            <li v-for="venda in checkout.data.vendas" :key="venda" :class="{ 
                              canceled: checkCancelado(venda, 'c'),
                              deleted: checkCancelado(venda, 'd')
                            }">
                              <template v-if="venda.itensComprados.length > 0">
                                <span class="cancel_tag" v-if="venda.itensComprados[0].itens[0].deleted_at">
                                  Item Removido
                                </span>
                                <span class="cancel_tag" v-if="venda.itensComprados[0].itens[0].canceladoEm">
                                  Item Cancelado <template v-if="venda.itensComprados[0].itens[0].idSolicitacaoCancelamento">- {{ venda.itensComprados[0].itens[0].idSolicitacaoCancelamento }}</template>
                                </span>
                              </template>
                              <template v-else>
                                <span class="cancel_tag" v-if="venda.deleted_at">
                                  Item Removido
                                </span>
                                <span class="cancel_tag" v-if="venda.canceladoEm">
                                  Item Cancelado <template v-if="venda.idSolicitacaoCancelamento">- {{ venda.idSolicitacaoCancelamento }}</template>
                                </span>
                              </template>
                              
                              <div>
                                <span v-if="venda.itensComprados.length > 0" class="loose">
                                  {{ venda.idvendamaterial }} - {{ venda.itensComprados[0].descricaoGrade ? venda.descricaovenda + ' - ' + venda.itensComprados[0].descricaoGrade : venda.descricaovenda}}
                                </span>
                                <span v-else>{{ venda.idvendamaterial }} - {{ venda.descricaovenda }}</span>
                                <span>{{ formatMoney(venda.valorvenda) }}</span>
                              </div>
                              <span class="student">
                                <fa :icon="['fas', 'user-graduate']" />
                                <p>{{ venda.nome }}</p>
                              </span>
                              <template v-if="venda.itensComprados.length >= 1">
                                <ol class="itensComprados" v-if="venda.itensComprados[0].itens.length >= 1">
                                  <li v-for="item in venda.itensComprados[0].itens" :key="item">
                                    <div class="deliver">
                                      <fa :icon="['fas', 'clipboard-check']" :class="['icon', { active: item.idsaidaitemmaterial }]" />
                                      <span v-if="item.idsaidaitemmaterial">Entregue em <b>{{ formatDate(item.datasaida) }}</b></span>
                                    </div>
                                    <div>
                                      <span v-if="item.descricaoGrade">{{ item.descricao + ' - ' + item.valorItem}}</span>
                                      <span v-else>{{ item.descricao }}</span>
                                      <span>{{ formatMoney(item.valorItem) }}</span>
                                    </div>
                                  </li>
                                </ol>
                              </template>
                              <div class="splitRules" v-if="venda.itensComprados.length > 0 ? !venda.itensComprados[0].itens[0].deleted_at : !venda.deleted_at">
                                <div><fa :icon="['far', 'shuffle']" class="icon"/></div>
                                <div class="splitChart">
                                  <div>
                                    <span class="" v-for="split in venda.splitPagamento" :key="split" :style="{ width: split.percentual + '%' }">
                                      <p>{{ formatMoney(split.valor) }}</p>
                                    </span>
                                  </div>
                                  <div>
                                    <p v-for="split in venda.splitPagamento" :key="split">
                                      {{ split.descricao + ' (' + split.percentual + '%)'}}   
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>                  
                        <div v-if="checkout.planoAgrupado == 1">
                          <div class="boletaoAlert">
                            <fa :icon="['fas','triangle-exclamation']" class="icon"></fa>
                            <h5>Venda sem transações. Compra efetuada com forma de pagamento integrado.</h5>
                          </div>
                        </div>
                        <div v-else>
                          <div class="sectionHeader">
                            <h5>Transações</h5>
                            <!-- <div>
                              <a href="#" class="btn btn-primary" @click.prevent="gerarPedido(checkout)" v-if="canGerarPedido">
                                Gerar Pedido &nbsp;
                                <fa :icon="['fas', 'conveyor-belt']"/>
                              </a>
                              <a 
                                href="#"
                                :class="['btn btn-primary refundActions', { off: !canExclude(checkout) }]"
                                v-if="canRefund && !checkout.deleted_at"
                                @click.prevent="refundCancel(checkout, 'total')"
                                :disabled="!canExclude(checkout)"
                                :title="canExclude(checkout) ? 'Excluir e estornar compra por completo' : 'Esta compra não pode ser excluída.' "
                              >
                              <template v-if="!canExclude(checkout) && !checkout.deleted_at">
                                <fa :icon="['fas','lock-keyhole']"></fa>
                                &nbsp;
                              </template>
                              Excluir Compra
                              </a>
                              <a href="#" class="btn btn-primary" v-if="allowRefund(checkout) && canRefund" @click.prevent="refundCancel(checkout, 'parcial')">Estornar Itens</a>
                              <a href="#" :class="['btn btn-secondary', { disabled: waitingPostback }]" @click.prevent="postback(checkout)">
                                <fa :icon="['fas','arrows-rotate']"></fa> Sincronizar Pagamentos
                              </a>
                            </div> -->
                          </div>
                          <div v-if="waitingPostback">
                            <input-spinner class="detailsSpinner" />
                          </div>
                          <table v-else>
                            <thead>
                              <tr>
                                <th v-if="canUpdate || canDelete"></th>
                                <th>ID</th>
                                <th>ID Meio Pagamento</th>
                                <th>Valor</th>
                                <th>Parcela</th>
                                <th>Vencimento</th>
                                <th>Status</th>
                                <th>Valor Pago</th>
                                <th>Pago em</th>
                                <!-- <th></th> -->
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="transacao in checkout.data.transacoes.itens" :key="transacao" :class="{ cancelado: transacao.cancelado == 1 || transacao.deleted_at }">
                                <td v-if="canDelete || canUpdate">
                                  <template v-if="transacao.cancelado != 1 && !transacao.deleted_at ">
                                    <a href="#" target="_blank" @click.prevent="editTransacao(transacao)" v-if="transacao.cartao == 0 && canUpdate">
                                      <fa :icon="['far', 'pencil']" />
                                    </a>
                                    <a href="#" target="_blank" @click.prevent="confirmRemove(transacao)" v-if="canDelete">
                                      <fa :icon="['far', 'trash-can']" />
                                    </a>
                                  </template>
                                </td>
                                <td>{{ transacao.idtransacaovenda }}</td>
                                <td>{{ transacao.idtransacao }}</td>
                                <td>{{ formatMoney(transacao.valortransacao) }}</td>
                                <td>{{ transacao.parcela ? transacao.parcela : 1 }}</td>
                                <td>{{ transacao.vencimentoboleto ? formatDate(transacao.vencimentoboleto) : '-' }}</td>
                                <td :title="transacao.status">{{ getStatusTransacao(transacao) }}</td>
                                <td>{{ transacao.valorpago ? formatMoney(transacao.valorpago) : '-' }}</td>
                                <!-- <td>{{ transacao.pagoem && transacao.pago == 1 ? formatDate(transacao.pagoem) : '-' }}</td> -->
                                <td>{{ transacao.pago == 1 ? ( transacao.atualizadoem ? formatDate(transacao.atualizadoem) : '-' ) : '-' }}</td>
                                <!-- <td>
                                  <fa :icon="['far','user-pen']" v-if="transacao.editado == 1"></fa>
                                </td> -->
                                <td class="text-center">
                                  <a :href="transacao.boletourl" target="_blank" v-if="transacao.boletourl && transacao.cancelado == 0 || transacao.boletourl && !transacao.deleted_at">
                                    <fa :icon="['fas', 'rectangle-barcode']" />
                                  </a>
                                  <a href="#" @click.prevent="" class="infoButton" target="_blank" v-else-if="transacao.cancelado == 1 && transacao.obs || transacao.deleted_at && transacao.obs ">
                                    <fa :icon="['fas', 'circle-info']" />
                                    <div v-if="transacao.obs" :style="{ width: (( transacao.obs.length * 7 ) + 30 ) + 'px' }">
                                      <p>{{ transacao.obs }}</p>
                                    </div>
                                  </a>
                                  <template v-else-if="transacao.cartao == 0 && transacao.boleto == 0">
                                    <fa :icon="['fas', 'qrcode']" />
                                  </template>
                                  <template v-else>
                                    <fa :icon="['fas', 'credit-card']" />
                                  </template>
                                </td>
                              </tr>
                              <tr v-if="checkout.data.transacoes.voucher.length > 0" class="no-hover">
                                <td :colspan="canUpdate || canDelete ? 10 : 9">
                                  <table class="voucher-details">
                                    <tr v-for="voucher in checkout.data.transacoes.voucher" :key="voucher">
                                      <td class="">
                                        <fa :icon="['fas','ticket']"></fa>
                                        &nbsp;Compra com voucher
                                      </td>
                                      <td>{{ voucher.codigoVoucher }}</td>
                                      <td>Valor do Voucher: <b>{{ voucher.porcentagem ? ( voucher.porcentagem + '%') : formatMoney(voucher.valor) }}</b></td>
                                      <td>Valor do Desconto: <b>{{ voucher.porcentagem ? formatMoney(calcVoucherValue(voucher) - voucher.valorvenda) : formatMoney(voucher.valor) }}</b></td>
                                      <td>Valor Original: <b>{{ formatMoney(calcVoucherValue(voucher)) }}</b></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr class="totais">
                                <td colspan="3"></td>
                                <td>
                                  <b>{{ formatMoney(getTotal('valortransacao', checkout.data.transacoes.itens)) }}</b>
                                </td>
                                <td colspan="3"></td>
                                <td>
                                  <b>{{ formatMoney(getTotal('valorpago', checkout.data.transacoes.itens)) }}</b>
                                </td>
                                <td colspan="2"></td>
                              </tr>
                            </tbody>
                            <tfoot v-if="canInsert">
                              <tr>
                                <th colspan="10">
                                  <a href="#" @click.prevent="addTransacao(checkout)">
                                    <fa :icon="['fas', 'hexagon-plus']" class="icon"/>
                                    Adicionar Transação
                                  </a>
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                          <!-- <ul>
                            <li v-for="transacao in checkout.data.transacoes" :key="transacao">
                              <span>{{ transacao.descricao }}</span>
                              <span>{{ formatMoney(venda.valorvenda) }}</span>
                            </li>
                          </ul> -->
                        </div>
                      </div>
                    </template>
                  </div>
                </transition>
              </div>
            </card>
          </li>
        </ul>
      </section>
    </div>
    <modal ref="modal">
      <div>
        <div class="row no-padd" v-if="remove">
          <div class="col-md-12 form-group no-padd-left">
            <p><b>Atenção!</b> Esta ação não pode ser desfeita.</p>
          </div>
        </div>
        <div class="row no-padd" v-if="!remove">
          <div class="col-md-3 form-group no-padd-left">
            <label>ID Meio Pagamento</label>
            <input-control v-model="edit.idtransacao" placeholder="0000000000" type="number" cssClass="invert"/>
          </div>
          <div class="col-md-3 form-group">
            <label>Parcela</label>
            <input-control v-model="edit.parcela" placeholder="Parcela" type="number" cssClass="invert"/>
          </div>
          <div class="col-md-3 form-group">
            <label>Valor</label>
            <input-control v-model="edit.valortransacao" placeholder="Valor" type="number" step=".01" cssClass="invert" @change="changeValorOuVencimento()"/>
            <span class="form-info">*Pontue apenas centavos (ex. 2990,45)</span>
          </div>
          <div class="col-md-3 form-group no-padd-right">
            <label>Vencimento</label>
            <input-control v-model="edit.vencimentoboleto" placeholder="Vencimento" type="date" cssClass="invert" @change="changeValorOuVencimento()"/>
          </div>
        </div>
        <div class="row no-padd">
          <div class="col-md-4 form-group no-padd-left" v-if="!remove">
            <label>Pago</label>
            <div class="form-group">
              <label>
                <input type="radio" v-model="edit.pago" :value="1">
                Sim
              </label>
              <label>
                <input type="radio" v-model="edit.pago" :value="0">
                Não
              </label>
            </div>
          </div>
          <div class="col-md-4 form-group" v-if="!remove">
            <label>Valor Pago</label>
            <input-control v-model="edit.valorpago" placeholder="Valor Pago" type="number" step=".01" cssClass="invert" @change="fillPago"/>
            <span class="form-info">*Pontue apenas centavos (ex. 2990,45)</span>
          </div>
          <div class="col-md-4 form-group no-padd-right" v-if="!remove">
            <label>Pago em</label>
            <input-control v-model="edit.pagoem" placeholder="Pago em" type="date" cssClass="invert" @change="fillPago"/>
          </div>
          <div :class="['col-md-12 form-group no-padd-right',{'no-padd-left': remove }]">
            <label>Observação</label>
            <textarea-control v-model="edit.obs" placeholder="Ex. Motivo da alteração" cssClass="invert"/>
          </div>
          <div class="col-md-12 form-group checkbox-wrap" v-if="!remove">
            <!-- <input type="checkbox" v-model="edit.regerar" id="regerar" @change="trackRegerar"/>
            <label for="regerar">Regerar transações no meio de pagamento</label> -->
            <checkbox-control v-model="edit.regerar" id="regerar" @change="trackRegerar" iconPos="left">(Re)gerar transações no meio de pagamento</checkbox-control>
          </div>
        </div>
        <div class="form-actions">
          <a href="#" class="btn btn-primary" @click.prevent="removeTransacao(true, edit)" v-if="remove">Remover</a>
          <a href="#" class="btn btn-primary" @click.prevent="saveTransacao()" v-else>Salvar</a>
          <a href="#" class="btn btn-secondary" @click.prevent="closeModal()">Cancelar</a>
        </div>
      </div>
    </modal>
    <modal ref="modalEstorno">
      <div v-if="refunding" class="estornoSpinner">
        <input-spinner class="detailsSpinner" />
      </div>
      <div ref="refundForm" v-else>
        <div class="row no-padd">
          <div class="col-md-12 form-group no-padd-left">
            <p><b>Atenção!</b> Esta ação não pode ser desfeita.</p>
          </div>
        </div>
        <div class="row no-padd" v-if="refund.tipo === 'parcial'">
          <div class="col-md-12 form-group no-padd-left">
            <h6 class="color-text">Selecione os itens que deseja extornar</h6>
            <ul class="refundList">
              <li v-for="item in refundListItens" :key="item">
                <label :class="['check-control', { disabled: diferencaSelecionado < item.valorItem && !refund.idsItens.includes(item.iditemlistavenda) || item.estornado }]" v-if="item.valorItem">
                  <input type="checkbox" class="check" :value="item.iditemlistavenda" v-model="refund.idsItens" @change="selectItemForRefund()"/>
                  <span v-if="item.itens && item.itens.length > 0">
                    {{ item.itens[0].descricao }} - <b>{{ formatMoney(item.valorItem) }}</b>
                  </span>
                  <span v-else>
                    {{ item.descricaovenda ? ( item.descricaovenda + ( item.descricaoGrade ? ' ' + item.descricaoGrade : '' ) ) : item.descricao }} - <b>{{ formatMoney(item.valorItem) }}</b>
                  </span>
                  <fa :icon="['fas', 'clipboard-check']" class="color-success" :title="formatDate(item.datasaida)" v-if="item.idsaidaitemmaterial"></fa>
                </label>
              </li>
              <li v-for="item in refundListVendas" :key="item">
                <label :class="['check-control', { disabled: diferencaSelecionado < item.valorItem }]" v-if="item.valorItem">
                  <input type="checkbox" class="check" :value="item.iditemlistavenda" v-model="refund.idsItens" @change="selectItemForRefund()"/>
                  <span>{{ item.descricaovenda ? ( item.descricaovenda + ( item.descricaoGrade ? ' ' + item.descricaoGrade : '' ) ) : item.descricao }} - <b>{{ formatMoney(item.valorItem) }}</b></span>
                  <fa :icon="['fas', 'clipboard-check']" class="color-success" :title="formatDate(item.datasaida)" v-if="item.idsaidaitemmaterial"></fa>
                </label>
              </li>
            </ul>
            <div>
              <ul class="refundFooter">
                <li class="pago">
                  <p>Pago</p>
                  <h4 class="color-text">{{ formatMoney(getTotalPago()) }}</h4>
                </li>
                <li class="selecionado">
                  <p>Selecionado</p>
                  <h4 class="color-text">{{ formatMoney(totalSelecionado) }}</h4>
                </li>
                <li class="resto">
                  <p>Restante</p>
                  <h4 class="color-text">{{ formatMoney(diferencaSelecionado) }}</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <template v-if="refund.tipoPagamento == 'boleto' && notPayed(refund.checkout)">
          <div class="row no-padd">
            <div class="col-md-2 form-group no-padd-left">
              <label>Código banco</label>
              <input-control v-model="refund.bank_account.bank_code" placeholder="0000" name="bank_code" type="number" :valid="refundValid.bank_code" @keyup="refundValid.bank_code = true" cssClass="invert" required/>
            </div>
            <div class="col-md-4 form-group no-padd-left">
              <a href="#" class="btBancos" target="_blank">Consulte a lista de bancos aqui</a>
            </div>
          </div>
          <div class="row no-padd">
            <div class="col-md-2 form-group no-padd-left">
              <label>Agência</label>
              <input-control v-model="refund.bank_account.agencia" name="agencia" :valid="refundValid.agencia" @keyup="refundValid.agencia = true" placeholder="0000" type="number" cssClass="invert" required/>
            </div>
            <div class="col-md-1 form-group">
              <label>Dígito</label>
              <input-control v-model="refund.bank_account.agenciaDV" name="agenciaDV" placeholder="0" type="text" cssClass="invert" />
            </div>
            <div class="col-md-2 form-group">
              <label>Conta</label>
              <input-control v-model="refund.bank_account.conta" name="conta" :valid="refundValid.conta" @keyup="refundValid.conta = true" placeholder="0000" type="number" cssClass="invert" required/>
            </div>
            <div class="col-md-1 form-group">
              <label>Dígito</label>
              <input-control v-model="refund.bank_account.contaDV" name="contaDV" placeholder="0" type="text" cssClass="invert" />
            </div>
         </div>
        </template>
        <div class="row no-padd" v-else-if="refund.tipoPagamento == 'cartao'">
          <div class="col-md-12 form-group no-padd-left">
            <p>Esta compra será estornada para o cartão de crédito utilizado na compra.</p>
          </div>
        </div>
        <div class="row no-padd" v-else>
          <div class="col-md-12 form-group no-padd-left">
            <p>Esta compra não tem transações pagas para estorno.</p>
          </div>
        </div>
        <div class='row no-padd'>
          <div class="col-md-12 form-group">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="refund.cancelamento"/>
              <span>Cancelamento/Devolução (Esta ação não remove o item, apenas marca como cancelado)</span>
            </label>
          </div>
        </div>
        <div class="row no-padd">
          <div class="col-md-12 form-group no-padd-right no-padd-left">
            <label>Observação</label>
            <textarea-control v-model="refund.motivoEstorno" name="motivoEstorno" :valid="refundValid.motivoEstorno" @keyup="refundValid.motivoEstorno = true" placeholder="Ex. Motivo da alteração" cssClass="invert" required/>
          </div>
        </div>
        <div class="form-actions">
          <a href="#" class="btn btn-primary" @click.prevent="refundSubmit()">Remover</a>
          <a href="#" class="btn btn-secondary" @click.prevent="closeEstornoModal()">Cancelar</a>
        </div>
      </div>
    </modal>
    <modal ref="modalPedido">
      <div class="row nop nom">
        <div class="form-group col-md-12 nop nom">
          <label class="check-control">
            <input type="checkbox" class="check" v-model="pedido.retroativo"/>
            <span>Retroativo</span>
          </label>
        </div>
        <!-- <div class="form-group col-md-12 nop nom">
          <label class="check-control disabled">
            <input type="checkbox" class="check" v-model="pedido.nota"/>
            <span>Emitir Nota Fiscal</span>
          </label>
        </div>
        <div class="form-group col-md-12 nop nom">
          <label class="check-control disabled">
            <input type="checkbox" class="check" v-model="pedido.recebimento"/>
            <span>Baixar Recebimento</span>
          </label>
        </div>
        <div class="form-group col-md-12 nop nom">
          <label class="check-control disabled">
            <input type="checkbox" class="check" v-model="pedido.entrega"/>
            <span>Baixar Entrega</span>
          </label>
        </div> -->
      </div>
      <div class="action-wrap">
        <a href="#" class="btn btn-primary" @click.prevent="confirmGerarPedido()">
          Gerar Pedido(s)
        </a>
      </div>
    </modal>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </controller>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service'
import FormService from '@/services/form.service'
import UserService from '@/services/user.service'
import { formatMoney, formatDate } from '@/services/utils.service'
import InputSpinner from '@/components/utilities/InputSpinner'
import Modal from '@/components/utilities/Modal'
import moment from 'moment'

export default {
  name: 'Vendas',
  components: {
    InputSpinner, Modal
  },
  data () {
    return {
      loading: false,
      tableLoading: false,
      fullFilter: false,
      filter: {
        nome: null,
        vencimentoDe: null,
        vencimentoAte: null,
        pagoemDe: null,
        pagoemAte: null,
        categoria: null,
        idUnidade: null,
        periodoLetivo: null,
        turma: null,
        produtos: null,
        status: null,
        cancelado: null,
        idVendaMaterial: null,
        idTransacao: null,
        idTransacaoMeioPagamento: null,
        pago: null,
      },
      categoriasLoja: [],
      unidades: [],
      periodosLetivos: [],
      turmas: [],
      produtos: [],
      statuses: [],
      list: [],
      preventParentClick: false,
      edit: null,
      backup: null,
      reload: {},
      canDelete: false,
      canInsert: false,
      canUpdate: false,
      canRefund: false,
      canPostback: false,
      remove: false,
      refunding: false,
      parcial: false,
      refund: {
        tipo: null,
        idsItens: [],
        idsVendas: [],
        idcheckoutMarketplace: null,
        checkout: null,
        motivoEstorno: null,
        tipoPagamento: null,
        cancelamento: null,
        bank_account: {
          bank_code: null,
          agencia: null,
          agenciaDV: null,
          conta: null,
          contaDV: null
        }
      },
      refundValid: {
        bank_code: true,
        agencia: true,
        conta: true,
        motivoEstorno: true
      },
      refundListItens: [],
      refundListVendas: [],
      totalSelecionado: 0,
      diferencaSelecionado: 0,
      waitingPostback: false,
      iterateGerarPedido: 0,
      refunded: [],
      canGerarPedido: false,
      pedido: {
        checkout: null,
        idCheckout: null,
        retroativo: null,
        nota: null,
        recebimento: null,
        entrega: null
      }
    }
  },
  mounted () {
    this.canDelete = UserService.filterPermissions('deleteTransacaoVenda')
    this.canInsert = UserService.filterPermissions('insertTransacaoVenda')
    this.canUpdate = UserService.filterPermissions('updateTransacaoVenda')
    this.canRefund = UserService.filterPermissions('estornaCompra')
    this.canPostback = UserService.filterPermissions('forcePostbackPagarme')
    this.canGerarPedido = UserService.filterPermissions('gerarPedidoVenda')
  }, 
  methods: {
    toggleFullFilter () {
      this.fullFilter = !this.fullFilter
    },
    runFilter () {
      const empty = Object.values(this.filter).every(e => !e)
    
      if ( empty ) {
        window.toaster.emit('open', {style: "warning", message: "Preencha ao menos um campo, para filtrar.", floater: false})
      } else {
        this.reload = {}
        this.loading = true
        window.spinner.emit("open")
        MarketplaceService.filterVendasTransacoes(this.filter).then(
          response => {
            console.log("response:", response.data)
            if ( Object.keys(response.data).length > 0 ) {
              this.list = []

              response.data.data.map( k => {
                k.collapse = false
                k.loadingDetails = false
                k.data = {}
                this.list.push(k)
              })
              // this.list = response.data.data
              // console.log("this.list:", this.list)
            } else {
              window.toaster.emit('open', {style: "warning", message: "Ops. Não econtramos vendas com os dados inseridos.", floater: false})
            }

            this.loading = false
            window.spinner.emit("close")
          },
          error => {  
            console.error("error:", error.response)
            this.loading = false
            window.spinner.emit("close")
          }
        )
      }
    },
    toggleCollapse ( collapse, index, checkout, force ) {
      // console.log("collapse:", collapse)
      // console.log("index:", index)
      // console.log("checkout:", checkout)
      // console.log("force:", force)

      const target = this.list[index]

      if ( !target.collapse && collapse || force ) {
        this.reload = {
          collapse: collapse,
          index: index,
          checkout: checkout
        }
        // console.log("force:", force)
        target.collapse = collapse
        target.loadingDetails = true
        MarketplaceService.getVendasTransacoesDetails( checkout.idcheckoutMarketplace ).then(
          response => {
            target.loadingDetails = false
            target.data.vendas = response.data.vendas
            target.data.transacoes = response.data.transacoes

            // target.data.transacoes[4].obs = "Etiam at erat molestie neque ultricies dignissim. Vestibulum sapien odio, sagittis nec hendrerit eget, vestibulum at ipsum. Phasellus nec volutpat justo, sed faucibus leo. Mauris sodales lacinia viverra."
            // console.log("getVendasTransacoesDetails:", response)
          },
          error => {
            console.error('error:', error.response)
            target.loadingDetails = false
          }
        )
      } else {
        target.collapse = collapse
      }
    },
    clear () {
      this.filter = {
        nome: null,
        vencimentoDe: null,
        vencimentoAte: null,
        pagoemDe: null,
        pagoemAte: null,
        categoria: null,
        idUnidade: null,
        periodoLetivo: null,
        turma: null,
        produtos: null,
        status: null,
        cancelado: null,
        idVendaMaterial: null,
        idTransacao: null,
        idTransacaoMeioPagamento: null,
        pago: null,
      }

      this.categoriasLoja = []
      this.unidades = []
      this.periodosLetivos = []
      this.turmas = []
      this.produtos = []
      this.statuses = []
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    getStatusTransacao ( transacao ) {
      // console.log("getStatusTransacao:", transacao)

      const due = moment().diff(moment(transacao.vencimentoboleto, 'YYYY-MM-DD'), 'days')
      const pago = transacao.pago == 1
      const cancelado = transacao.cancelado == 1 || transacao.deleted_at
      const expired = due > 0 && !transacao.deleted_at

      if ( pago ) {
        return 'Pago'
      } else if ( expired ) {
        return 'Vencido'
      } else if ( cancelado ) {
        return 'Cancelado'
      } else {
        return 'Pendente'
      }
    },
    getPago ( transacoes ) {
      // console.log("transacoes:", transacoes)
      const total = transacoes.length
      const pago = transacoes.filter ( k => {
        return k.pago == 1
      }).length

      const expired =  transacoes.filter ( k => {
        const due = moment().diff(moment(k.vencimentoboleto, 'YYYY-MM-DD'), 'days')
        return k.pago == 0 && due > 0
      })

      // console.log("expired:", expired)
      var status
      if ( total == pago ) {
        status = 'payed'
      } else if ( expired.length > 0 ) {
        status = 'expired'
      } else {
        status = 'waiting'
      }

      return status
      // console.log("status:", status)
      // console.log("pago:", pago.length)
    },
    createBackup ( data ) {
      const backup = {}

      Object.keys(data).map(k => {
        backup[k] = data[k]
      })

      this.backup = backup
    },
    editTransacao ( transacao ) {
      this.remove = false
      this.edit = {
        ...transacao,
        regerar: false
      }

      this.createBackup(transacao)

      // console.log("edit transacao", this.edit)
      const options = {
        title: 'Editar Transação ' + transacao.idtransacaovenda,
        titleAlign: "left",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: false, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
    },
    confirmRemove ( transacao ) {
      this.edit = {
        idtransacaovenda: transacao.idtransacaovenda,
        obs: null
      }
      this.createBackup(transacao)
      this.remove = true

      const options = {
        title: 'Remover Transação ' + transacao.idtransacaovenda,
        titleAlign: "left",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: false, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
      window.pagescroll.emit("lock")
    },
    removeTransacao ( state, transacao ) {
      // console.log("state:", state)
      // console.log("transacao:", transacao)
      // console.log("obs:", this.edit.obs)
      window.pagescroll.emit("unlock")

      if ( state ) {
        this.loading = true
        window.spinner.emit("open")
        this.$refs.modal.close()

        MarketplaceService.removeTransacao( transacao.idtransacaovenda, this.edit.obs ).then(
        response => {
          console.log('removeTransacao:', response)
          
          this.loading = false
          window.spinner.emit("close")

          const collapse = this.reload.collapse
          const index = this.reload.index
          const checkout = this.reload.checkout

          this.toggleCollapse(collapse, index, checkout, true)
        },
        error => {
          console.error(error)
          this.loading = false
          window.spinner.emit("close")
        }
      )
      }
      
    },
    fillPago () {
      // console.log("this.edit:", this.edit)
      if ( this.edit.pagoem || this.edit.valorpago !== '0,00' ) {
        // console.log("pagoem e valor ok...")
        this.edit.pago = 1

        // console.log("this.edit:", this.edit)
      }
    },
    changeValorOuVencimento () {
      // console.log("this.backup:", this.backup)
      // console.log("this.edit:", this.edit)
      const changedValue = this.backup?.valortransacao != this.edit.valortransacao
      const changedVencimento = this.backup?.vencimentoboleto != this.edit.vencimentoboleto

      // console.log("changedValue:", changedValue)
      // console.log("changedVencimento:", changedVencimento)

      if ( changedValue || changedVencimento ) {
        this.edit.regerar = true
      }
    },
    saveTransacao () {
      if ( this.edit ) {
        this.loading = true
        window.spinner.emit("open")
        this.$refs.modal.close()
        window.pagescroll.emit("unlock")
        // const valid = this.validate()

        MarketplaceService.editTransacao(this.backup,this.edit).then(
          response => {
            // console.log(response.data)
            if ( response.data ) {
              this.loading = false
              window.spinner.emit("close")

              // console.log('reload: ',this.reload)
              const collapse = this.reload.collapse
              const index = this.reload.index
              const checkout = this.reload.checkout

              this.toggleCollapse(collapse, index, checkout, true)
              // this.reload
            }
          },
          error => {
            console.error(error.response)
            this.loading = false
            window.spinner.emit("close")
            const msg = JSON.parse(error.response.data.message).errors[0]
            // console.log('msg:', msg)
            window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu! " + msg.type + " - " + msg.parameter_name + ": " + msg.message , floater: false})
          }
        )
      }
      
    },
    addTransacao ( checkout ) {
      // console.log('checkout:', checkout)
      this.backup = null
      this.remove = false
      this.create = true
      
      this.edit = {
        idtransacao: null,
        parcela: null,
        valortransacao: null,
        vencimentoboleto: null,
        pago: 0,
        valorpago: null,
        pagoem: null,
        idcheckoutMarketplace: checkout.idcheckoutMarketplace,
        obs: null
      }

      const options = {
        title: 'Adicionar Transação',
        titleAlign: "left",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
      window.pagescroll.emit("lock")
    },
    closeModal () {
      this.edit = false
      this.remove = false
      this.create = false
      this.$refs.modal.close()
      window.pagescroll.emit("unlock")
    },
    getTotal ( target, data ) {
      var total = 0

      if ( data && data.length > 0 ) {
        data.map( k => {
          // console.log("k:", k)
          if ( !k.deleted_at && !k.cancelado )
          total += k[target]
        })

        return total
      } else {
        return 0
      }
      
    },
    trackRegerar ( e ) {
      this.edit.regerar = e.target.value ? true : false
      // console.log(this.edit)
      // console.log(e.target.value)
      // console.log(e)
    },
    canExclude ( checkout ) {
      console.log("checkout:", checkout)
      const noPag = checkout.data.transacoes.itens.filter( k => {
        return k.pago == 0 || !k.pago
      })

      const canExclude = noPag.length == 0 || noPag.length == checkout.data.transacoes.length

      return canExclude
    },
    allowRefund ( checkout ) {
      const pag = checkout.data.transacoes.itens.filter( k => {
        return k.pago == 1
      })

      const canRefund = pag.length > 0

      return canRefund
    },
    createRefundList ( vendas ) {
      const items = []
      const itensVenda = []

      vendas.map( k => {
        // console.log("vendas - k:", k)
        // console.log("estorno:", k.estorno)
        const estorno = k.estorno

        if ( k.itensComprados ) {
          k.itensComprados.map ( kc => {
            // console.log("kc:", kc)
            if ( kc.itens.length > 1 ) {
              // console.log("kc > 1 | add itens..")
              kc.itens.map( km => {
                // console.log("km:", km)
                if ( estorno.length > 0 ) {
                  const estornado = estorno.filter( ke => {
                    // console.log("ke:", ke.iditemlistavenda)
                    return ke.iditemlistavenda == km.iditemlistavenda
                  })
                  if ( estornado.length > 0 ) {
                    km['estornado'] = true
                  }
                }

                items.push(km)
              })
              // console.log("kc > 1 - items:", items)
            } else {
              kc.valorItem = kc.valorvenda ? kc.valorvenda : kc.itens[0].valorItem
              kc.iditemlistavenda = kc.itens[0].iditemlistavenda

              // console.log("estorno:", estorno)
              if ( estorno.length > 0 ) {
                // console.log("estorno:", k.estorno)
                const iditemlistavenda = kc.iditemlistavenda
                // console.log('iditemlistavenda:', iditemlistavenda)
                const estornado = estorno.filter( ke => {
                  // console.log("ke:", ke.iditemlistavenda)
                  return ke.iditemlistavenda == iditemlistavenda
                })
                if ( estornado.length > 0 ) {
                  kc['estornado'] = true
                }
                // console.log("estornado:", estornado)
              }

              items.push(kc)
            }
          })
        } else {
          // console.log("não tem item...")
          itensVenda.push(k)
        }

        console.log("estorno:", k.estorno)

        if ( k.estorno.length > 0 ) {
          k.estorno.map ( ke => {
            const iditemlistavenda = ke.iditemlistavenda
            if ( !this.refunded.includes(iditemlistavenda) ) {
              this.refunded.push(iditemlistavenda)
            }
          })
          console.log('this.refunded:', this.refunded)

        }

      })
      
      this.refundListItens = items
      this.refundListVendas = itensVenda

      this.selectItemForRefund()
      // console.log("refundListItens:", this.refundListItens)
      // console.log("refundListVendas:", this.refundListVendas)

    },
    refundCancel ( checkout, type ) {
      // console.log("checkout:", checkout)

      const title = type === 'total' ? 'Excluir Venda ' :  'Estornar Itens '
      const options = {
        title: title + checkout.idcheckoutMarketplace,
        titleAlign: "left",
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: false, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      // this.refund.tipo = 
      this.refund.tipoPagamento = checkout.cartao == 1 ? 'cartao' : ( checkout.pix == 1 ? 'pix' : 'boleto' ) 
      this.refund.idcheckoutMarketplace = checkout.idcheckoutMarketplace
      this.refund.checkout = checkout
      this.refund.tipo = type

      window.pagescroll.emit("lock")

      if ( type === 'parcial' ) {
        this.createRefundList( checkout.data.vendas ) 
      }

      this.$refs.modalEstorno.open(options)

      // console.log("refund:", this.refund)
    },
    validateRefund () {
      const form = this.$refs.refundForm
      const valids = this.refundValid

      const validate = FormService.validate(form, valids)

      // console.log("validate:", validate)
      return validate.response
    },
    refundSubmit () {
      const valid = this.validateRefund()
      // console.log("valid:", valid)

      if ( valid ) {
        this.refunding = true

        MarketplaceService.cancelarCompra(this.refund).then(
          response => {
            // console.log("refunded..", response)
            this.refunding = false

            if ( response.status === 200 ) {
              const collapse = this.reload.collapse
              const index = this.reload.index
              const checkout = this.reload.checkout

              this.closeEstornoModal()

              setTimeout(() => {
                this.toggleCollapse(collapse, index, checkout, true)
              }, 500)
            } else {
              window.toaster.emit('open', {style: "warning", message: "Ops. Algo deu errado. Não foi possível concluir sua solicitação.", floater: true})
            }

          },
          error => {
            console.error('error:', error.response.data )
            window.toaster.emit('open', {style: "warning", message: error.response.data, floater: true})
            this.refunding = false
          }
        )
      }
      
    },
    closeEstornoModal () {
      this.$refs.modalEstorno.close()
      window.pagescroll.emit("unlock")
      this.estornoList = null
      this.waitingPostback = false
      this.refund = {
        tipo: null,
        idsItens: [],
        idsVendas: [],
        idcheckoutMarketplace: null,
        motivoEstorno: null,
        tipoPagamento: null,
        cancelamento: null,
        bank_account: {
          bank_code: null,
          agencia: null,
          agenciaDV: null,
          conta: null,
          contaDV: null
        }
      }
    },
    notPayed ( checkout ) {
      // console.log("notPayed:", checkout)
      const transacoes = checkout.data.transacoes

      if ( transacoes.length > 0 ) {
        const notPayed = transacoes.filter( k => {
          return k.pago == 0
        })

        const payed = transacoes.filter( k => {
          return k.pago == 1
        })
        // console.log("notPayed:", notPayed)
        return notPayed.length == 0 || payed.length > 1
      } else {
        return true
      }
    },
    getTotalPago () {
      var total = 0
      this.refund.checkout.data.transacoes.map ( k => {
        if ( k.pago == 1 ) {
          total += k.valorpago
        }
      })

      return total
    },
    selectItemForRefund () {
      // console.log("selectItemForRefund:", this.refund.idsItens)

      var total = 0
      const pago = this.getTotalPago()
      // console.log("pago:", pago)

      this.refundListItens.map( k => {
        const iditemlistavenda = k.iditemlistavenda
        if ( this.refund.idsItens.includes(iditemlistavenda) ) {
          total += k.valorItem
        }
        // console.log('refundList - k:', k)
      })

      const dif = Math.round((pago - total) * 100) / 100
      this.diferencaSelecionado = total === 0 ? pago : dif
      this.totalSelecionado = total

      // console.log("diferencaSelecionado:", this.diferencaSelecionado)
      // console.log("total:", this.total)
    },
    postback ( checkout ) {
      // console.log("checkout:", checkout)
      const transacoes = checkout.data.transacoes.itens
      const total = checkout.data.transacoes.itens.length
      var step = 0
      this.waitingPostback = true

      transacoes.map( k => {
        // console.log(k)
        MarketplaceService.postback( k.idtransacao ).then(
          () => {
            // console.log("response: " + i )
            // console.log("response.data", response.data)

            step++

            // console.log("step:", step)
            // console.log("total:", total)

            if ( step == total ) {
              this.waitingPostback = false

              const collapse = this.reload.collapse
              const index = this.reload.index
              const checkout = this.reload.checkout
              
              setTimeout(() => {
                this.toggleCollapse(collapse, index, checkout, true)
              }, 500)
            }
          },
          error => {
            console.error(error)
            this.waitingPostback = false
            window.toaster.emit('open', {style: "warning", message: "Ops. Algo deu errado. Não foi possível concluir sua solicitação.", floater: false})
          }
        )
      })
    },
    getAlunosVendas ( vendas ) {
      const alunos = []
      vendas.map( k => {
        const idAluno = k.idAluno
        if ( !alunos.includes(idAluno) ) {
          alunos.push(idAluno)
        }
      })

      return alunos
    },
    toggleGerarPedido ( checkout ) {
      this.pedido.checkout = checkout

      const options = {
        title: 'Gerar Pedido',
        titleAlign: "left",
        message: "Selecione abaixo as opções", //não obrigatório
        size: "small", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
        fullHeight: false,
        onClose: this.clearPedido
      }
      
      this.$refs.modalPedido.open(options)
    },
    confirmGerarPedido () {
      if ( this.pedido.retroativo ) {
        this.gerarPedidoRetroativo(this.pedido.checkout)
      } else {
        this.gerarPedido(this.pedido.checkout)
      }
    },
    gerarPedido ( checkout ) {
      const idcheckoutMarketplace = checkout.idcheckoutMarketplace
      const alunos = this.getAlunosVendas(checkout.data.vendas)
      const total = alunos.length

      alunos.map( k => {
        window.spinner.emit("open")
        MarketplaceService.gerarPedido(k, idcheckoutMarketplace, 0).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Solicitação enviada com sucesso. O Processo pode demorar alguns minutos.", floater: true})
            }
            this.iterateGerarPedido ++

            if ( total == this.iterateGerarPedido ) {
              window.spinner.emit("close")
              this.closePedidoModal()
            }
          },
          () => {
            window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível gerar o pedido.", floater: true})
            window.spinner.emit("close")
          }
        )
      })
      
    },
    gerarPedidoRetroativo ( checkout ) {
      console.log("gerarPedidoRetroativo:", this.pedido)

      const options = {
        idCheckoutMP: checkout.idcheckoutMarketplace,
        pedido: 1,
        nota: this.pedido.nota ? 1: 0,
        recebimento: this.pedido.recebimento ? 1: 0,
        entrega: this.pedido.entrega ? 1: 0
      }

      console.log("options:", options)

      window.spinner.emit("open")
      MarketplaceService.gerarPedidosRetroativos(options).then(
        response => {
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Solicitação enviada com sucesso. O Processo pode demorar alguns minutos.", floater: true})
            window.spinner.emit("close")
            this.closePedidoModal()
          }
        },
        () => {
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível gerar o pedido.", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    closePedidoModal () {
      this.$refs.modalPedido.close()
    },
    clearPedido () {
      this.pedido = {
        checkout: null,
        idCheckout: null,
        retroativo: null,
        nota: null,
        recebimento: null,
        entrega: null
      }
    },
    calcVoucherValue ( voucher ) {
      console.log("calc voucher: ", voucher)
      
      const valor = voucher.valorvenda
      const voucherType = voucher.porcentagem ? 'porcentagem' : 'valor'

      if ( voucherType == 'porcentagem' ) {
        const percent = parseFloat( voucher.porcentagem )
        const value = valor / ( 1 - ( percent / 100 ) )
 
        return value
      }

      if ( voucherType == 'valor' ) {
        const value = valor + parseFloat(voucher.valor)

        return value
      }
    },
    checkCancelado ( venda, type ) {
      if ( type == 'c' ) {
        if (  venda.itensComprados.length > 0 ) {
          return venda.itensComprados[0].itens[0].canceladoEm
        } else {
          return venda.canceladoEm
        }
      }
      if ( type == 'd' ) {
        if (  venda.itensComprados.length > 0 ) {
          return venda.itensComprados[0].itens[0].deleted_at
        } else {
          return venda.deleted_at
        }
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-actions {
    margin-top: $hmg !important;
  }

  .checkoutDetails {
    width: 100%; border-top: 1px solid $color-bg;
    padding-top: $hmg; margin-top: $hmg;
    @extend %transition_4e;

    .detailsSpinner {
      margin: auto;
    }

    > div {
      display: flex; align-content: stretch; align-items: stretch;

      &.checkoutDetailsLists {
        > div {
          &:first-child {
            width: 30%; margin-right: $mg;
          }

          &:last-child {
            flex: 2
          }

          ul {
            width: 100%; height: auto;
            padding: 0; margin: 0;

            >li {
              width: 100%; list-style: none;

              
              >div:not(.splitRules) {
                display: flex; align-content: center; align-items: center;
                justify-content: space-between;
                padding: $hmg_mini 0; border-bottom: 1px solid $color-light;
                margin: 0;

                > span:last-child {
                  text-align: right;
                }
              }
              
              .student {
                display: flex; align-content: center; align-items: center;
                width: 100%; background-color: $color-bg; padding: $hmg_mini;
                // border-bottom-left-radius: 1em; border-bottom-right-radius: 1em;
                margin-bottom: $hmg_mini; font-size: 12px; line-height: 1;
                
                p {padding: 0 0 0 $hmg_mini; margin: 0; font-size: 11px; line-height: 1}                
              }

              ol {
                padding: 0 0 0 34px; margin: 0 0 $hmg_small 0;
                border-bottom: 2px solid $color-light;

                li {
                  width: 100%;
                  list-style: decimal; padding: 3px 0;
                  margin: 0; position: relative;

                  > div:not(.deliver) {
                    justify-content: space-between; width: 100%;
                    display: flex; align-content: center; align-items: flex-start;

                    > span {
                      &:first-child {
                        white-space: normal;
                      }
                    }
                  }

                  .deliver {
                    position: absolute; top: 4px; left: -30px;

                    .icon {
                      color: $color-bg; font-size: 12px;

                      &.active {
                        color: $color-success-2
                      }
                    }

                    span {
                      line-height: 1; padding: 4px 8px;
                      position: absolute;
                      border-radius: 1em;
                      background-color: $color-success-2;
                      color: $color-secondary;
                      right: 0; top: 50%; transform: translate(100%,-50%);
                      box-shadow: 1px 2px 4px rgba(16, 122, 73, 0.3);
                      opacity: 0;
                      @extend %transition_4e;
                      pointer-events: none;
                    }

                    &:hover {
                      span {
                        transform: translate(105%, -50%); opacity: 1;
                        pointer-events: all;
                      }
                    }
                  }
                }
              }

              &.canceled, &.deleted {
                &.canceled {  background-color: $color-neutro; }
                &.deleted { background-color: $color-debito; }
                
                padding: $mg_mini;
                border-radius: $border-radius-small;

                .student {
                  background-color: $color-secondary;
                }

                ol {
                  border-bottom: 2px solid $color-secondary;

                   .deliver {
                    .icon {
                      color: $color-secondary;
                    }
                  }
                }

                .cancel_tag {
                  display: inline-flex;
                  border-radius: 2em;
                  background-color: $color-secondary;
                  padding: $hmg_mini $mg_mini;
                  margin-bottom: $mg_mini;
                  font-size: $font-size-mini !important;
                  text-transform: uppercase;
                }
              }
              

            }

            
            &.fixed {
              li {
                white-space: nowrap;
              }
            }
          }
        }

        table:not(.voucher-details) {
          width: 100%;
          border-collapse: collapse;
          border: 0;
          thead {
            th {
              border-bottom: 2px solid $color-light
            }
          }
          tbody {
            tr {
              @extend %transition_3o;

              td {
                padding: .4em;
                border-bottom: 1px solid $color-light;
                position: relative;

                &:first-child{
                  padding-left: $mg_mini;
                }

                a {
                  display: inline-block;
                  margin-left: $mg_mini;

                  &:first-child{margin-left: 0;}
                  &:hover{
                    color: $color-primary
                  }
                }
              }

              &:hover:not(.totais):not(.no-hover) {
                background-color: $color-bg;
              }

              &.over {
                background-color: $color-alert-light;

                &:hover {
                  background-color: $color-alert-light-hover;
                }
              }
              &.cancelado {
                // pointer-events: none;
                color: $color-fade;

                td {
                  // opacity: .4;
                }
              }
            }
          }
          tfoot {
            th {
              padding: $hmg_mini;
              font-size: 13px;
              border-top: 1px solid $color-bg;
              border-bottom: 1px solid $color-bg;

              a {
                .icon {
                  transform-origin: center;
                  @extend %transition_4e;
                }
                
                &:hover { 
                  color: $color-primary;
                  .icon{
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
        }

        .tableSpinner {
          width: 100%; height: 300px; 
          display: flex; align-content: center; align-items: center; justify-content: center;
          background-color: $color-bg;
        }
      }
    }
  }

  .splitRules {
    width: 100%; display: flex;
    justify-content: flex-start;

    .icon {
      display: block; margin-top: 4px;
      font-size: 11px;
    }
    >div {
      position: relative;
      
      &:first-child {padding: 0 $mg_mini 0 0;}
      &.splitChart {
        flex: 1;

        > div {
          display: flex; 

          &:first-child {
            height: 20px;
            align-content: stretch; align-items: stretch;
            margin-bottom: 2px;

            >span {
              display: flex; height: 100%; align-items: center;
              justify-content: center;

              &:nth-child(1) {background-color: $color-success;}
              &:nth-child(2) {background-color: $color-success-2;}
              &:nth-child(3) {background-color: $color-error;}
              &:nth-child(4) {background-color: $color-alert;}
              &:nth-child(5) {background-color: $color-dark;}
              &:nth-child(6) {background-color: $color-uniform;}

              &:first-child{justify-content: flex-start; padding-left: 3px;}
              &:last-child{justify-content: flex-end; padding-right: 3px;}

              p {
                display: table; width: auto; margin: 0;
                font-size: 9px; padding: 2px 3px 1px; border-radius: 2em;
                background-color: $color-secondary;
                line-height: 1; font-weight: bold;
              }
            }
          }
          &:last-child {
            align-content: stretch; align-items: stretch; justify-content: space-between;
            
            p {
              flex: 1;
              font-size: 11px;
              margin: 0; padding: 0; text-align: center;

              &:first-child{text-align: left;}
              &:last-child{text-align: right;}
            }
          }
        }
      }
    }
  }

  .itensComprados {
    margin-bottom: $mg_mini;
    font-size: 11px;
  }

  .form-actions {
    a.btn {
      width: auto !important;
    }
  }

  a.infoButton {
    color: $color-text;

    > div {
      width: 100%; max-width: 220px; min-width: 80px;
      position: absolute; top: 50%; right: 0; white-space: pre-wrap;
      transform: translateY(-50%);
      background-color: $color-secondary;
      border-radius: $border-radius; padding: $hmg_small;
      @extend %transition_4e;
      @extend %shadow-mid;
      opacity: 0; pointer-events: none;
      z-index: 1000;
      
      p {
        padding: 0; margin: 0;
      }
    }

    &:hover {
      > div {
        right: 25px;
        opacity: 1; pointer-events: all;
      }
    }
  }

  .sectionHeader {
    display: flex;
    align-content: center; align-items: flex-end; justify-content: space-between;
    margin-bottom: $hmg;

    h5 { margin-bottom: 0; }

    > div {
      display: flex;
      align-content: center; align-items: center;

      .btn {
        margin-left: $hmg_mini;

        &.off {
          background-color: $color-bg !important; color: $color-text;
          opacity: .8; cursor: default;

          &:hover{
            box-shadow: initial !important;
          }
        }
      }
    }
  }
  
  .btBancos {
    display: block;
    margin: $mg 0 0 $hmg !important;

    &:hover {
      color: $color-primary !important;
    }
  }

  .estornoSpinner {
    padding: $mg;
    text-align: center;

    >*{
      margin: auto;
    }
  }

  .refundList {
    padding: $hmg 0; margin: 0;
    width: 100%;
    
    li {
      list-style: none;
      padding: 0; margin: 0 0 $hmg_mini 0;
    }
  }

  .refundFooter {
    display: flex; width: 100%;
    align-items: flex-start; align-content: center;
    padding: 0 !important;
    margin: 0 0 $mg;

    li {
      list-style: none;
      width: 150px; height: 72px;
      border-radius: $border-radius;
      background-color: $color-bg;
      padding: $hmg_small;
      margin-right: $hmg_mid;

      &.pago { background-color: $color-credito; }
      &.selecionado { background-color: $color-debito; }
      &.resto { background-color: $color-bg; }

      p {
        margin: 0; padding: 0;
        font-size: 12px;
      }
      h4 {
        margin: 0; padding: 0;
        white-space: nowrap;
      }
    }
  }

  .boletaoAlert {
    display: flex;
    align-items: center; align-content: center;
    background-color: $color-alert;
    border-radius: $border-radius-small;
    padding: $mg_mini $hmg_small;

    h5 {
      color: $color-secondary;
      margin: 0; padding: 0;
    }

    .icon {
      margin-right: $mg_mini;
      font-size: 16px;
      color: $color-secondary;
    }
  }

  .sectionActions {
    display: flex; align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-bg;
    padding-bottom: $hmg; margin-bottom: $hmg_mid;
    
    > span {
      h3 {
        margin: 0;
      }
    }
    > div {
      display: flex; align-items: center;
      justify-content: flex-end;
      gap: $mg_mini;
    }

    .action-wrap {
      margin-top: $hmg
    }
  }

  .voucher-details {
    width: 100%;
    border-collapse: collapse;
    background-color: $color-debito;
    border-radius: $border-radius-small;
    border: 1px solid $color-secondary;

    td {
      padding: $mg_mini !important;
      
      &.iconWrap {
        width: 40px
      }
    }
  }
</style>