  <template>
  <section>
    <header>
      <h4>Detalhes da compra </h4>
      <p>{{ total + ( total == 1 ? ' item' : ' itens' ) }}</p>
    </header>
    <main>
      <transition-group tag="ul" class="product-list" v-if="produtos.length > 0" name="slideLeftToRightSmall">
        <li v-for="item in produtos" :key="item">
          <div>
            <div>
              <div class="thumb" :style="{backgroundImage: 'url(' + item.produto.thumb + ')'}"></div>
              <div>
                <p>
                  <b>{{ item.produto.descricaovenda }}</b>
                  <template v-if="item.selecao?.length >= 1">
                    <span v-for="variacao in item.selecao" :key="variacao" class="sizeDetail">
                      <template v-if="variacao?.composicao?.descricaoGrade && item.selecao.length <= 1">
                        - {{ variacao?.composicao?.descricaoGrade }}
                      </template>
                    </span>
                  </template>  
                </p>
                <div class="sub-info">
                  <p>{{ getTotalItemAmount(item.selecao) + ( getTotalItemAmount(item.selecao) == 1 ? ' item' : ' itens' ) }}</p>
                  <template v-if="item.produto.codigoVoucher">
                    <span class="cupom-tag">
                      {{ item.produto.codigoVoucher }}
                    </span>
                  </template>
                  <template v-else>
                    <template v-for="selecao in item.selecao" :key="selecao">
                      <span class="cupom-tag" v-if="selecao.codigoVoucher">
                        {{ selecao.codigoVoucher }}
                      </span>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <span class="item-price">
              <template v-if="item.selecao?.length <= 1 && ( !item.produto.gradeProduto || item.produto.gradeProduto != 1 )">
                <template v-if=" item.produto.servicoextra == 1 || item.produto.precoitem == 0">
                  <div v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                    <p class="sizeDetail offPrice"><del>{{ formatMoney(multiply(item.produto.PrecoVenda, item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</del></p>
                    <h6 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(multiply(desconto(desconto(item.produto.PrecoVenda, item.produto.percentual),item.produto.UnidadeDesconto), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</h6>
                    <h6 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(multiply(desconto(item.produto.PrecoVenda, item.produto.UnidadeDesconto), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</h6>
                    <h6 v-else>{{ formatMoney(multiply(desconto(item.produto.PrecoVenda, item.produto.percentual), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</h6>
                  </div>
                  <template v-else>
                    <template v-if="item.produto.idVoucher">
                      <del>{{ formatMoney(multiply( item.produto.PrecoVendaOriginal, item.produto.quantidade)) }}</del>
                      <h6>{{ formatMoney(multiply( item.produto.PrecoVenda, item.produto.quantidade)) }}</h6>
                    </template>
                    <h6 v-else>
                      {{ formatMoney(multiply(desconto(item.produto.PrecoVenda, item.produto.percentual), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}
                    </h6>
                  </template>
                </template>
                <template v-else>
                  <template v-if="item.produto.comprasMultiplas === 1">
                    <template v-for="variacao in item.selecao" :key="variacao">
                      <template v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                        <p class="sizeDetail offPrice"><del>{{ formatMoney(multiply(variacao.composicao.valorVendaGrade, variacao.quantidade)) }}</del></p>
                        <h6 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(multiply(desconto(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), item.produto.UnidadeDesconto), variacao.quantidade)) }}</h6>
                        <h6 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.UnidadeDesconto), variacao.quantidade)) }}</h6>
                        <h6 v-else>{{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), variacao.quantidade)) }}</h6>
                      </template>
                      <h6 v-else>{{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), variacao.quantidade)) }}</h6>
                    </template>
                  </template>
                  <template v-else>
                    <h6 v-if="!item.produto.percentual || item.produto.percentual == 0  || !item.produto.allowDesconto">{{ formatMoney(multiply(item.produto.valorVenda, item.produto.quantidade)) }}</h6>
                    <template v-else>
                      <p class="sizeDetail offPrice"><del>{{ formatMoney(multiply(item.produto.valorVenda, item.produto.quantidade)) }}</del></p>
                      <h6 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(multiply(desconto(item.produto.valorVenda, item.produto.percentual), item.produto.quantidade)) }}</h6>
                      <h6 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(multiply(desconto(item.produto.valorVenda, item.produto.percentual), item.produto.quantidade)) }}</h6>
                      <h6 v-else>{{ formatMoney(multiply(desconto(item.produto.valorVenda, item.produto.percentual), item.produto.quantidade)) }}</h6>
                    </template>
                  </template>
                </template>
              </template>
              <template v-else>
                <template v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                  <p class="sizeDetail offPrice"><del>{{ formatMoney(getTotalItemValue(item.selecao)) }}</del></p>
                  <h6 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(desconto(desconto(getTotalItemValue(item.selecao),item.produto.UnidadeDesconto), item.produto.percentual)) }}</h6>
                  <h6 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(desconto(getTotalItemValue(item.selecao), item.produto.percentual)) }}</h6>
                  <h6 v-else>{{ formatMoney(desconto(getTotalItemValue(item.selecao), item.produto.percentual)) }}</h6>
                </template>
                <template v-else-if="item.selecao[0].idVoucher">
                  <!-- quantidade: {{ item.selecao[0].quantidade }} -->
                  <p><del>{{ formatMoney(multiply(item.selecao[0].PrecoVendaOriginal, getTotalItemAmount(item.selecao))) }}</del></p>
                  <h6>{{ formatMoney(getTotalItemValue(item.selecao)) }}</h6>
                </template>
                <h6 v-else>
                  {{ formatMoney(getTotalItemValue(item.selecao)) }}
                </h6>
              </template>
            </span>
          </div>
          <template v-if="item.selecao.length > 1">
            <div class="sub-item" v-for="selecao in item.selecao" :key="selecao">
              <div>
                <b>{{ selecao.composicao.descricaoGrade }}</b>
                <div class="sub-info">
                  <span>&nbsp;{{ '- ' + selecao.quantidade + ( selecao.quantidade == 1 ? ' item' : ' itens') }}</span>
                </div>
              </div>
              <div>
                <span v-if="selecao.PrecoVendaOriginal">
                  <del>{{ formatMoney( multiply(selecao.PrecoVendaOriginal, selecao.quantidade)) }}</del> -&nbsp; 
                </span>
                <span>{{ formatMoney( multiply(selecao.composicao.valorVendaGrade, selecao.quantidade)) }}</span>
              </div>
            </div>
          </template>
        </li>
      </transition-group>
      <div class="cart-sub">
        <card>
          <div class="cupom-wrap">
            <h5>Tem um cupom?</h5>
            <transition-group name="fadein">
              <div v-for="(cupom, i) in cupons" :key="cupom">
                <input-control 
                  placeholder="Ex. CUPOM1234" 
                  v-model="cupom.codigo" 
                  cssClass="invert" 
                  :valid="!cupom.error"
                  @change="validateCupons(i)"
                  @keyup="validateCupons(i)"
                  :disabled="cupom.used"
                ></input-control>
                <span>
                  <a href="#" @click.prevent="applyCupom(i)" v-if="!cupom.error && !cupom.used" class="btn btn-primary btApplyCupom">Aplicar</a>
                  <transition name="fadein">
                    <a href="#" @click.prevent="removeCupom(i)" class="btRemoveCupom" v-if="i > 0 || cupom.codigo"><fa :icon="['fas','trash-can']" class="icon"></fa></a>
                  </transition>
                </span>
              </div>
            </transition-group>
            <!-- <a href="#" @click.prevent="addCupom()" class="addCupom" v-if="allowMoreCupons">
              <fa :icon="['fas','plus']" class="icon"></fa>
              Adicionar outro cupom
            </a> -->
            <span class="difUniAlert" v-if="showVoucherAlert">
              <fa :icon="['fas', 'triangle-exclamation']" />
              <span>Ei, lembre-se que seu cupom não pode ser utilizado mais de uma vez, mesmo se o valor da compra for inferior.</span>
            </span>
          </div>
        </card>
      </div>
      <div class="cart-sub">
        <card>
          <div class="frete-wrap" v-if="entrega">
            <h5>Entrega</h5>
            <p>Seu pedido será entregue na unidade:</p>
            <select-control placeholder="Unidade" v-model="entrega.idUnidade" :options="options.unidades" cssClass="invert" @change="changeUnidade()"></select-control>
            <span class="difUniAlert" v-if="unidadeAlert">
              <fa :icon="['fas', 'triangle-exclamation']" />
              <span>A unidade selecionada não é a mesma do(s) aluno(s)</span>
            </span>
          </div>
        </card>
      </div>
      <div class="cart-sub">
        <card>
          <div class="pagamento-wrap" v-if="pagamento">
            <h5>Pagamento</h5>
            <p>Escolha a forma de pagamento:</p>
            <div class="paymentAlert" v-if="hasBloqueio">
              <b>Atenção!</b> Você tem bloqueios financeiros que impedem o pagamento de suas compras com boleto. <b>Entre em contato com nosso time de atendimento</b>, utilizando o aplicativo da Rede Decisão.
            </div>
            <div class="paymentAlert" v-if="!hasBloqueio && categorias.length > 1">
              <b>Atenção!</b> Como você está comprando produtos de diversas categorias, <b>a únicas formas de pagamento disponíveis são Cartão de Crédito e PIX.</b><br>
              Se preferir pagar com boleto, você deve comprar apenas itens de uma mesma categoria.
            </div>
            <ul class="formas-pagamento">
              <li v-if="enableBoleto && formasPagamentoSelectBoleto.length > 0">
                <label>
                  Boleto
                  <input type="radio" v-model="pagamento.forma" value="boleto" />
                </label>
              </li>
              <li>
                <label>
                  Cartão
                  <input type="radio" v-model="pagamento.forma" value="credito" />
                </label>
              </li>
              <li v-if="enablePix">
                <label>
                  Pix
                  <input type="radio" v-model="pagamento.forma" value="pix" />
                </label>
              </li>
            </ul>
            <div class="payment-data" v-if="pagamento.forma">
              <transition name="slidein">       
                <div class="col-md-12 nop nom" v-if="pagamento.forma == 'credito' || pagamento.forma == 'debito' && enableCartao">
                  <h5 class="form-group col-md-12">Dados do Cartão</h5>
                  <div class="row nop nom">
                    <div class="form-group col-md-12">
                      <label>Parcelas</label>
                      <select-control type="text" placeholder="Parcelas" v-model="pagamento.idFormaPagamento" name="idFormaPagamento" :valid="valid.idFormaPagamento" @keyup="valid.idFormaPagamento = true"  :options="formasPagamentoSelect" @change="changeFormaPagamento" required/>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Número do Cartão</label>
                      <input-control type="text" placeholder="Número" v-model="pagamento.cartao.numero" name="numero" :valid="valid.numero" @keyup="valid.numero = true" required/>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Nome</label>
                      <input-control type="text" placeholder="Nome" v-model="pagamento.cartao.nome" name="nome" :valid="valid.nome" @keyup="valid.nome = true" required/>
                    </div>
                    <div class="form-group col-md-8">
                      <label>Validade</label>
                      <input-control type="text" placeholder="MM/AA" v-model="pagamento.cartao.validade" masking="validade_cartao" name="validade" :valid="valid.validade" @keyup="valid.validade = true" required/>
                    </div>
                    <div class="form-group col-md-4">
                      <label>CVV</label>
                      <input-control type="text" placeholder="123" v-model="pagamento.cartao.cvv" masking="cvv" name="cvv" :valid="valid.cvv" @keyup="valid.cvv = true" required/>
                    </div>
                  </div>
                </div>
              </transition>
              <transition>
                <div class="col-md-12 nop nom" v-if="pagamento.forma == 'boleto' && enableBoleto && formasPagamentoSelectBoleto.length > 0">
                  <h5 class="form-group col-md-12">Parcelamento</h5>
                  <div class="row nop nom">
                    <div class="form-group col-md-12">
                      <select-control type="text" placeholder="Parcelas" v-model="pagamento.idFormaPagamento" :options="formasPagamentoSelectBoleto" name="idFormaPagamento" :valid="valid.idFormaPagamento" @keyup="valid.idFormaPagamento = true" @change="changeFormaPagamento" required/>
                    </div>
                  </div>
                </div>
              </transition>
              <transition>
                <div class="col-md-12 nop nom" v-if="pagamento.forma == 'pix' && enablePix && formasPagamentoSelectPix.length > 0">
                  <h5 class="form-group col-md-12">Parcelamento</h5>
                  <div class="row nop nom">
                    <div class="form-group col-md-12">
                      <select-control type="text" placeholder="Parcelas" v-model="pagamento.idFormaPagamento" :options="formasPagamentoSelectPix" name="idFormaPagamento" :valid="valid.idFormaPagamento" @keyup="valid.idFormaPagamento = true" @change="changeFormaPagamento" required/>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <!-- <footer>
              <a href="#" class="btn btn-primary" @click.prevent="continuePayment">Continuar</a>
            </footer> -->
          </div>
        </card>
      </div>
      <div class="cart-sub price">
        <p>Valor Final</p>
        <span>
          <template v-if="valorOriginal && valorOriginal != valorTotal">
            <h3 class="color-text"><del>{{ valorOriginal }}</del></h3>
            <fa :icon="['far','arrow-right']" class="icon"></fa>
          </template>
          <h2>{{ valorTotal }}</h2>
        </span>
      </div>
    </main>
    <footer>
      <a href="#" :class="['btn btn-primary btn-large', { disabled: !allowFinish}]" @click.prevent="concluir">Concluir Compra</a>
    </footer>
  </section>
</template>

<script>
import LojaService from '@/services/loja.service.js'
import { 
  formatMoney, 
  multiply, 
  createSelectOptions, 
  divide 
} from '@/services/utils.service.js'
import UserService from '@/services/user.service'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      cart: [],
      produtos:[],
      total: 0,
      cupons: [{codigo: null}],
      valorTotal: "R$ 0,00",
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      options: {
        unidades: []
      },
      entrega: null,
      noPostMethod: false,
      unidadeOk: false,
      pagamento: null,
      formasPagamento: [],
      enableBoleto: false,
      enableCartao: false,
      enablePix: false,
      formasPagamentoSelect: [],
      formasPagamentoSelectBoleto: [],
      formasPagamentoSelectPix: [],
      formaPagamentoPix: null,
      valid: {
        idFormaPagamento: true,
        numero: true,
        nome: true,
        validade: true,
        cvv: true
      },
      valorPagoRaw: 0,
      hasBloqueio: false,
      isBundle: false,
      categorias: [],
      allowBoletoIntegrado: false,
      boletao23: ['9','27','13','23'],
      idUnidade: null,
      valorOriginal: null,
      allowMoreCupons: false,
      unidadeAlert: false,
      unidadeDoAluno: null,
      allowFinish: false,
      showVoucherAlert: false
    }
  },
  mounted () {
    window.spinner.emit('open')
    
    this.$store.dispatch('checkout/get').then(response => {
      this.cart = response

      this.total = this.getTotalItensFromCart()
      this.valorOriginal = formatMoney(this.getTotalValueFromCart())

      this.calcTotalValue()

      const free = this.cart.produtos.filter( k => {
        return k.produto.categoriaLivre == 1 && !k.produto.servicoextra || k.produto.categoriaLivre == 1 && k.produto.servicoextra == 0
      })
      
      this.noPostMethod = free.length == 0

      setTimeout(() => {
        this.appendProducts(this.cart.produtos)
        this.checkAllowFinish()
      },1000)
    })

    this.getUnidades()
    
  },
  methods: {
    appendProducts ( produtos, recalc, voucher ) {
      this.produtos = []

      produtos.map( (k,i) => {
        setTimeout(() => {
          this.produtos.push(k)

          if ( recalc ) {
            if ( i == ( produtos.length - 1 ) ) {
              // console.log("ready to recalc...")
              this.calcTotalValue(voucher)
              this.processImages()
            }
          } else {
            if ( i == ( produtos.length - 1 ) ) {
              this.processImages()
            }
          }
        },400 * i)
      })
    },
    processImages () {
      this.produtos.map( (k, i) => {
        console.log("k:", k)
        this.setImageRoute(k.produto?.imgproduto, i)
      })
    },
    setImageRoute ( value, i ) {
      console.log("setImageRoute:", value)
      if ( value ) {
        LojaService.setImageRoute(value).then(
          response => {
            this.produtos[i].produto.thumb = response.data
          }
        )
      } else return '/img/thumb_nopic.jpg'
    },
    getTotalItensFromCart () {
      const total = LojaService.getTotalItensFromCart()
      return total
    },
    getTotalItemAmount ( items ) {
      let total = 0

      items?.map(k => {
        total += k.quantidade
      })

      return total
    },
    getTotalItemValue ( items ) {
      return LojaService.getTotalItemValue(items)
    },
    getTotalValueFromCart ( desconto, target ) {
      return LojaService.getTotalValueFromCart( desconto, target )
    },
    desconto ( value, discount ) {
      return LojaService.calcDesconto (value, discount)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    multiply ( value, times ) {
      return multiply(value,times)
    },
    addCupom () {
      this.cupons.push({codigo: null})
    },
    removeCupom ( i ) {
      const codigoAtual = this.cupons[i].codigo
      const codigoAtualUsed = this.cupons[i].used
      console.log("codigoAtual:", codigoAtual)
      console.log("codigoAtualUsed:", codigoAtualUsed)

      if ( i == 0 && this.cupons.length == 1 ) {
        this.cupons[0].codigo = null
        this.cupons[0].used = null
        this.cupons[0].error = false
        this.allowMoreCupons = false
      } else if ( i != 0 ) {
        this.cupons.splice(i,1)
      }

      if ( codigoAtual && codigoAtualUsed ) {
        const clear = i == 0
        this.applyCupom(i, clear)
      }
    },
    applyCupom ( index, clear ) {
      if ( clear ) {
        this.$store.dispatch('checkout/get').then(response => {
          this.cart = response

          this.cart.produtos = LojaService.getCart().produtos
          
          // this.produtos = this.cart.produtos
          console.log("this.cart:", this.cart)
          this.appendProducts(this.cart.produtos, true)
        })
        
      } else {
        const drop = this.cart
        var cupons = []

        window.spinner.emit('open')

        this.cupons.map( k => {
          // if ( i == index ) k.used = true
          if ( !k.error ) cupons.push(k.codigo)
        })

        drop.cupons = cupons

        LojaService.aplicarCupom( drop ).then(
          response => {
            console.log("aplicarCupom:", response)
            this.appendProducts(response.data.produtos,true, true)
            LojaService.createCheckoutCart(response.data)

            if ( this.cupons[index] ) {
              this.cupons[index].used = true
            }
            
            this.allowMoreCupons = true
            this.getFormasPagamento('carrinho-checkout')
            window.spinner.emit('close')
          },
          error => {
            console.log("message:", error.response.data)
            const msg = error.response.data ? error.response.data : "Não foi possível processar seu cupom."
            window.toaster.emit('open', {style: "error", message: "Ops. " + msg, floater: true})
            window.spinner.emit('close')
          }
        )
      }
    },
    validateCupons ( i ) {
      var codigo = this.cupons[i].codigo
          
      if ( codigo.length > 3 ) {
        codigo = codigo.trim()
        console.log("codigo:", codigo)
        this.cupons[i].codigo = codigo
        const repeated = this.cupons.filter( a => a.codigo == this.cupons[i].codigo )

        this.cupons[i].error = repeated.length > 1
      }
    },
    calcTotalValue ( dropType ) {
      const valor = dropType ? this.getTotalValueFromCart(null,'carrinho-checkout') : this.getTotalValueFromCart()
      this.cart.valorPago = parseFloat(valor.toFixed(2))

      if ( this.cart.valorPago == 0 ) {
        this.showVoucherAlert = true
      }

      this.valorTotal = this.formatMoney(this.cart.valorPago)
      console.log("valorTotal:", this.valorTotal)
    },
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log('getUnidades:', response.data)
          if ( response.data ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade', 'descricao')
            window.spinner.emit('close')
            this.loading = false
            this.getAlunoUnidade()
          }
        },
        err => {
          console.log('err:', err)
          window.spinner.emit('close')
        }
      )
    },
    getAlunoUnidade () {
      // console.log("currentUser:", this.currentUser)
      var unidadeDoAluno = null
      if ( this.currentUser.alunos ) {
        unidadeDoAluno = this.currentUser.alunos[0].idUnidade
      }

      // console.log("unidadeDoAluno:", unidadeDoAluno)
      // console.log("unidades:", this.options.unidades)

      this.unidadeOk = this.options.unidades.filter( k => {
        return k.value == unidadeDoAluno
      }).length > 0

      this.unidadeOk = this.noPostMethod ? true : this.unidadeOk

      console.log("unidadeOk:", this.unidadeOk)

      this.$store.dispatch('checkout/get').then(response => {
        this.entrega = response.entrega
        this.pagamento = response.pagamento
        
        if ( unidadeDoAluno && this.unidadeOk ) {
          this.unidadeDoAluno = unidadeDoAluno
          this.entrega.idUnidade = unidadeDoAluno
          this.completeUniInfo()
        }
        this.getFormasPagamento()
        // console.log("entrega:", this.entrega)
      })
    },
    completeUniInfo () {
      console.log("this.entrega.idUnidade:", this.entrega.idUnidade)

      const unidade = this.options.unidades.filter(k => {
        return parseFloat(this.entrega.idUnidade) === k.value
      })[0]
      this.unidadeOk = true
      console.log("unidade:", unidade)

      this.entrega.descricao = unidade.label
    },
    trackBoletoIntegrado () {
      this.currentUser.alunos.map( k => {
        k.matriculas.map( km => {
          const isBoletao = km.agruparBoletos == 'A'
          this.idUnidade = km.idUnidade
          this.allowBoletoIntegrado = !this.allowBoletoIntegrado ? isBoletao : this.allowBoletoIntegrado
        })
      })
    },
    getFormasPagamento(src) {
      if ( src ) {
        this.valorPagoRaw = LojaService.getTotalValueFromCart(true, src)
      } else {
        this.valorPagoRaw = LojaService.getTotalValueFromCart(true)
      }
      
      if ( this.cart.pagamento ) {
        this.cart.pagamento.valorPago = this.valorTotal
      }
      this.trackBoletoIntegrado()

      var valorMaiorProduto = 0;
      var planoItem
      
      console.log("this.pagamento:", this.pagamento)

      this.cart.produtos.map( element => {
        const valorProduto = element['produto']['precoVenda'] ? element['produto']['precoVenda'] : ( element['produto']['PrecoVenda'] ? element['produto']['PrecoVenda'] : element['produto']['valorVenda'] )
        console.log('valorProduto', valorProduto)
        
        if(valorMaiorProduto === 0 || Number(valorMaiorProduto) < Number(valorProduto)) {
          valorMaiorProduto = valorProduto
          this.formasPagamento = element['produto']['formasPagamento']
          // this.formasPagamento.produto = element
          planoItem = element
          // console.log('planoItem:', planoItem)
        }

        const categoria = element.produto.idcategorialoja

        if ( !this.categorias.includes(categoria) ) {
          this.categorias.push(categoria)
        }
      })
      // console.log("categorias:", this.categorias)
      const productAllowsBoletao = planoItem.produto.planoAgrupado == 1
      // console.log("planoItem:", planoItem)
      // console.log("productAllowsBoletao:", productAllowsBoletao)
      // console.log("formasPagamento:", this.formasPagamento)
      this.formasPagamentoSelect = []
      this.formasPagamentoSelectPix = []
      this.formasPagamentoSelectBoleto = []

      this.formasPagamento.forEach(element => {
        const isColab = this.currentUser.colaborador
        const existFP = formas => {
          return formas.idformapagamentomaterial == element.idformapagamentomaterial
        }

        if (element.cartao === 1) {
          this.enableCartao = true
          let valor, descricao
          if ( element.desconto > 0 ) {
            if ( this.produtos.length == 1 ) {
              valor = formatMoney(divide(this.desconto(this.valorPagoRaw, element.desconto), element.meses))
            } else {
              var cartaoTotalDiscountValue = 0;
              var cartaoTotalNoDiscountValue = 0;

              this.cart.produtos.map( k => {
                const exist = k.produto.formasPagamento.some(existFP)

                var cartaoSingleValue = ( k.produto.valorVendaGrade ? k.produto.valorVendaGrade : k.produto.valorVenda ? k.produto.valorVenda : k.produto.PrecoVenda ) * k.produto.quantidade
                    cartaoSingleValue = k.produto.UnidadeDesconto > 0 ? this.desconto(cartaoSingleValue, k.produto.UnidadeDesconto) : cartaoSingleValue
                if ( exist ) {
                  cartaoTotalDiscountValue += cartaoSingleValue
                } else {
                  cartaoTotalNoDiscountValue += cartaoSingleValue
                }
              })

              const cartaoProdutoValorDesconto = parseFloat(this.desconto(cartaoTotalDiscountValue, element.desconto))
              const valorFinal = divide(cartaoProdutoValorDesconto + cartaoTotalNoDiscountValue, element.meses)

              valor = formatMoney( valorFinal )
            }
            descricao = element['descricao'] + " (" + valor + ")"
          } else {
            valor = formatMoney(divide(this.valorPagoRaw, element.meses))
            // console.log("element", element)
            descricao = element['descricao'] + " (" + valor + ")"
          }
          
          this.formasPagamentoSelect.push({label: descricao, value: element['idformapagamentomaterial']})
        }
        if ( element.cartao === 0 && element.pix === 0 ) {
          this.enableBoleto = true
          let valor, descricao
          
          if ( ( element.planoAgrupado == 1 && this.allowBoletoIntegrado && productAllowsBoletao ) || element.planoAgrupado == 1 && !productAllowsBoletao  || element.planoAgrupado == 0 ) {
            if ( element.desconto > 0 ) {
              if ( this.produtos.length == 1 ) {
                valor = formatMoney(divide(this.desconto(this.valorPagoRaw, element.desconto), element.meses))
              } else {
                
                var totalDiscountValue = 0;
                var totalNoDiscountValue = 0;

                this.cart.produtos.map( k => {
                  const exist = k.produto.formasPagamento.some(existFP)
                  
                  var singleValue = ( k.produto.valorVendaGrade ? k.produto.valorVendaGrade : k.produto.valorVenda ? k.produto.valorVenda : k.produto.PrecoVenda ) * k.produto.quantidade
                      singleValue = k.produto.UnidadeDesconto > 0 ? this.desconto(singleValue, k.produto.UnidadeDesconto) : singleValue
                  if ( exist ) {
                    totalDiscountValue += singleValue
                  } else {
                    totalNoDiscountValue += singleValue
                  }
                })

                const produtoValorDesconto = parseFloat(this.desconto(totalDiscountValue, element.desconto))
                const valorFinal = divide(produtoValorDesconto + totalNoDiscountValue, element.meses)

                // const produtoValorDesconto = divide(this.desconto(totalDiscountValue, element.desconto), element.meses)
                valor = formatMoney( valorFinal )
              }
              descricao = element['descricao'] + " (" + valor + ")"
            } else {

              valor = formatMoney(divide(this.valorPagoRaw, element.meses))
              descricao = element['descricao'] + " (" + valor + ")"
            }

            // this.valorFinal = valor
            if ( isColab ) {
              this.formasPagamentoSelectBoleto.push({label: descricao, value: element['idformapagamentomaterial']})
            } else {
              if ( element.colaborador != 1 ) {
                const unidade23 = this.boletao23.some( a => a == this.idUnidade)

                if ( element.planoAgrupado ) {
                  if ( planoItem.produto.planoAgrupado && this.allowBoletoIntegrado ) {
                    this.formasPagamentoSelectBoleto.push({label: descricao, value: element['idformapagamentomaterial']})
                  }
                } else {
                  if ( !planoItem.produto.planoAgrupado || !this.allowBoletoIntegrado || !unidade23 ) {
                    this.formasPagamentoSelectBoleto.push({label: descricao, value: element['idformapagamentomaterial']})
                  }
                }
              }
            }
          }
        }
        if ( element.pix === 1 ) {
          this.enablePix = true
          let valor, descricao

          if ( element.desconto ) {
            if ( this.cart.produtos.length == 1 ) {
              this.formaPagamentoPix = element['idformapagamentomaterial']
              valor = formatMoney(divide(this.desconto(this.valorPagoRaw, element.desconto), element.meses))
            } else {
              var pixTotalDiscountValue = 0;
              var pixTotalNoDiscountValue = 0;

              this.cart.produtos.map( k => {
                const exist = k.produto.formasPagamento.some(existFP)
                // console.log("exist:", exist)
                var pixSingleValue = ( k.produto.valorVendaGrade ? k.produto.valorVendaGrade : k.produto.valorVenda ? k.produto.valorVenda : k.produto.PrecoVenda ) * k.produto.quantidade
                    pixSingleValue = k.produto.UnidadeDesconto > 0 ? this.desconto(pixSingleValue, k.produto.UnidadeDesconto) : pixSingleValue
                if ( exist ) {
                  pixTotalDiscountValue += pixSingleValue
                } else {
                  pixTotalNoDiscountValue += pixSingleValue
                }
              })

              const produtoValorDesconto = parseFloat(this.desconto(pixTotalDiscountValue, element.desconto))
              const valorFinal = divide(produtoValorDesconto + pixTotalNoDiscountValue, element.meses)

              valor = formatMoney( valorFinal )
            }
            
          } else {
            valor = formatMoney(divide(this.desconto(this.valorPagoRaw, element.desconto), element.meses))
          }
          descricao = element['descricao'] + " (" + valor + ")"
          this.formasPagamentoSelectPix.push({label: descricao, value: element['idformapagamentomaterial']})
        }
        
      })

      this.checkBloqueio()
      
    },
    changeFormaPagamento ( e ) {
      const formaPagamentoId = parseFloat(e.target.value)
      var target
      
      if ( this.pagamento.forma === 'boleto' ) target = this.formasPagamentoSelectBoleto 
      else if ( this.pagamento.forma === 'pix' ) target = this.formasPagamentoSelectPix
      else target = this.formasPagamentoSelect

      const formaPagamento = target.filter(k => {
        return k.value == formaPagamentoId
      })[0]

      const fp = this.formasPagamento.filter(k => {
        return k.idformapagamentomaterial == formaPagamentoId
      })[0]

      const parcelas = fp.parcelas
      const desconto = fp.desconto

      console.log("formaPagamento:", formaPagamento)

      const label = formaPagamento.label
      const valueFromLabel = label.match(/.*\((.*)\)/)[1]

      // console.log("valueFromLabel:", valueFromLabel)
      
      const valorFinal = parcelas == 1 && desconto > 0 ? valueFromLabel : formatMoney(this.valorPagoRaw)

      this.pagamento.valorPago = valorFinal

      this.checkAllowFinish()
      // console.log("label:", label)
      // console.log("valorFinal:", valorFinal)
      // console.log("fp:", fp)
      // console.log("formaPagamento:", formaPagamento)
      // txt.match(/\((.*)\)/)[1]
    },
    checkBloqueio () {
      const bloqueios = []
      this.currentUser.alunos.map( k => {
        if ( k.bloqueios ) {
          k.bloqueios.map( kb => {
            bloqueios.push(kb)
          })
        }
      })
      
      // console.log("bloqueios:", bloqueios)

      if ( bloqueios ) {
        const bloquearFormaPagamento = bloqueios.filter( k => {
          return k.cancelado == 0 && k.bloquearFormaPagamento == 1
        })

        this.enableBoleto = bloquearFormaPagamento.length == 0 && this.categorias.length == 1
        this.hasBloqueio = bloquearFormaPagamento.length > 0

        // console.log("bloquearFormaPagamento:", bloquearFormaPagamento)
        // console.log("enableBoleto:", this.enableBoleto)
        
      }
    },
    selectPix () {
      this.pagamento.idFormaPagamento = this.formaPagamentoPix
      // console.log("this.pagamento:", this.pagamento)
    },
    validate () {
      var required = document.querySelectorAll(':required')
    
      const notValids = []
      const radioChecked = []
      // console.log("required:", required)
      required.forEach (k => {
        // console.log("k:", k)
        const value = k.value
        const nome = k.name
        const type = k.type
        
        if ( type == 'radio' ) {
          const checked = k.checked
          // console.log("checked:", checked)

          if ( !checked ) {
            radioChecked.push(k)

            if ( radioChecked.length == 3 ) {
              this.valid[nome] = false
              notValids.push(k)
            }
          }
          // console.log("checked:", k.checked)
          // if ( !value ) {
          //   notValids.push(k)
          //   this.valid[nome] = false
          //   // window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
          //   // k.className += ' error'; 
          // }
        } else {
          if ( !value ) {
            notValids.push(k)
            this.valid[nome] = false
            // window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
            // k.className += ' error'; 
          }
        }
        
      })

      // console.log('notValids:', notValids)

      return notValids.length == 0
      // return false
    },
    concluir () {
      const hasCupons = this.cupons.some( a => a.codigo )
      // console.log("hasCupons:", hasCupons)
      var cart

      if ( hasCupons ) {
        cart = LojaService.getCheckoutCart()
      } else {
        cart = LojaService.getCart()
      }
     
      this.cart.produtos = cart.produtos
      
      this.$emit("onFinish", this.cart)
    },
    checkAllowFinish () {
      this.allowFinish = this.cart && this.pagamento && this.pagamento.forma
    },
    changeUnidade () {
      this.unidadeAlert = this.unidadeDoAluno != this.entrega.idUnidade
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    header {
      padding: 0 $mg_mid;
    }
    main {
      padding: 0 $hmg $hmg;

      .product-list {
        padding: 0; margin: 0;

        li {
          list-style: none;
          display: flex; flex-direction: column;
          align-items: flex-end;
          padding: 0; margin: 0 0 $mg_mini 0;
          
          > div {
            display: flex; flex: 1;
            align-items: center;
            padding: $mg_small;
            // border-bottom: 1px solid $color-bg;
            border-radius: $border-radius;
            @extend %shadow-low-light;
            background-color: $color-secondary;
            @extend %transition_4e;
            justify-content: space-between;
            width: 100%;

            .thumb {
              width: 40px; height: 40px;
              border-radius: $border-radius-small;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              background-color: $color-bg;
              border: 1px solid $color-bg;
              margin-right: $mg_mini
            }
            p {
              margin: 0; padding: 0;
            }

            > div {
              display: flex;
              align-items: center;
            }

            &.sub-item {
              max-width: calc( 100% - $mg_mini );
              margin-top: $mg_mini
            }
          }
        }
      }
    }
    footer {
      padding: 0 $hmg; margin: 0;
    }
    
    .cart-sub {
      margin-top: $hmg;

      h5 {
        margin-bottom: $mg_mini
      }

      .cupom-wrap {
        padding: $hmg_mid;
        position: relative;

        .btn, a {
          @extend %transition_4o;
        }
        > div {
          position: relative;
          margin-bottom: $mg_mini;
          @extend %transition_4o;

          > span {
            position: absolute;
            right: $mg_mini; top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
          }
        }
      }

      .frete-wrap,
      .pagamento-wrap  {
        padding: $hmg_mid;

        h5 {
          margin-bottom: 0;
        }

        footer {
          padding: 0;
          margin-top: $hmg_small
        }
      }
    }

    .addCupom {
      display: flex;
      align-items: center;
      .icon {
        margin-right: $hmg_mini
      }
    }

    .item-price {
      text-align: right;

      h6 {
        color: $color-text;
        margin: 0; padding: 0;
      }
    }
    .btRemoveCupom {
      display: flex; flex-direction: column;
      justify-content: center; align-items: center;
      width: 28px; height: 28px;
      border-radius: $border-radius-small;
      background-color: $color-bg;
      border: 2px solid $color-secondary;
      line-height: 1; text-align: center;
      transform: translateX(75%);
      font-size: 14px;
      @extend %transition_4e;
      .icon {
        @extend %transition_3o;
      }

      &:hover {
        background-color: $color-primary;
        .icon {
          color: $color-secondary
        }
      }
    }

    .price {
      padding: 0 $hmg;
    
      > * {
        margin: 0; padding: 0;
      }
      > span {
        display: flex;
        align-items: center; align-content: center;
        gap: $hmg;

        > * {
          margin: 0; padding: 0;
        }
      }
    }
  }

  .sub-info {
    display: flex; align-items: center
  }
  .cupom-tag {
    padding: 2px 6px;
    border-radius: 2em;
    background-color: $color-success;
    display: inline-flex;
    margin-left: $mg_mini;
    color: #FFF;
    font-size: .8rem;
    font-weight: bold;
    line-height: 1;
  }

  .formas-pagamento {
    padding: 0; margin: 0;
    display: flex; align-items: center;

    > li {
      list-style: none;
      padding: 0; margin-right: $mg_mini;

      &:last-child {
        margin: 0;
      }

      label {
        width: 80px;
        background-color: $color-bg;
        border-radius: $border-radius-small;
        display: flex; align-items: center;
        justify-content: space-between;
        padding: $mg_mini;
      }
    }

    
  }

  .paymentAlert {
    padding: $mg_mini 0;
    border-top: 1px solid $color-bg;
    border-bottom: 1px solid $color-bg;
    margin-bottom: $hmg_small;
  }

  .payment-data {
    margin-top: $hmg_mid;
    background-color: $color-bg;
    border-radius: $border-radius-small;
    padding: $hmg_mid $mg_mini $mg_mini $mg_mini;

    > div {
      @extend %transition_4o;
    }

    h5 {
      margin-bottom: $hmg_small !important;
    }
  }

  .difUniAlert {
    padding: $mg_mini $hmg_small;
    border-radius: $border-radius;
    background-color: $color-alert;
    color: $color-secondary;
    display: flex; align-items: center;
    gap: $mg_mini;
    margin-top: $mg_mini;

    span {
      line-height: 1.2
    }
  }
</style>





