<template>
  <controller lockScroll>
    <transition name="fadein">
      <iframe
        v-if="src"
        :src="src"
        frameborder="0"
        allowtransparency="true"
        ref="iframe"
        :onload="finishLoading"
      ></iframe>
    </transition>
  </controller>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    id: {
      type: [String, Number]
    }
  },
  watch: {
    id ( value ) {
      if ( value ) {
        this.handleSrc()
      }
    }
  },
  data () {
    return {
      src: null,
      payload: {
        resource: null,
        params: null,
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
      },
    }
  },
  mounted () {
    window.spinner.emit("open")
    this.handleSrc()
  },
  methods: {
    handleSrc () {
      var sign = require('jwt-encode')

      const parsed = this.id.split('-')
      const type = parsed[0]
      const dashboard = parseFloat(parsed[1])

      this.payload.resource = { [type]: dashboard }
      const params = this.$route.query.path
      console.log("params:", params)

      this.payload.params = params ? this.getQueryStringParams(params) : {}
      
      console.log("payload:", this.payload)
      
      this.src = process.env.VUE_APP_METABASE_SITE_URL + "/embed/" + type + '/' + sign(this.payload, process.env.VUE_APP_METABASE_SECRET_KEY) + "#bordered=false&titled=true"
    },
    finishLoading () {      
      setTimeout (() => {
        window.spinner.emit("close")
        this.loading = false
      },1000)
    },
    getQueryStringParams(query) {      
      var array = query.split('&')
      var result = {}

      console.log("getQueryStringParams:", array)
      // var common = false

      array.map( k => {
        if ( k.includes('%') ) {
          if(k == 'unidade=%unidade') {
            result['unidade'] = this.currentUser.colaborador.sigla
            // result.push({"unidade": this.currentUser.colaborador.sigla})
          }
          if(k == 'email=%email') {
            result['email'] = this.currentUser.colaborador.email
            // result.push({"email": this.currentUser.colaborador.email})
          }
          if(k == 'nome_pessoa=%nome_pessoa') {
            result['nome_pessoa'] = this.currentUser.colaborador.nome
            // result.push({"nome_pessoa": this.currentUser.colaborador.nome})
          }
          if(k == 'data_atual=%data_atual') {
            result['data_atual'] = moment().format('YYYY-MM-DD')
            // result.push({"data_atual": moment().format('YYYY-MM-DD')})
          }

          // common = true
        } else if ( k != "" ) {
          var data = k.split(";")
          data.map( k => {
            const item = k.split("=")

            result[item[0]] = item[1]
          })

          // result = drop
        }

        console.log("result:", result)
        // console.log("common:", common)

      })

      return result
    },
  }
}
</script>

<style lang="scss" scoped>
  iframe {
    width: 100%; height: calc(100vh - 62px);
    overflow-y: auto;
  }
</style>