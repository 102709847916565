import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class FerramentaService {
  getGatilhos () {
    return axios.get(process.env.VUE_APP_API_URL + 'intervencao/listgatilhos', { headers: authHeader() })
  }
  getAnaliseIntegradaFields () {
    return axios.get(process.env.VUE_APP_API_URL + 'intervencao/schema', { headers: authHeader() })
  }
  saveGatilho ( gatilho ) {
    // console.log("saveGatilho:", gatilho)
    gatilho.analiseFinanceira = gatilho.analiseFinanceira ? 1 : 0

    if ( gatilho.idGatilhoIntervencao ) {
      return axios.put(process.env.VUE_APP_API_URL + 'intervencao/gatilho/update/' + gatilho.idGatilhoIntervencao, gatilho, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'intervencao/gatilho/create', gatilho, { headers: authHeader() })
    }
  }

  deleteGatilho ( gatilho ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'intervencao/gatilho/delete/' + gatilho.idGatilhoIntervencao, { headers: authHeader() })
  }

  createIntervencoes ( gatilho ) {
    return axios.post(process.env.VUE_APP_API_URL + 'intervencao/gatilho/create/lote/' +  gatilho.idGatilhoIntervencao, {}, { headers: authHeader() })
  }

  getIntervencoes ( gatilho ) {
    const ativos = 1
    return axios.get(process.env.VUE_APP_API_URL + 'intervencao/lista/' + ativos + '/' +  gatilho.idGatilhoIntervencao, { headers: authHeader() })
  }

  getRDConversasTemplate () {
    return axios.get(process.env.VUE_APP_API_URL + 'get/templates/rd/conversas', { headers: authHeader() })
  }

  callIntervencao ( intervencao ) {
    return axios.get(process.env.VUE_APP_API_URL + 'intervencao/call/' +  intervencao.idIntervencao, { headers: authHeader() })
  }

  runIntervencoes ( gatilho ) {
    return axios.get(process.env.VUE_APP_API_URL + 'intervencao/call/lote/' + gatilho.idGatilhoIntervencao, { headers: authHeader() })
  }

  uploadImageApp ( data ) {
    return axios.postForm("https://rededecisao.sasconecta.com.br/api/arquivo/upload", data, { 
      headers: {
        Authorization: 'Bearer 7PfuDvII8GuGkloNwMcxw9kCHx3O+nbjrSHlkKBLKK4Jkcok9Ced3nzYv/UQlpdauoK5SAYnDvjBGI7E6CmtaSpOjOeFOFUu79U3qU/ltpGcHrjPsKgYFwAPgt4lwQ8yEUq09luqiPeIjLIcwhIFoNlXCUVGgaLsz8qPRLaEX98=',
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  getRestauracaoBase ( target ) {
    target = target ? target : 'hom'
    return axios.get(process.env.VUE_APP_API_URL + 'get/restore/' + target, { headers: authHeader() })
  }

  restaurarBase ( target ) {
    target = target ? target : 'hom'
    return axios.get(process.env.VUE_APP_API_URL + 'restore/' + target, { headers: authHeader() })
  }

  getGatilhosAtividades () {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/gatilhos/atividades', { headers: authHeader() })
  }
  
  getOnlineUsers () {
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/gatilhos/atividades', { headers: authHeader() })
  }
    
  getMeObject (idUser) {
    return axios.get(process.env.VUE_APP_API_URL + 'auth/perfil/na/' +idUser, { headers: authHeader() })
  }

  sendNotification ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'testeeventsprivado', data , { headers: authHeader() })
  }

  getFormulariosGatilhosAtividades ( ) {
    return axios.get(process.env.VUE_APP_API_URL + 'get/online/users', { headers: authHeader() })
  }

  getGatilhosDetails ( idCategoriaAtividade, idFormulario ) { 
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/gatilhos/atividades/list/${idCategoriaAtividade}/${idFormulario}` , { headers: authHeader() })
  }

  getEditGatilhos ( idCategoriaAtividade, idFormulario ) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/gatilhos/atividades/edit/full/${idCategoriaAtividade}/${idFormulario}` , { headers: authHeader() })
  }

  removeGatilhoAtividade ( idGatilhoAtividade ) {
    return axios.delete(process.env.VUE_APP_API_URL + `atendimento/gatilhos/atividades/delete/${ idGatilhoAtividade} ` , { headers: authHeader() })
  }

  saveGatilhoAtividade ( data ) {
    if ( data.idGatilhoAtividade ) {
      return axios.put(process.env.VUE_APP_API_URL + `atendimento/gatilhos/atividades/create/${ data.idGatilhoAtividade }`, data , { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + `atendimento/gatilhos/atividades/create`, data , { headers: authHeader() })
    }
  }
  
  getIndicadores () {
    return axios.get(process.env.VUE_APP_API_URL + 'indicadores/rede', { headers: authHeader() })
  }
    
  getIndicadoresTreeView () {
    
    return axios.get(process.env.VUE_APP_API_URL + 'indicadores/treeview/rede', { headers: authHeader() })
  }
  
  updateIndicadores (data) {
    
    return axios.post(process.env.VUE_APP_API_URL + 'indicadores/treeview/rede/update', data, { headers: authHeader() })
  }
}

export default new FerramentaService()